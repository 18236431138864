import styled from '@emotion/styled';
import { Box, SxProps, Theme, Typography } from '@mui/material';
import { useState } from 'react';
import SmallXIcon from '../../assets/icons/small_x_icon';
import SavedResume from '../../mainTabs/savedResumes/model/SavedResume';
import { DeleteChatAction } from '../../redux/actions/ChatActions';
import { DeleteInterviewAction } from '../../redux/actions/InterviewActions';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { clearActiveChat } from '../../redux/reducers/appReducer';
import { removeSavedInterview } from '../../redux/reducers/interviewReducer';
import { clearSelectedResume, removeSavedResume } from '../../redux/reducers/savedResumeReducer';
import { removeSavedChat } from '../../redux/reducers/userReducer';
import InterviewService from '../../services/interviewService';
import ResumeService from '../../services/resumeService';
import SavedChatsService from '../../services/savedChatsService';
import ClickableOpacityDiv from '../../shared/components/ClickableOpacityDiv';
import ChatSession from '../../shared/models/ChatSession';
import Interview from '../../shared/models/Interview';
import SavedChat from '../../shared/models/SavedChat';
import { getFormattedChatDate } from '../../shared/utils/DateUtils';
import { AppColors } from '../../theme/AppTheme';
import { interviewTitle } from '../utils/InterviewHelper';

const StyledCard = styled(Box)((props: { isMobile: boolean }) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    width: props.isMobile ? 'calc(100% - 90px)' : 'calc(100% - 48px)',
    marginLeft: '24px',
    marginRight: '24px',
    marginBottom: '10px',
    padding: '20px',
    border: `2px solid ${AppColors.grey.light}`,
    borderRadius: '8px',
    userSelect: 'text',
    webkitUserSelect: 'text',
}));


const UnselectedStyledCard = styled(StyledCard)(() => ({
    '&:hover': {
        backgroundColor: AppColors.grey.lightest,
        cursor: 'pointer',
    }
}));

const StyledTypography = styled(Typography)(() => ({
    wordBreak: 'break-word',
    whiteSpace: 'normal',
    textAlign: 'left',
    maxHeight: '40px',
    overflow: 'hidden',
}));

const textTheme = (selected: boolean): SxProps<Theme> => {
    return {
        backgroundColor: selected ? AppColors.black : AppColors.white,
        color: selected ? AppColors.white : AppColors.grey.darkish,
    }
}

interface ChatCardProps {
    chat?: ChatSession;
    savedChat?: SavedChat;
    interview?: Interview;
    resume?: SavedResume;
    selected: boolean;
    onClick: () => void;
}

const ChatCard = (props: ChatCardProps) => {
    const { selected, savedChat, chat, interview, resume, onClick } = props;
    const Card = selected ? StyledCard : UnselectedStyledCard;
    const isMobile = useAppSelector(state => state.appState.isMobile);
    const [isHovered, setIsHovered] = useState(false);
    const state = useAppSelector(state => state);
    const dispatch = useAppDispatch();

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const onDelete = async (event: React.MouseEvent) => {
        event.stopPropagation();
        if (savedChat) {
            dispatch(removeSavedChat(savedChat));
            await SavedChatsService.unsaveChat(savedChat);
        } else if (chat) {
            dispatch(DeleteChatAction(chat));
            if (selected) {
                dispatch(clearActiveChat());
            }
        } else if (interview) {
            const isSaved = state.interviewState.interviewsArray.some(i => i.id === interview.id);
            if (isSaved) {
                await InterviewService.removeSavedInterview(interview);
                dispatch(removeSavedInterview(interview));
            } else {
                dispatch(DeleteInterviewAction(interview));
                dispatch(clearActiveChat());
            }
        } else if (resume) {
            await ResumeService.unsaveResume(resume);
            dispatch(removeSavedResume(resume));
            if (selected) {
                clearSelectedResume();
            }
        }
    }
    const title = chat?.title ?? savedChat?.title ?? resume?.title ?? (interview?.type ? interviewTitle(interview, state) : '');
    const updated = chat?.updated ?? savedChat?.chatCreatedAt ?? interview?.updatedAt ?? resume?.updatedAt;

    return (
        <Card
            isMobile={isMobile}
            sx={textTheme(selected)}
            onClick={onClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <StyledTypography variant='h4'>
                    {getFormattedChatDate(new Date(updated ?? Date.now()))}
                </StyledTypography>
                <ClickableOpacityDiv style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '20px', height: '20px', position: 'absolute', top: '5px', right: '5px' }} onClick={onDelete}>
                    <SmallXIcon color={selected ? AppColors.white : (isHovered ? AppColors.grey.darkish : AppColors.grey.neutral)} />
                </ClickableOpacityDiv>
            </div>
            <StyledTypography variant='caption' sx={{ marginTop: '6px' }} >
                {title ?? ''}
            </StyledTypography>
        </Card>
    )
}

export default ChatCard
import { auth } from "../firebaseConfigs";
import Skill from "../shared/models/Skill";

class SkillsService {
    fetchSkillsForSearch = async (searchTerm: string) => {
        if (!searchTerm) return [];
        const token = await auth.currentUser?.getIdToken();

        const res = await fetch(`${process.env.REACT_APP_API_URL}/skills/${searchTerm}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        });

        if (res.status !== 200) {
            throw new Error(`Failed to fetch skills: ${res.status}`);
        }

        const responseJson: Skill[] = await res.json();
        return responseJson;
    }

    fetchTitlesForSearch = async (searchTerm: string) => {
        if (!searchTerm) return [];
        const token = await auth.currentUser?.getIdToken();

        const res = await fetch(`${process.env.REACT_APP_API_URL}/skills/titles/${searchTerm}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        });

        if (res.status !== 200) {
            throw new Error(`Failed to fetch titles: ${res.status}`);
        }

        const responseJson: Skill[] = await res.json();
        return responseJson;
    }

    getRecommendedSkillsAndPositions = async (model: string): Promise<Recommendations | undefined> => {
        try {
            const token = await auth.currentUser?.getIdToken();
            const res = await fetch(`${process.env.REACT_APP_API_URL}/skills/recos`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({ model }),
            });

            if (res.status !== 200) {
                console.error(`Failed to name resume: ${res.status}`);
            }
            const data = await res.json();
            const messages = data["messages"];
            if (messages && messages.length > 1) {
                const content = messages[1]["content"];
                const contentWithoutQuotes = content.replace(/^'|'$/g, '');
                const jsonMatch = contentWithoutQuotes.match(/{[\s\S]*}/);
                if (jsonMatch) {
                    const jsonString = jsonMatch[0];
                    try {
                        const jsonObject = JSON.parse(jsonString);
                        return jsonObject;
                    } catch (error) {
                        console.error('Error parsing JSON:', error);
                    }
                } else {
                    console.error('No JSON string found in the content');
                }
                return contentWithoutQuotes;
            }
            return;
        } catch (error) {
            console.error('Error getting recomendations name:', error);
        }
    };
}

export interface Recommendations {
    skills: string[];
    positions: string[];
}

const skillsService = new SkillsService();

export default skillsService;
import { auth } from "../firebaseConfigs";
import ViewedJob from "../shared/models/ViewedJob";


class JobViewService {
    static recordJobView = async (jobId: string): Promise<ViewedJob | undefined> => {
        try {
            const token = await auth.currentUser?.getIdToken();

            const res = await fetch(`${process.env.REACT_APP_API_URL}/viewedjobs/save`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({ jobId }),
            });

            const data: ViewedJob = await res.json();
            return data;
        } catch (error) {
            console.error('Error recording job view:', error);
        }
    };
}

export default JobViewService;

import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import CaretLeftIcon from '../../assets/icons/caret_left_icon';
import ClockIcon from '../../assets/icons/clock_icon';
import SaveIconUnselected from '../../assets/icons/save_icon_unselected';
import NavigationConstants from '../../navigation/NavigationConstants';
import { MainTabType } from '../../navigation/classes/MainTab';
import { ClearAllSelectedAction } from '../../redux/actions/GlobalActions';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setDrawerIsOpen, setSelectedProfileTab } from '../../redux/reducers/appReducer';
import { clearSelectedSavedResource, setSelectedList, setSelectedSavedResource } from '../../redux/reducers/resourceReducer';
import ClickableOpacityDiv from '../../shared/components/ClickableOpacityDiv';
import { StyledBackButton } from '../../shared/components/SideBarButtons';
import Resource from '../../shared/models/Resource';
import ResourceList from '../../shared/models/ResourceList';
import { AppColors, sharpTransition } from '../../theme/AppTheme';
import ResourceSearchTextInput from './ResourceSearchTextInput';
import ResourceSidebarListView from './ResourceSidebarListView';
import SavedResourceHeader from './SavedResourceHeader';
import SavedResourcesCard from './SavedResourcesCard';
import SavedResourcesSearchView from './SavedResourcesSearchView';

const Sidebar = styled(Box)((props: { isMobile: boolean }) => ({
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    overflowX: 'clip',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    borderRight: `2px solid ${AppColors.grey.light}`,
    height: '100%',
    width: props.isMobile ? '100%' : NavigationConstants.sidebarWidth,
    backgroundColor: AppColors.white,
}));

const SavedResourcesSidebarView = () => {
    const [titleOpacity, setTitleOpacity] = useState(1);
    const [listTitle, setListTitle] = useState('');
    const [searchText, setSearchText] = useState('');
    const selectedList = useAppSelector(s => s.resourceState.selectedList);
    const selectedTab = useAppSelector(s => s.appState.selectedTab);

    const isMobile = useAppSelector(state => state.appState.isMobile);
    const dispatch = useAppDispatch();

    const sidebarRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (selectedList) {
            setTimeout(() => {
                setListTitle(selectedList.name);
            }, 300);
        } else {
            setListTitle('');
        }
    }, [selectedList]);

    useEffect(() => {
        if (selectedTab !== MainTabType.savedResources && selectedList) {
            dispatch(setSelectedList(undefined));
        }
    }, [selectedTab]);

    const handleScroll = () => {
        if (sidebarRef.current) {
            const scrollPosition = sidebarRef.current.scrollTop;
            if (scrollPosition > 25 && titleOpacity === 1) {
                setTitleOpacity(0);
            } else if (scrollPosition <= 25 && titleOpacity === 0) {
                setTitleOpacity(1);
            }
        }
    }

    return (
        <Sidebar isMobile={isMobile} ref={sidebarRef} onScroll={handleScroll}>
            {isMobile && <StyledBackButton />}
            {isMobile && !selectedList &&
                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', zIndex: 9, position: 'absolute', left: 0, right: '12px', top: 0, height: '60px', backgroundColor: AppColors.white }}>
                    <Typography variant='h4' style={{ marginLeft: '60px', marginTop: '8px', fontSize: '18px', opacity: (1 - titleOpacity), transition: `opacity 0.2s ${sharpTransition}` }}>
                        Saved Resources
                    </Typography>
                </div>}
            <SavedResourceHeader isMobile={isMobile}>
                <Typography variant='h4' style={{ fontSize: isMobile ? '18px' : '14px', opacity: titleOpacity, transition: `opacity 0.2s ${sharpTransition}` }}>
                    Saved Resources
                </Typography>
            </SavedResourceHeader>
            <ResourceSearchTextInput
                searchText={searchText}
                onSearchTextChanged={(newText) => setSearchText(newText)}
            />

            {!searchText &&
                <>
                    <ResourceListComponent onSelectedList={(list) => dispatch(setSelectedList(list))} />
                    {selectedList && <ResourceSidebarListView
                        list={selectedList}
                        onClosed={() => dispatch(setSelectedList(undefined))}
                    />}
                </>
            }

            {searchText && <SavedResourcesSearchView searchText={searchText} />}
        </Sidebar>
    )
}

const ScrollingResourceList = styled(Box)((props: { isMobile: boolean }) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    overflowY: props.isMobile ? undefined : 'auto',
    flexGrow: 1,
    width: props.isMobile ? '100%' : NavigationConstants.sidebarWidth,
    paddingBottom: '64px',
}));

interface ResourceListComponentProps {
    onSelectedList: (list: ResourceList) => void;
}

const ResourceListComponent = (props: ResourceListComponentProps) => {
    const { onSelectedList } = props;
    const dispatch = useAppDispatch();
    const user = useAppSelector((s) => s.userState.currentUser);
    const [filteredResources, setFilteredResources] = useState(user?.savedResources ?? []);
    const searchTerm = useAppSelector((s) => s.resourceState.resourceSearchTerm ?? '');
    const selectedResource = useAppSelector((s) => s.resourceState.selectedSavedResource);
    const reloadResourcesToggle = useAppSelector(s => s.resourceState.resourceToggle);
    const searchResourcesToggle = useAppSelector(s => s.resourceState.searchResourceToggle);
    const isMobile = useAppSelector(s => s.appState.isMobile);
    const savedResourceLists = useAppSelector(s => s.resourceState.resourceList);

    useEffect(() => {
        const resources = user?.savedResources ?? [];
        if (searchTerm && searchTerm.trim().length > 0) {
            const lowerSearch = searchTerm.trim().toLowerCase();
            const newFilteredResources = resources.filter(resources => {
                return resources.title?.toLowerCase().includes(lowerSearch)
                    || resources.description?.toLowerCase().includes(lowerSearch)
                    || resources.url?.toLowerCase().includes(lowerSearch);
            });
            setFilteredResources(newFilteredResources);
        } else {
            setFilteredResources(resources);
        }
    }, [reloadResourcesToggle, searchResourcesToggle, user]);

    useEffect(() => {
        dispatch(clearSelectedSavedResource());
    }, []);

    const cardSelected = (resource: Resource) => {
        dispatch(ClearAllSelectedAction());
        dispatch(setSelectedSavedResource(resource));
        dispatch(setSelectedProfileTab(undefined));
        if (isMobile) {
            dispatch(setDrawerIsOpen(false));
        }
    }

    const savedResourceBuckets = (list: ResourceList) => {
        return (
            <ClickableOpacityDiv onClick={() => onSelectedList(list)} style={{ maxWidth: 'calc(100% - 48px)', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '15px', marginBottom: '15px', marginLeft: '24px', marginRight: '24px' }}>
                <div>{list.name} ({list.resources.length})</div>
                <span style={{ transform: 'rotate(180deg)' }}><CaretLeftIcon /></span>
            </ClickableOpacityDiv>
        );
    }

    const activeSavedResourceLists = savedResourceLists.filter(list => list.resources.length > 0);

    return (
        // TODO: Grab the intial full resource list from user.savedResources, save to resource reducer, and then keep that up to date and sorted by last updated
        <ScrollingResourceList isMobile={isMobile}>
            {filteredResources.length > 0 && <>
                <div style={{ maxWidth: 'calc(100% - 48px)', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginTop: '36px', marginBottom: '30px', marginLeft: '24px', marginRight: '24px' }}>
                    <ClockIcon />
                    <div style={{ marginLeft: '15px' }}>Recent</div>
                </div>
                {filteredResources.slice(0, 3).map((resource, index) => <SavedResourcesCard
                    key={index}
                    onClick={() => cardSelected(resource)}
                    resource={resource}
                    selected={!!resource.url && resource.url.trim().length > 0 && selectedResource?.url === resource.url}
                />)}
            </>}
            {activeSavedResourceLists.length > 0 &&
                <>
                    <div style={{ maxWidth: 'calc(100% - 48px)', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginTop: '36px', marginBottom: '15px', marginLeft: '24px', marginRight: '24px' }}>
                        <SaveIconUnselected />
                        <div style={{ marginLeft: '15px' }}>Saved Resources</div>
                    </div>
                    {activeSavedResourceLists.map((list) => {
                        return savedResourceBuckets(list);
                    })}
                </>
            }
            {activeSavedResourceLists.length === 0 && filteredResources.length === 0 &&
                <>
                    <Typography variant='h5' style={{
                        marginTop: '36px', marginBottom: '30px', color: AppColors.grey.darkish
                    }}>
                        Saved resources will appear here
                    </Typography>
                </>
            }
        </ScrollingResourceList >
    );

}

export default SavedResourcesSidebarView;

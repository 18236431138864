import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { useEffect, useRef } from "react";
import { useAppSelector } from "../../redux/hooks";
import MobileURLPreviewCard from "../../shared/components/MobileURLPreviewCard";
import URLPreviewCard from "../../shared/components/URLPreviewCard";
import Resource from "../../shared/models/Resource";

const StyledColumn = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    marginTop: '40px',
    overflow: 'hidden',
}));

const StyledRow = styled('div')((props: { isMobile: boolean }) => ({
    display: 'flex',
    paddingTop: props.isMobile ? '20px' : undefined,
    flexDirection: props.isMobile ? 'column' : 'row',
    alignItems: props.isMobile ? 'center' : 'flex-start',
    justifyContent: 'flex-start',
    gap: props.isMobile ? '24px' : '0px',
    overflowX: props.isMobile ? 'hidden' : 'auto',
    overflowY: 'hidden',
    width: props.isMobile ? '100%' : undefined,
    maxWidth: '100%',
    scrollbarWidth: 'none', // For Firefox
    '-ms-overflow-style': 'none', // For IE and Edge
    '&::-webkit-scrollbar': {
        width: '0px',
        background: 'transparent', // Optional: to make scrollbar area transparent
    },
}));


interface UrlsContentProps {
    urls: string[];
    onBookmarkPressed: (adding: boolean, resource: Resource) => void;

}
const UrlsContent = (props: UrlsContentProps) => {
    const { onBookmarkPressed, urls } = props;
    const isMobile = useAppSelector(store => store.appState.isMobile)
    const ref = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const handleScroll = (event: WheelEvent) => {
            if (Math.abs(event.deltaY) > Math.abs(event.deltaX)) {
                event.preventDefault();
                const parent = ref.current?.closest('.chat-scroll-page');
                if (parent) {
                    (parent as HTMLElement).scrollTop += event.deltaY;
                }
            }
        };

        const element = ref.current;
        if (element) {
            element.addEventListener('wheel', handleScroll, { passive: false });
        }

        return () => {
            if (element) {
                element.removeEventListener('wheel', handleScroll);
            }
        };
    }, []);

    return (
        <StyledColumn>
            <Typography variant='body2' style={{ marginBottom: isMobile ? '20px' : '40px' }}>
                Suggested resources:
            </Typography>
            <StyledRow ref={ref} isMobile={isMobile}>
                {urls.map((url, index) => (
                    isMobile
                        ? <MobileURLPreviewCard key={index} url={url} />
                        : <URLPreviewCard key={index} url={url} onBookmarkPressed={onBookmarkPressed} />
                ))}
            </StyledRow>
        </StyledColumn>
    );
}

export default UrlsContent;

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import Job from '../../shared/models/Job';

interface SavedJobsState {
    jobBuckets: string[];
    jobs: { [key: string]: Job[] };
    jobsArray: Job[];
    savedJobsToggle: boolean;
    selectedJob?: Job;
}

const initialState: SavedJobsState = {
    jobBuckets: [],
    jobs: {},
    jobsArray: [],
    savedJobsToggle: false,
};

export const savedJobsSlice = createSlice({
    name: 'savedjobs',
    initialState,
    reducers: {
        addSavedJob: (state, action: PayloadAction<Job>) => {
            if (state.jobs['Today'] === undefined) {
                state.jobs['Today'] = [];
            }
            state.jobs['Today'].unshift(action.payload);
            state.jobsArray.unshift(action.payload);
            state.savedJobsToggle = !state.savedJobsToggle;
        },
        removeSavedJob: (state, action: PayloadAction<Job>) => {
            Object.keys(state.jobs).forEach((bucket) => {
                state.jobs[bucket] = state.jobs[bucket].filter((cs) =>
                    cs.id !== action.payload.id
                );
                if (state.jobs[bucket]?.length === 0) {
                    delete state.jobs[bucket];
                }
            });
            state.jobsArray = state.jobsArray.filter((cs) => cs.id !== action.payload.id);
            state.savedJobsToggle = !state.savedJobsToggle;
        },
        updateSavedJob: (state, action: PayloadAction<Job>) => {
            Object.keys(state.jobs).forEach((bucket) => {
                state.jobs[bucket] = state.jobs[bucket].map((cs) =>
                    cs.id === action.payload.id ? action.payload : cs
                );
            });
            state.jobsArray = state.jobsArray.map((cs) =>
                cs.id === action.payload.id ? action.payload : cs
            );
        },
        clearSavedJobBuckets: (state) => {
            state.jobBuckets = [];
        },
        setSavedJobBuckets: (state, action: PayloadAction<string[]>) => {
            state.jobBuckets = action.payload;
        },
        setSavedJobs: (state, action: PayloadAction<{ [key: string]: Job[] }>) => {
            state.jobs = action.payload;
            state.jobsArray = Object.values(action.payload).flat();
        },
        toggleSavedJobs: (state) => {
            state.savedJobsToggle = !state.savedJobsToggle;
        },
        setSelectedJob: (state, action: PayloadAction<Job>) => {
            state.selectedJob = action.payload;
        },
        clearSelectedJob: (state) => {
            state.selectedJob = undefined;
        },
        expireJob: (state, action: PayloadAction<Job>) => {
            Object.keys(state.jobs).forEach((bucket) => {
                state.jobs[bucket] = state.jobs[bucket].map((cs) =>
                    cs.id === action.payload.id ? { expired: true, ...action.payload } : cs
                );
            });
            state.jobsArray = state.jobsArray.map((cs) =>
                cs.id === action.payload.id ? { expired: true, ...action.payload } : cs
            );
            state.savedJobsToggle = !state.savedJobsToggle;
        },
    },
})

export const {
    addSavedJob,
    removeSavedJob,
    updateSavedJob,
    clearSavedJobBuckets,
    setSavedJobBuckets,
    setSavedJobs,
    toggleSavedJobs,
    setSelectedJob,
    clearSelectedJob,
    expireJob,
} = savedJobsSlice.actions

export default savedJobsSlice.reducer

import { saveAs } from "file-saver";
import docx from "remark-docx";
import markdown from "remark-parse";
import { unified } from "unified";

export enum DownloadFileType {
    pdf,
    docx
}

const downloadDocx = (content: string, filename: string) => {
    const processor = unified().use(markdown).use(docx, { output: "blob" });
    (async () => {
        const doc = await processor.process(content);
        const blob = await doc.result as Blob;
        saveAs(blob, `${filename}.docx`);
    })();
}

const downloadPDF = async (content: string, filename: string) => {
    // const pdf = await mdToPdf({ content: content });
    // const buffer = Buffer.from(pdf.content);
    // const file = new File([buffer], `${filename}.pdf`, { type: 'application/pdf' });
    // saveAs(file);
}

export const downloadFile = (content: string, filename: string, type: DownloadFileType) => {
    if (type === DownloadFileType.pdf) {
        downloadPDF(content, filename);
    } else {
        downloadDocx(content, filename);
    }
}

export const createDocx = async (content: string, filename: string): Promise<File> => {
    const processor = unified().use(markdown).use(docx, { output: "blob" });
    const doc = await processor.process(content);
    const blob = await doc.result as Blob;
    return new File([blob], filename);
}

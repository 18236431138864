import { Typography } from '@mui/material';
import { ReactNode } from 'react';
import { useAppSelector } from '../../redux/hooks';
import { AppColors } from '../../theme/AppTheme';

interface AdminSettingRowProps {
    title: string;
    value: ReactNode;
}

const AdminSettingRow = (props: AdminSettingRowProps) => {
    const { title, value } = props;
    const isMobile = useAppSelector(state => state.appState.isMobile);
    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginTop: '40px', flexDirection: `${isMobile ? 'column' : 'row'}`, gap: '12px' }}>
            <Typography variant="h4" style={{ textAlign: 'left', width: '200px', minWidth: '200px' }}>
                {title}
            </Typography>
            <div style={{ alignItems: 'center', marginLeft: isMobile ? 0 : 'clamp(40px, 140px, 140px)', color: AppColors.grey.darkish }}>
                {value}
            </div>
        </div>
    )
}

export default AdminSettingRow
import styled from "@emotion/styled";
import { FilledInput, FormControl, InputAdornment, useTheme } from "@mui/material";
import { ChangeEvent } from "react";
import SearchIcon from "../../assets/icons/search_icon";
import SearchClearButton from "../../shared/components/SearchClearButton";
import { AppColors } from "../../theme/AppTheme";

const SearchInput = styled(FormControl)((props: { marginTop?: string }) => ({
    width: '100%',
    marginTop: props.marginTop ?? '46px',
    marginBottom: '17px',
}));

const StyledInput = styled(FilledInput)(() => ({
    marginLeft: '24px',
    marginRight: '24px',
    borderRadius: '8px',
    height: '48px',
    backgroundColor: AppColors.grey.lightest,
    '&:hover': {
        backgroundColor: AppColors.grey.light
    },
}));

interface ResourceSearchTextInputProps {
    searchText: string;
    onSearchTextChanged: (newSearch: string) => void;
    marginTop?: string;
}

const ResourceSearchTextInput = (props: ResourceSearchTextInputProps) => {
    const { searchText, onSearchTextChanged, marginTop } = props;
    const theme = useTheme();

    const handleInputChanged = (event: ChangeEvent<HTMLInputElement>) => {
        const newSearch = event.target.value;
        onSearchTextChanged(newSearch);
    }

    return (
        <SearchInput variant='filled' marginTop={marginTop}>
            <StyledInput
                disableUnderline
                hiddenLabel
                id="outlined-basic"
                type='text'
                startAdornment={
                    <InputAdornment sx={{ width: '18px', height: '18px', margin: 0, padding: 0, marginRight: '12px' }} position="end">
                        <SearchIcon />
                    </InputAdornment>
                }
                endAdornment={searchText.length > 0 &&
                    <SearchClearButton onClick={() => onSearchTextChanged('')} />
                }
                placeholder='Search'
                onChange={handleInputChanged}
                value={searchText}
                sx={{
                    ...theme.typography.h5,
                    '&.Mui-focused': {
                        backgroundColor: AppColors.grey.light
                    },
                    '& input::placeholder': {
                        ...theme.typography.h4,
                        color: AppColors.grey.dark,
                        opacity: 1.0,
                    },
                    input: {
                        ...theme.typography.h4,
                    },
                }}
            />
        </SearchInput>
    );
}

export default ResourceSearchTextInput;
import React from 'react';
import { useAppSelector } from '../../redux/hooks';

const FAQsView: React.FC = () => {
    const isMobile = useAppSelector((s) => s.appState.isMobile);
    return (
        <div style={{ maxWidth: '1200px', width: isMobile ? 'calc(100% - 48px)' : '100%', height: isMobile ? 'calc(100% - 48px)' : '100%', padding: '24px', margin: 'auto' }}>
            <script type="text/javascript" src="https://s3.amazonaws.com/assets.freshdesk.com/widget/freshwidget.js"></script>
            <style type="text/css" media="screen, projection">
                {`
          @import url(https://s3.amazonaws.com/assets.freshdesk.com/widget/freshwidget.css);
        `}
            </style>
            <iframe
                title="Feedback Form"
                className="freshwidget-embedded-form"
                id="freshwidget-embedded-form"
                src="https://gurulink.freshdesk.com/widgets/feedback_widget/new?&widgetType=embedded&formTitle=Feedback+%26+Help&submitTitle=Send+Feedback&submitThanks=Thank+you+for+your+feedback%2C+a+member+of+our+team+will+respond+within+24+hours."
                scrolling="no"
                height="100%"
                width="100%"
                frameBorder="0"
            ></iframe>
        </div>
    );
};

export default FAQsView;

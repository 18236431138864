import styled from "@emotion/styled";
import { Modal, Switch, SwitchProps, Typography, styled as muiStyled } from "@mui/material";
import { multiFactor, sendPasswordResetEmail } from "firebase/auth";
import { CSSProperties, useState } from "react";
import { Circles } from "react-loader-spinner";
import { auth } from "../firebaseConfigs";
import { LogoutAction } from "../redux/actions/GlobalActions";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { setTwoFactorOpen } from "../redux/reducers/userReducer";
import PrimaryButton from "../shared/components/PrimaryButton";
import { AppColors } from "../theme/AppTheme";
import GoogleReauthenticateModal from "./GoogleReauthenticate";
import ReauthenticateModal from "./ReauthenticateModal";
import ProfileSectionHeader from "./components/ProfileSectionHeader";

const IOSSwitch = muiStyled((props: SwitchProps) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    marginRight: '12px',
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: AppColors.pink.dark,
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: AppColors.grey.darkish,
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

const ScrollingWrapper = styled('div')(() => ({
    overflowY: 'auto',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
}));

const StyledContainer = styled('div')((props: { style?: CSSProperties, isMobile: boolean }) => ({
    padding: props.isMobile ? '24px' : '50px',
    maxWidth: '1200px',
    width: props.isMobile ? 'calc(100% - 48px)' : '100%',
    ...props.style,
}));

const AuthenticationPage = () => {
    const user = useAppSelector(store => store.userState.currentUser);
    const isMobile = useAppSelector(store => store.appState.isMobile);
    const [sending, setSending] = useState(false);
    const [sent, setSent] = useState(false);
    const [reauthVisible, setReauthVisible] = useState(false);
    const [googleReauth, setGoogleReauth] = useState(false);
    const dispatch = useAppDispatch();

    const twoFactorEnabled = () => {
        const fbUser = auth.currentUser;
        if (!fbUser) return false;
        const multifactorSettings = multiFactor(fbUser);
        return multifactorSettings.enrolledFactors.length > 0;
    }
    const [twoFAEnabled, setTwoFAEnabled] = useState(twoFactorEnabled());


    const onResetPassword = async () => {
        setSending(true);
        await sendPasswordResetEmail(auth, user?.email ?? '');
        setSending(false);
        setSent(true);
        setTimeout(() => {
            setSent(false);
        }, 2000);
    }


    const on2FAChanged = async () => {
        if (twoFAEnabled) {
            const user = auth.currentUser;
            if (!user) return;
            try {
                const multiFactorUser = multiFactor(user);
                const options = multiFactorUser.enrolledFactors
                await multiFactorUser.unenroll(options[0])
                setTwoFAEnabled(false);
                dispatch(LogoutAction());
            } catch (e) {
                // TODO: Show an error
                console.log('2FA Error', e);
            }
        } else {
            dispatch(setTwoFactorOpen(true));
            setTwoFAEnabled(true);
        }
    }

    const onReauthSuccess = () => {
        setReauthVisible(false);
        on2FAChanged();
    }

    const showReauth = () => {
        const user = auth.currentUser;
        if (!user) return;
        if (user.providerData.length > 0 && user.providerData[0].providerId === 'google.com') {
            setGoogleReauth(true);
        }
        setReauthVisible(true);
    }

    return (
        <ScrollingWrapper>
            <StyledContainer isMobile={isMobile}>
                <ProfileSectionHeader title='Authentication' />
                <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', marginTop: '40px', flexDirection: `${isMobile ? 'column' : 'row'}` }}>
                    <Typography variant="h4" style={{ textAlign: 'left', width: '200px', minWidth: '200px' }}>
                        Login Email
                    </Typography>
                    <Typography variant="subtitle2" style={{ lineHeight: '30px', textAlign: 'left', paddingLeft: isMobile ? 0 : 'clamp(40px, 140px, 140px)', color: AppColors.grey.darkish, marginTop: isMobile ? '15px' : 0 }}>
                        {user?.email ?? '-'}
                    </Typography>
                </div>
                <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', marginTop: '40px', flexDirection: `${isMobile ? 'column' : 'row'}` }}>
                    <Typography variant="h4" style={{ textAlign: 'left', width: '200px', minWidth: '200px' }}>
                        Manage Password
                    </Typography>
                    <Typography variant="subtitle2" style={{ lineHeight: '30px', textAlign: 'left', paddingLeft: isMobile ? 0 : 'clamp(40px, 140px, 140px)', color: AppColors.grey.darkish, marginTop: isMobile ? '15px' : 0 }}>
                        To change your passsword just hit the button below and we'll send an email to <br />{user?.email ?? '-'} with a link to change your password.
                    </Typography>
                </div>
                <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', marginTop: '28px', flexDirection: `${isMobile ? 'column' : 'row'}` }}>
                    <Typography variant="h4" style={{ textAlign: 'left', width: '200px', minWidth: '200px' }}>
                    </Typography>
                    <div style={{ display: 'flex', alignItems: 'center', marginLeft: isMobile ? 0 : 'clamp(40px, 140px, 140px)', color: AppColors.grey.darkish }}>
                        <PrimaryButton smallText height={'48px'} disabled={sending} title={'Send Email'} onClick={onResetPassword} style={{ width: '118px', marginRight: '12px' }} />
                        <Circles visible={sending} color={AppColors.pink.dark} height={30} width={30} />
                        {sent && <Typography variant="subtitle2" style={{ color: AppColors.success }}>
                            Email Sent
                        </Typography>}
                    </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', marginTop: '40px', flexDirection: `${isMobile ? 'column' : 'row'}` }}>
                    <Typography variant="h4" style={{ textAlign: 'left', width: '200px', minWidth: '200px' }}>
                        Two Factor Authentication
                    </Typography>
                    <Typography variant="subtitle2" style={{ lineHeight: '30px', textAlign: 'left', paddingLeft: isMobile ? 0 : 'clamp(40px, 140px, 140px)', color: AppColors.grey.darkish, marginTop: isMobile ? '15px' : 0 }}>
                        Two factor authentication adds an extra layer of security to your account. When enabled, you will be required to enter a code sent to your phone when logging in.
                    </Typography>
                </div>
                <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', marginTop: '28px', flexDirection: `${isMobile ? 'column' : 'row'}` }}>
                    <Typography variant="h4" style={{ textAlign: 'left', width: '200px', minWidth: '200px' }}>
                    </Typography>
                    <div style={{ display: 'flex', alignItems: 'center', marginLeft: isMobile ? 0 : 'clamp(40px, 140px, 140px)', color: AppColors.grey.darkish }}>
                        <IOSSwitch checked={twoFAEnabled} onChange={showReauth} inputProps={{ color: 'red' }} /> {twoFAEnabled ? 'Enabled' : 'Disabled'}
                    </div>
                </div>

                <div style={{ minHeight: '20px', maxHeight: '20px', }} />
                <Modal
                    open={reauthVisible}
                    onClose={() => setReauthVisible(false)}
                    aria-labelledby="reauth-modal"
                    aria-describedby="reauth-modal"
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                    {googleReauth ?
                        <GoogleReauthenticateModal
                            enabling2FA={!twoFAEnabled}
                            onSuccess={() => onReauthSuccess()}
                            onClose={() => setReauthVisible(false)}
                        />
                        : <ReauthenticateModal
                            enabling2FA={!twoFAEnabled}
                            onSuccess={() => onReauthSuccess()}
                            onClose={() => setReauthVisible(false)}
                        />
                    }
                </Modal>
            </StyledContainer>
        </ScrollingWrapper>
    )
}

export default AuthenticationPage;
import {
    MultiFactorUser, PhoneAuthProvider, PhoneMultiFactorGenerator,
    RecaptchaVerifier
} from "firebase/auth";
import { auth } from "../firebaseConfigs";

class TwoFactorService {
    static sendCode = async (user: MultiFactorUser, phoneNumber: string, recaptchaVerifier: RecaptchaVerifier) => {
        try {
            const session = await user.getSession();
            const phoneInfoOptions = {
                phoneNumber: phoneNumber,
                session: session
            };

            const phoneAuthProvider = new PhoneAuthProvider(auth);

            return await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier);
        } catch (error) {
            console.error('Error sending code:', error);
        } finally {
            recaptchaVerifier.clear();
        }
    }

    static verifyCodeAndEnroll = async (user: MultiFactorUser, verificationId: string, code: string) => {
        const cred = PhoneAuthProvider.credential(verificationId, code);
        const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);

        return await user.enroll(multiFactorAssertion, 'Phone');
    }
}

export default TwoFactorService;
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import SavedResume from '../../mainTabs/savedResumes/model/SavedResume';

interface SavedResumeState {
    resumeBuckets: string[];
    resumes: { [key: string]: SavedResume[] };
    resumesArray: SavedResume[];
    savedResumeToggle: boolean;

    selectedResume?: SavedResume;

    coverLettersBuckets: string[];
    coverLetters: { [key: string]: SavedResume[] };
    coverLettersArray: SavedResume[];
}

const initialState: SavedResumeState = {
    resumeBuckets: [],
    resumes: {},
    resumesArray: [],
    savedResumeToggle: false,
    coverLettersBuckets: [],
    coverLetters: {},
    coverLettersArray: [],
};

export const savedResumesSlice = createSlice({
    name: 'savedresumes',
    initialState,
    reducers: {
        addSavedResume: (state, action: PayloadAction<SavedResume>) => {
            if (action.payload.type === 'coverLetter') {
                if (state.coverLetters['Today'] === undefined) {
                    state.coverLetters['Today'] = [];
                }
                state.coverLetters['Today'].unshift(action.payload);
                state.coverLettersArray.unshift(action.payload);
            } else {
                if (state.resumes['Today'] === undefined) {
                    state.resumes['Today'] = [];
                }
                state.resumes['Today'].unshift(action.payload);
                state.resumesArray.unshift(action.payload);
            }
            state.savedResumeToggle = !state.savedResumeToggle;
        },
        removeSavedResume: (state, action: PayloadAction<SavedResume>) => {
            if (action.payload.type === 'coverLetter') {
                Object.keys(state.coverLetters).forEach((bucket) => {
                    state.coverLetters[bucket] = state.coverLetters[bucket].filter((cs) =>
                        cs.id !== action.payload.id
                    );
                    if (state.coverLetters[bucket]?.length === 0) {
                        delete state.coverLetters[bucket];
                    }
                });
                state.coverLettersArray = state.coverLettersArray.filter((cs) => cs.id !== action.payload.id);
            } else {
                Object.keys(state.resumes).forEach((bucket) => {
                    state.resumes[bucket] = state.resumes[bucket].filter((cs) =>
                        cs.id !== action.payload.id
                    );
                    if (state.resumes[bucket]?.length === 0) {
                        delete state.resumes[bucket];
                    }
                });
                state.resumesArray = state.resumesArray.filter((cs) => cs.id !== action.payload.id);
            }
            state.savedResumeToggle = !state.savedResumeToggle;
        },
        updateSavedResume: (state, action: PayloadAction<SavedResume>) => {
            if (action.payload.type === 'coverLetter') {
                Object.keys(state.coverLetters).forEach((bucket) => {
                    state.coverLetters[bucket] = state.coverLetters[bucket].map((cs) =>
                        cs.id === action.payload.id ? action.payload : cs
                    );
                });
                state.coverLettersArray = state.coverLettersArray.map((cs) =>
                    cs.id === action.payload.id ? action.payload : cs
                );
            } else {
                Object.keys(state.resumes).forEach((bucket) => {
                    state.resumes[bucket] = state.resumes[bucket].map((cs) =>
                        cs.id === action.payload.id ? action.payload : cs
                    );
                });
                state.resumesArray = state.resumesArray.map((cs) =>
                    cs.id === action.payload.id ? action.payload : cs
                );
            }
        },
        setSavedResumeBuckets: (state, action: PayloadAction<string[]>) => {
            state.resumeBuckets = action.payload;
        },
        setSavedResumes: (state, action: PayloadAction<{ [key: string]: SavedResume[] }>) => {
            state.resumes = action.payload;
            state.resumesArray = Object.values(action.payload).flat();
        },
        setSavedCoverLetterBuckets: (state, action: PayloadAction<string[]>) => {
            state.coverLettersBuckets = action.payload;
        },
        setSavedCoverLetters: (state, action: PayloadAction<{ [key: string]: SavedResume[] }>) => {
            state.coverLetters = action.payload;
            state.coverLettersArray = Object.values(action.payload).flat();
        },
        toggleSavedResumes: (state) => {
            state.savedResumeToggle = !state.savedResumeToggle;
        },
        setSelectedResume: (state, action: PayloadAction<SavedResume>) => {
            state.selectedResume = action.payload;
        },
        clearSelectedResume: (state) => {
            state.selectedResume = undefined;
        },
    },
})

export const {
    addSavedResume,
    removeSavedResume,
    setSavedResumeBuckets,
    setSavedResumes,
    toggleSavedResumes,
    setSelectedResume,
    clearSelectedResume,
    updateSavedResume,
    setSavedCoverLetterBuckets,
    setSavedCoverLetters,
} = savedResumesSlice.actions

export default savedResumesSlice.reducer

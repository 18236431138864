import styled from "@emotion/styled";
import { AppColors } from "../../theme/AppTheme";

const AuthModalDiv = styled('div')((props: { isMobile: boolean, fitHeight?: boolean }) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: props.isMobile ? '100%' : '70%',
    height: props.isMobile ? '100%' : props.fitHeight ? undefined : '90%',
    maxWidth: props.isMobile ? '100%' : '650px',
    maxHeight: props.isMobile ? '100%' : '760px',
    overflowY: 'auto',
    backgroundColor: AppColors.white,
    padding: props.isMobile ? '24px 48px' : '54px 70px 64px',
}));

export default AuthModalDiv;

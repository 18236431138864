import { Modal } from '@mui/material';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import NewChatPlusIcon from '../../assets/icons/new_chat_plus_icon';
import SidebarIcon from '../../assets/icons/sidebar_icon';
import AuthFlowContainer from '../../auth/AuthFlowContainer';
import { auth } from '../../firebaseConfigs';
import ProfileCard from '../../profile/ProfileCard';
import ProfileSidebar from '../../profile/ProfileSidebar';
import { ClearAllFocusedAction, ClearAllSelectedAction } from '../../redux/actions/GlobalActions';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { clearActiveChat, setDrawerIsOpen, setSelectedProfileTab, setSelectedTab } from '../../redux/reducers/appReducer';
import { setOnboarding } from '../../redux/reducers/userReducer';
import ConfirmationModal from '../../shared/components/ConfirmationModal';
import zIndicies from '../../shared/utils/zIndexConstants';
import { AppColors, sharpTransition } from '../../theme/AppTheme';
import NavigationConstants from '../NavigationConstants';
import { MainTab, MainTabType, profileTabIndex } from '../classes/MainTab';
import FullLogo from './FullLogo';
import Sidebar from './Sidebar';
import TabActionButton from './TabActionButton';
import TabButton from './TabButton';

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '0px',
    paddingTop: '52px',
    paddingBottom: '52px',
    alignItems: 'start',
    justifyContent: 'center',
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const DrawerRow = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
}));
interface AppDrawerProps {
    isMobile: boolean;
}

const AppDrawer = (props: AppDrawerProps) => {
    const { isMobile } = props;
    const { t } = useTranslation();
    const user = useAppSelector((store) => store.userState.currentUser);
    const onboarding = useAppSelector((store) => store.userState.onboarding);
    const twoFactor = useAppSelector((store) => store.userState.twoFactorOpen);
    const open = useAppSelector((store) => store.appState.drawerIsOpen);
    const selectedTab = useAppSelector((store) => store.appState.selectedTab);
    const sidebar = useAppSelector((s) => s.appState.sidebar);
    const selectedProfileTab = useAppSelector((s) => s.appState.selectedProfileTab);
    const fbUser = useAppSelector((s) => s.userState.fbUser);
    const [logoutOpen, setLogoutOpen] = useState(false);
    const dispatch = useAppDispatch();

    const drawerWidth = open ? NavigationConstants.drawerWidthOpen : NavigationConstants.drawerWidthClosed;

    var startX: number | null = 0;

    const handleProfileClicked = () => {
        if (selectedTab === profileTabIndex) {
            dispatch(setSelectedTab(-1));
        } else {
            dispatch(setSelectedTab(profileTabIndex));
        }
    }


    const handleDrawerOpen = () => {
        dispatch(setDrawerIsOpen(true));
    };

    const handleDrawerClose = () => {
        dispatch(setDrawerIsOpen(false));
    };

    const handleTouchStart = (event: React.TouchEvent) => {
        startX = event.touches[0].clientX;
    };

    const handleTouchEnd = (event: React.TouchEvent) => {
        if (startX !== null) {
            const endX = event.changedTouches[0].clientX;
            const deltaX = startX - endX;

            const swipeThreshold = 50;

            if (deltaX > swipeThreshold) {
                handleDrawerClose();
            }
        }
        startX = null;
    };
    const onAppLogoClicked = () => {
        dispatch(setSelectedTab(-1));
        dispatch(clearActiveChat());
        dispatch(ClearAllFocusedAction());
        dispatch(ClearAllSelectedAction());
        if (isMobile) {
            dispatch(setDrawerIsOpen(false));
        }
    }

    const fullScreenModalOpen = !user || onboarding || twoFactor || (fbUser !== null && fbUser.emailVerified === false);

    return (
        <MuiDrawer
            sx={{
                margin: 0,
                zIndex: isMobile ? zIndicies.appDrawerMobile : zIndicies.appDrawerDesktop,
                whiteSpace: 'nowrap',
                boxSizing: 'border-box',
                overflowX: 'hidden',
                '& .MuiDrawer-paper': {
                    borderWidth: 0,
                    overflowX: 'hidden',
                },
                overflow: 'hidden',
            }} variant="permanent" open={open}>
            <DrawerRow>
                <Box
                    onTouchStart={handleTouchStart}
                    onTouchEnd={handleTouchEnd}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        alignContent: 'space-between',
                        flex: 1,
                        width: isMobile ? (open ? '93vw' : 0) : drawerWidth,
                        transition: `width 0.2s ${sharpTransition}`,
                        height: isMobile ? '100%' : '100vh',
                        overflow: selectedTab >= 0 ? 'hidden' : 'auto',
                        paddingBottom: 0,
                        marginBottom: 0,
                    }}>
                    <Box>
                        <DrawerHeader>
                            <FullLogo onClick={onAppLogoClicked} open={open} />
                        </DrawerHeader>
                        <List sx={{ py: 0 }}>
                            {Object.values(MainTabType).filter((v) => !isNaN(Number(v))).map((mainTab, index) => {
                                const isSelected = index === selectedTab;
                                const tab = new MainTab(mainTab as MainTabType);
                                return (
                                    <TabButton
                                        key={index}
                                        text={tab.localizedName()}
                                        icon={tab.icon(isSelected ? AppColors.black : AppColors.grey.dark)}
                                        fullWidth={open}
                                        isSelected={index === selectedTab}
                                        onClick={() => dispatch(setSelectedTab(index === selectedTab ? -1 : index))}
                                    />
                                );
                            })}
                        </List>
                        <TabButton
                            text={t('closeSidebar')}
                            icon={<SidebarIcon />}
                            fullWidth={open}
                            isSelected={false}
                            onClick={open ? handleDrawerClose : handleDrawerOpen}
                        />
                        <TabActionButton
                            text='New Chat'
                            icon={<NewChatPlusIcon />}
                            fullWidth={open}
                            onClick={onAppLogoClicked}
                        />
                    </Box>
                    {user &&
                        <ProfileCard user={user} open={open} onClick={handleProfileClicked} />
                    }
                    <Modal onClose={() => dispatch(setOnboarding(false))} open={fullScreenModalOpen}
                        aria-labelledby="authentication-modal"
                        aria-describedby="prompts the user to log in or sign up"
                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    >
                        <AuthFlowContainer />
                    </Modal>
                    {isMobile && (!!sidebar || selectedTab === profileTabIndex) && <div style={{ overflowX: 'hidden', position: 'absolute', left: 0, right: 0, top: 0, bottom: 0 }}>
                        {selectedTab === profileTabIndex ? <ProfileSidebar width={100} tab={selectedProfileTab ?? -1}
                            setTab={(tab) => {
                                dispatch(setSelectedProfileTab(tab))
                                dispatch(setDrawerIsOpen(false));
                            }}
                            logout={() => setLogoutOpen(true)} /> : sidebar}
                    </div>
                    }
                </Box>
                {!isMobile && <Sidebar />}
                <Modal
                    sx={{ zIndex: zIndicies.modal }}
                    open={logoutOpen}>
                    <ConfirmationModal title="Log out?" subtitle="Are you sure you want to log out?" onCancel={() => { setLogoutOpen(false) }} onConfirm={() => { setLogoutOpen(false); auth.signOut(); }} confirmText="Yes" />
                </Modal>
            </DrawerRow>
        </MuiDrawer >
    )
}

export default AppDrawer;


class DesiredIncome {
    public hourlyRate?: number;
    public salaryBottom?: number;
    public salaryTop?: number;

    constructor(stringForm: string | string[]) {
        if (!Array.isArray(stringForm)) {
            const splitString = stringForm.split(',');
            for (let i = 0; i < splitString.length; i++) {
                const value = splitString[i].trim();
                if (value === 'h') {
                    this.hourlyRate = parseFloat(splitString[i + 1]);
                } else if (value === 'sb') {
                    this.salaryBottom = parseFloat(splitString[i + 1]);
                } else if (value === 'st') {
                    this.salaryTop = parseFloat(splitString[i + 1]);
                }
            }
        }
    }

    static fromValues(hourlyRate?: number, salaryBottom?: number, salaryTop?: number): DesiredIncome {
        const desiredIncome = new DesiredIncome('');
        desiredIncome.hourlyRate = hourlyRate;
        desiredIncome.salaryBottom = salaryBottom;
        desiredIncome.salaryTop = salaryTop;
        return desiredIncome;
    }

    get displayString(): string {
        return `${this.hourlyRate ? `\$${this.hourlyRate ?? ''}/hr  |  ` : ''}${this.salaryBottom ? `\$${this.salaryBottom.toLocaleString() ?? '-'} - \$${this.salaryTop?.toLocaleString() ?? '-'} /year` : ''}`;
    }

    toStringForm(): string {
        let returnString = '';
        if (this.hourlyRate) {
            returnString += `h,${this.hourlyRate},`;
        }
        if (this.salaryBottom) {
            returnString += `sb,${this.salaryBottom},`;
        }
        if (this.salaryTop) {
            returnString += `st,${this.salaryTop},`;
        }
        return returnString;
    }
}

export default DesiredIncome;
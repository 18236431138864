import DesiredIncome from "./DesiredIncome";

interface UserInfo {
    desiredPositions?: string[];
    desiredIncome?: DesiredIncome;
    preferredWorkEnvironments?: string[];
    preferredLocations?: string[];
    skills?: string[];
}

export const generatePromptInfo = (info?: UserInfo): string => {
    if (!info) return '';

    let returnString = '';
    if (info.desiredIncome) {
        returnString += `Desired Income ranges: ${info.desiredIncome.salaryBottom} - ${info.desiredIncome.salaryTop}\n`;
    }
    if (info.desiredPositions) {
        returnString += `Desired Positions: ${info.desiredPositions.join(', ')}\n`;
    }
    if (info.preferredWorkEnvironments) {
        returnString += `Preferred Work Environments: ${info.preferredWorkEnvironments.join(', ')}\n`;
    }
    // if (info.skills) {
    //     returnString += `Skills: ${info.skills.join(', ')}\n`;
    // }
    if (info.preferredLocations) {
        returnString += `Preferred Locations: ${info.preferredLocations.join(', ')}\n`;
    }
    return returnString;
}

export const userInfoOrder = ["desiredIncome", "preferredLocations", "preferredWorkEnvironments"];

export default UserInfo;

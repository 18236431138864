import styled from '@emotion/styled';
import { Slider, Typography } from '@mui/material';
import { useLayoutEffect, useRef, useState } from 'react';
import useClickedOutside from '../../hooks/useClickedOutside';
import DesiredIncome from '../../profile/models/DesiredIncome';
import { useAppSelector } from '../../redux/hooks';
import ClickableOpacityDiv from '../../shared/components/ClickableOpacityDiv';
import PPTextInput from '../../shared/components/PPTextInput';
import { AppColors, BoxShadow } from '../../theme/AppTheme';

const Container = styled('div')((props: { isMobile: boolean }) => ({
    position: 'absolute',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: props.isMobile ? '95%' : '80%',
    backgroundColor: 'white',
    paddingTop: '18px',
    paddingBottom: '25px',
    boxShadow: BoxShadow,
    zIndex: 9999999,
    borderRadius: '12px',
}));

const TopBar = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    backgroundColor: 'white',
    boxSizing: 'border-box',
    marginBottom: '15px',
    padding: '0px 15px',
});

const Content = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    width: '100%',
    padding: '0px 15px',
    boxSizing: 'border-box',
});

const InputRow = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginBottom: '16px',
    padding: '0 16px',
});

interface IncomeOptionProps {
    value: DesiredIncome;
    onDone: () => void;
    onHourlyChanged: (hourly: string) => void;
    onSalaryChanged: (bottom: number, top: number) => void;
    anchorElement: HTMLElement | null;
    scrollContainer: HTMLElement | null;
}
const IncomeOption = (props: IncomeOptionProps) => {
    const { value, onHourlyChanged, onSalaryChanged, scrollContainer, anchorElement, onDone } = props;
    const isMobile = useAppSelector(store => store.appState.isMobile);
    const containerRef = useRef<HTMLDivElement>(null);

    const [currentHourly, setCurrentHourly] = useState(`${value.hourlyRate ?? ''}`);

    useClickedOutside(containerRef, onDone);

    useLayoutEffect(() => {
        if (anchorElement && containerRef.current && scrollContainer) {
            const anchorRect = anchorElement.getBoundingClientRect();
            const container = containerRef.current;
            const containerRect = container.getBoundingClientRect();
            const scrollContainerRect = scrollContainer.getBoundingClientRect();
            const topPosition = anchorRect.top + scrollContainer.scrollTop - scrollContainerRect.top;
            const adjustedLeft = anchorRect.left - scrollContainerRect.left;

            const widthDifference = containerRect.width - anchorRect.width;
            container.style.top = `${topPosition}px`;
            container.style.left = `${adjustedLeft - (widthDifference / 2.0)}px`;
        }
    }, [anchorElement, scrollContainer, containerRef]);

    const cleanNumericInput = (value: string): string => {
        let cleanHourly = value.replace(/[^0-9.]/g, '');
        const parts = cleanHourly.split('.');
        if (parts.length > 2) {
            cleanHourly = parts[0] + '.' + parts.slice(1).join('');
        }
        if (parts.length === 2) {
            parts[1] = parts[1].substring(0, 2);
            cleanHourly = parts[0] + '.' + parts[1];
        }
        if (cleanHourly.startsWith('.')) {
            cleanHourly = '0' + cleanHourly;
        }
        return cleanHourly;
    }

    const SALARY_MIN = 0;
    const SALARY_MAX = 300000;

    const [salaryRange, setSalaryRange] = useState<number[]>([
        value.salaryBottom ?? SALARY_MIN,
        value.salaryTop ?? SALARY_MAX
    ]);

    const handleSalaryChange = (event: Event, newValue: number | number[]) => {
        console.log('handleSalaryChange', newValue);
        if (Array.isArray(newValue)) {
            setSalaryRange(newValue);
            onSalaryChanged(newValue[0], newValue[1]);
        }
    };

    const handleBottomSalaryChange = (newValue: string) => {
        const cleanValue = cleanNumericInput(newValue);
        setSalaryRange([parseInt(cleanValue), salaryRange[1]]);
        onSalaryChanged(parseInt(cleanValue), salaryRange[1]);
    }

    const handleTopSalaryChange = (newValue: string) => {
        const cleanValue = cleanNumericInput(newValue);
        setSalaryRange([salaryRange[0], parseInt(cleanValue)]);
        onSalaryChanged(salaryRange[0], parseInt(cleanValue));
    }

    return (
        <Container isMobile={isMobile} onClick={(e) => e.stopPropagation()} ref={containerRef}>
            <TopBar>
                <Typography variant='body1'>Desired Income</Typography>
                <ClickableOpacityDiv onClick={onDone}>
                    <Typography variant='subtitle1'
                        style={{
                            color: AppColors.pink.dark,
                            fontWeight: 400,
                        }}>
                        Done
                    </Typography>
                </ClickableOpacityDiv>
            </TopBar>
            <Content>
                <Typography variant='h6' style={{ marginBottom: '8px' }}>Salary Range</Typography>
                <InputRow>
                    <div style={{ width: '100%', padding: '0 16px' }}>
                        <Slider
                            value={salaryRange}
                            onChange={handleSalaryChange}
                            valueLabelDisplay="auto"
                            min={SALARY_MIN}
                            max={300000}
                            color='secondary'
                            valueLabelFormat={(value) => `$${value.toLocaleString()}`}
                            step={1000}
                            marks={[
                                { value: SALARY_MIN, label: `$${SALARY_MIN}` },
                                { value: 75000, label: '$75k' },
                                { value: 150000, label: '$150k' },
                                { value: 225000, label: '$225k' },
                                { value: 300000, label: `$300k+` },
                            ]}
                        />
                        <Typography variant='body1' style={{ width: '100%', textAlign: 'center', marginTop: '8px' }}>Selected Range</Typography>
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '0px' }}>
                            <PPTextInput
                                id={'language-search-input'}
                                value={salaryRange[0] === 0 ? 'Up to ' : `\$${salaryRange[0].toLocaleString()}`}
                                onValueChanged={handleBottomSalaryChange}
                                bottomPadding='0px'
                                leadingPadding='8px'
                                fillColor={AppColors.white}
                                height='40px'
                                textAlign='right'
                            />
                            {salaryRange[0] > 0 && <Typography variant='body2' style={{ margin: '0px 12px', paddingTop: '6px' }}> - </Typography>}
                            <PPTextInput
                                id={'language-search-input'}
                                value={`\$${salaryRange[1].toLocaleString()}${salaryRange[1] === 300000 ? '+' : ''}`}
                                onValueChanged={handleTopSalaryChange}
                                bottomPadding='0px'
                                leadingPadding='8px'
                                fillColor={AppColors.white}
                                height='40px'
                            />
                        </div>
                    </div>
                </InputRow>
                <Typography variant='h6' style={{ marginBottom: '8px' }}>Hourly Rate</Typography>
                <div style={{ width: '50%' }}>
                    <PPTextInput
                        id={'hourly-entry'}
                        value={currentHourly}
                        onValueChanged={(value) => {
                            const cleanHourly = cleanNumericInput(value);
                            setCurrentHourly(cleanHourly);
                            onHourlyChanged(cleanHourly);
                        }}
                        bottomPadding="30px"
                        placeholder={'hourly rate'}
                        leadingIcon={<div style={{ marginLeft: '8px' }}>$</div>}
                        height='40px'
                    />
                </div>
            </Content>
        </Container>
    )
}

export default IncomeOption;

import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import ChatCard from "../../chat/components/ChatCard";
import NavigationConstants from "../../navigation/NavigationConstants";
import { FetchAndShowChatAction } from "../../redux/actions/ChatActions";
import { ClearAllFocusedAction, ClearAllSelectedAction } from "../../redux/actions/GlobalActions";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setDrawerIsOpen } from "../../redux/reducers/appReducer";
import ChatSession from "../../shared/models/ChatSession";

const ScrollingList = styled('div')((props: { isMobile: boolean }) => ({
    display: 'flex',
    flexDirection: 'column',
    overflowY: props.isMobile ? undefined : 'auto',
    width: props.isMobile ? '100%' : NavigationConstants.sidebarWidth,
    paddingBottom: '50px',
}));

interface ChatHistorySearchViewProps {
    searchText: string;
}

const ChatHistorySearchView = (props: ChatHistorySearchViewProps) => {
    const { searchText } = props;
    const [filteredChats, setFilteredChats] = useState<ChatSession[]>([]);
    const chatHistory = useAppSelector((s) => s.appState.chatHistoryArray ?? []);
    const chatToggle = useAppSelector((s) => s.userState.savedChatsToggle);
    const isMobile = useAppSelector((s) => s.appState.isMobile);
    const selectedChat = useAppSelector((s) => s.appState.activeChat);

    const dispatch = useAppDispatch();

    const cardSelected = (chat: ChatSession) => {
        dispatch(FetchAndShowChatAction(chat.sessionId));
        if (isMobile) {
            dispatch(ClearAllFocusedAction());
            dispatch(ClearAllSelectedAction());
            dispatch(setDrawerIsOpen(false));
        }
    }

    useEffect(() => {
        const filtered = chatHistory.filter((chat) => chat.title.toLowerCase().includes(searchText.toLowerCase()));
        setFilteredChats(filtered);
    }, [searchText, chatToggle]);

    return (
        <ScrollingList isMobile={isMobile}>
            {filteredChats.map((chat, index) => <ChatCard key={index} onClick={() => cardSelected(chat)} chat={chat} selected={selectedChat?.sessionId === chat.sessionId} />)}
        </ScrollingList>
    );
}

export default ChatHistorySearchView
import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import ChatCard from "../../chat/components/ChatCard";
import NavigationConstants from "../../navigation/NavigationConstants";
import { ClearAllFocusedAction } from "../../redux/actions/GlobalActions";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setDrawerIsOpen } from "../../redux/reducers/appReducer";
import { setSelectedResume } from "../../redux/reducers/savedResumeReducer";
import SavedResume from "./model/SavedResume";

const ScrollingList = styled('div')((props: { isMobile: boolean }) => ({
    display: 'flex',
    flexDirection: 'column',
    overflowY: props.isMobile ? undefined : 'auto',
    width: props.isMobile ? '100%' : NavigationConstants.sidebarWidth,
    paddingBottom: '50px',
}));

interface ChatHistorySearchViewProps {
    searchText: string;
}

const SavedResumeSearchView = (props: ChatHistorySearchViewProps) => {
    const { searchText } = props;
    const [filteredResumes, setFilteredResumes] = useState<SavedResume[]>([]);
    const savedResumes = useAppSelector((s) => s.savedResumesState.resumesArray ?? []);
    const resumeToggle = useAppSelector((s) => s.savedResumesState.savedResumeToggle);
    const isMobile = useAppSelector((s) => s.appState.isMobile);
    const selectedResume = useAppSelector((s) => s.savedResumesState.selectedResume);

    const dispatch = useAppDispatch();

    const cardSelected = (resume: SavedResume) => {
        dispatch(setSelectedResume(resume));
        if (isMobile) {
            dispatch(ClearAllFocusedAction());
            dispatch(setDrawerIsOpen(false));
        }
    }

    useEffect(() => {
        const filtered = savedResumes.filter((resume) => resume.title.toLowerCase().includes(searchText.toLowerCase()) || resume.content.toLowerCase().includes(searchText.toLowerCase()));
        setFilteredResumes(filtered);
    }, [searchText, resumeToggle]);

    return (
        <ScrollingList isMobile={isMobile}>
            {filteredResumes.map((resume, index) => <ChatCard key={index} onClick={() => cardSelected(resume)} resume={resume} selected={selectedResume?.id === resume.id} />)}
        </ScrollingList>
    );
}

export default SavedResumeSearchView
import styled from '@emotion/styled';
import { useState } from 'react';
import { Bars } from 'react-loader-spinner';
import SkillsScreen from '../mainTabs/profile/SkillsScreen';
import TitlesScreen from '../mainTabs/profile/TitlesScreen';
import JobInfoModal from '../profile/modals/JobInfoModal';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { clearAuthError, setOnboarding } from '../redux/reducers/userReducer';
import { AppColors } from '../theme/AppTheme';
import Enable2FAScreen from './Enable2FAScreen';
import ForgotPasswordScreen from './ForgotPasswordScreen';
import LoginScreen from './LoginScreen';
import OnboardingResumeScreen from './OnboardingResumeScreen';
import SignUpInfoScreen from './SignUpInfoScreen';
import SignUpScreen from './SignUpScreen';
import VerifyEmailScreen from './VerifyEmailScreen';

const StyledLoadingDiv = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
}));

enum AuthType {
    signUp,
    signUpInfo,
    selectSkills,
    selectPositions,
    jobInfo,
    verifyEmail,
    login,
    forgot,
    none
}

const AuthFlowContainer = () => {
    const [type, setType] = useState(AuthType.signUp);
    const [email, setEmail] = useState('');
    const initializing = useAppSelector((s) => s.userState.initializing);
    const user = useAppSelector((s) => s.userState.currentUser);
    const onboarding = useAppSelector((s) => s.userState.onboarding);
    const twoFactor = useAppSelector((s) => s.userState.twoFactorOpen);
    const twoFactorResolver = useAppSelector((s) => s.userState.twoFactorResolver);
    const fbUser = useAppSelector((s) => s.userState.fbUser);
    const dispatch = useAppDispatch();

    if (initializing) {
        return <StyledLoadingDiv>
            <Bars
                height="30"
                width="30"
                color={AppColors.pink.dark}
                ariaLabel="bars-loading"
                visible={true}
            />
        </StyledLoadingDiv>
    }

    const onEmailVerified = () => {
        setType(AuthType.login);
    }

    console.log('Cuurent state:', type, email, initializing, user, onboarding, twoFactor, twoFactorResolver, fbUser)

    if (twoFactor && twoFactorResolver !== undefined) {
        return <Enable2FAScreen fromOnboarding={onboarding} />;
    } else if (fbUser !== null && !fbUser.emailVerified) {
        return <VerifyEmailScreen onEmailVerified={onEmailVerified} />;
    } else if (twoFactor) {
        return <Enable2FAScreen fromOnboarding={onboarding} />;
    } else if (type === AuthType.selectSkills) {
        return <SkillsScreen onboarding hideCloseButton onClose={() => setType(AuthType.selectPositions)} />;
    } else if (type === AuthType.selectPositions) {
        return <TitlesScreen onboarding hideCloseButton onClose={() => setType(AuthType.jobInfo)} />;
    } else if (type === AuthType.jobInfo) {
        return <JobInfoModal
            hideCloseButton
            initialInfo={user?.info ?? {}}
            onDonePressed={() => dispatch(setOnboarding(false))}
            onBackPressed={() => setType(AuthType.selectPositions)}
        />
    } else if (!!user && onboarding && (fbUser === null || fbUser?.emailVerified)) {
        return <OnboardingResumeScreen onDone={() => setType(AuthType.selectSkills)} />;

    } else if (type === AuthType.signUpInfo) {
        return <SignUpInfoScreen
            initialEmail={email}
            switchToSignUpPressed={() => {
                dispatch(clearAuthError());
                setType(AuthType.signUp);
            }}
        />
    } else if (type === AuthType.login) {
        return <LoginScreen switchToSignUpPressed={() => {
            dispatch(clearAuthError());
            setType(AuthType.signUp);
        }}
            switchToForgotPasswordPressed={() => {
                dispatch(clearAuthError());
                setType(AuthType.forgot);
            }}
        />
    } else if (type === AuthType.forgot) {
        return <ForgotPasswordScreen switchToLoginPressed={() => {
            dispatch(clearAuthError());
            setType(AuthType.login);
        }} />
    }
    return <SignUpScreen
        switchToSignUpInfoPressed={(enteredEmail) => {
            setEmail(enteredEmail)
            dispatch(clearAuthError());
            setType(AuthType.signUpInfo);
        }}
        switchToLoginPressed={() => {
            dispatch(clearAuthError());
            setType(AuthType.login);
        }} />
}

export default AuthFlowContainer;
import styled from "@emotion/styled";
import { Modal, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import EditIcon from "../../assets/icons/edit_icon";
import { Strings } from "../../i18n";
import SkillsArray from "../../mainTabs/profile/SkillsArray";
import TitlesScreen from "../../mainTabs/profile/TitlesScreen";
import { useAppSelector } from "../../redux/hooks";
import { AppColors } from "../../theme/AppTheme";
import ProfileSectionHeader from "./ProfileSectionHeader";

const StyledColumn = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: '100%',
    marginBottom: '80px',
}));

const ProfilePositionsSection = () => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const isMobile = useAppSelector(store => store.appState.isMobile);
    const user = useAppSelector(store => store.userState.currentUser);

    const onEditPressed = () => {
        setOpen(true);
    }

    const stacked = isMobile;

    return (
        <StyledColumn>
            <ProfileSectionHeader title={'Desired Positions'} icon={<EditIcon />} action={t(Strings.edit)} onActionPressed={onEditPressed} />
            <div key={'positions-section'} style={{ display: 'flex', flexDirection: `${stacked ? 'column' : 'row'}`, alignItems: 'flex-start', justifyContent: 'flex-start', paddingTop: isMobile ? '25px' : '40px' }}>
                <Typography variant="h4" style={{ textAlign: 'left', width: stacked ? '100%' : isMobile ? '100px' : '200px', minWidth: isMobile ? '100px' : '200px' }}>
                    Desired Positions:
                </Typography>
                <Typography variant="subtitle2" style={{ marginTop: stacked ? '15px' : 0, lineHeight: '30px', textAlign: 'left', paddingLeft: stacked ? 0 : isMobile ? 'clamp(24px, 60px, 140px)' : 'clamp(40px, 140px, 140px)', color: AppColors.grey.darkish }}>
                    <SkillsArray emptyStateText="" skills={user?.info?.desiredPositions ?? []} title='' allSelected={false} />
                </Typography>
            </div>
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="edit-titles"
                aria-describedby="edit-titles-info"
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
                <TitlesScreen onClose={() => setOpen(false)} />
            </Modal>
        </StyledColumn>
    )
}

export default ProfilePositionsSection
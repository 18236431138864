import { ReactNode } from "react";

enum InterviewQuestionsType {
    resume = 'resume',
    job = 'job',
    specified = 'specified',
}

export const textForInterviewQuestionsType = (type: InterviewQuestionsType): ReactNode => {
    switch (type) {
        case InterviewQuestionsType.resume:
            return <>I would like to prepare for a general interview based on my <b>current resume</b></>
        case InterviewQuestionsType.job:
            return <>I would like to prepare for an interview for one of my <b>saved jobs</b></>
        case InterviewQuestionsType.specified:
            return <>I would like to prepare for an interview for a <b>specific type of job / role</b> I'm interested in</>
    }
}

export default InterviewQuestionsType;
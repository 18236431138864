import { Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Bars } from "react-loader-spinner";
import ExpandButton from "../../shared/components/ExpandButton";
import { AppColors } from "../../theme/AppTheme";
import Pill from "./Pill";

interface SkillsArrayProps {
  title?: string;
  subtitle?: string;
  skills: string[];
  selectedSkills?: string[];
  onSelected?: (skill: string) => void;
  onRemove?: (skill: string) => void;
  allSelected: boolean;
  greyPills?: boolean;
  loading?: boolean;
  emptyStateText: string;
  noExpand?: boolean;
}

const SkillsArray = (props: SkillsArrayProps) => {
  const { title, subtitle, skills, allSelected, selectedSkills, greyPills, loading, emptyStateText, noExpand, onRemove, onSelected } = props;
  const [hasExpandButton, setHasExpandButton] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const contentRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (contentRef.current) {
      setHasExpandButton(contentRef.current.scrollHeight > 156);
    }
  }, [skills]);

  return (
    <div style={{ width: '100%' }}>
      {title && <Typography variant="body2">
        {title}
      </Typography>}
      {subtitle && <Typography variant="h5" style={{ marginTop: '12px', color: AppColors.grey.darkish }}>
        {subtitle}
      </Typography>}
      {(title || subtitle) && <div style={{ marginBottom: '28px' }}></div>}
      {loading ? <Bars
        height="30"
        width="30"
        color={AppColors.pink.dark}
        ariaLabel="bars-loading"
        visible={true}
      /> : skills.length === 0 ?
        <Typography variant="h6" style={{ marginBottom: '0px', color: AppColors.grey.darkish }}>
          {emptyStateText}
        </Typography>
        : <div ref={contentRef} style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '8px', maxHeight: expanded ? undefined : '156px', overflow: 'hidden' }}>
          {skills.map((skill, index) => (
            <Pill
              grey={greyPills}
              key={index}
              value={skill}
              selected={allSelected || (selectedSkills ? selectedSkills.includes(skill) : false)}
              onClick={!allSelected && onSelected ? () => onSelected(skill) : undefined}
              onRemove={onRemove ? () => onRemove(skill) : () => { }}
            />
          ))}
        </div>
      }
      {!noExpand && hasExpandButton && <ExpandButton isExpanded={expanded} onClick={() => setExpanded(!expanded)} bottomMargin="0px" topMargin="30px" />}
    </div>
  )
}

export default SkillsArray
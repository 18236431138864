import { Typography } from '@mui/material';
import { getAuth } from "firebase/auth";
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Bars } from 'react-loader-spinner';
import GoogleLogo from '../assets/images/google.png';
import Seperator from '../chat/components/Seperator';
import SocialButton from '../chat/components/SocialButton';
import { Strings } from '../i18n';
import { GoogleAuthAction } from '../redux/actions/SocialAuthActions';
import { LogInAction } from '../redux/actions/UserActions';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import ClickableOpacityDiv from '../shared/components/ClickableOpacityDiv';
import PrimaryButton from '../shared/components/PrimaryButton';
import { AppColors } from '../theme/AppTheme';
import AuthModalDiv from './components/AuthModalDiv';
import EmailInput from './components/EmailInput';
import PasswordInput from './components/PasswordInput';
import { isValidEmail } from './helpers/EmailHelper';

interface LoginScreenProps {
    switchToSignUpPressed: () => void;
    switchToForgotPasswordPressed: () => void;
}

const LoginScreen = (props: LoginScreenProps) => {
    const { switchToSignUpPressed, switchToForgotPasswordPressed } = props;

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');

    const loading = useAppSelector((s) => s.userState.authIsBusy);
    const error = useAppSelector((s) => s.userState.authError);
    const isMobile = useAppSelector((s) => s.appState.isMobile);
    const { t } = useTranslation();
    const auth = getAuth();
    const dispatch = useAppDispatch();

    const onGooglePressed = () => {
        dispatch(GoogleAuthAction(auth));
    }
    const onLinkedInPressed = () => {
        // TODO: LinkedIn Login
    }

    const onEmailChanged = (newEmail: string) => {
        setEmailError('');
        setEmail(newEmail);
    }

    const onPasswordChanged = (newPassword: string) => {
        setPasswordError('');
        setPassword(newPassword);
    }

    const onLoginPressed = async () => {
        setEmailError('');
        setPasswordError('');

        if (!emailIsValid()) {
            return;
        }
        if (!passwordIsValid()) {
            return;
        }

        dispatch(LogInAction(auth, email, password));
    }

    const emailIsValid = (): boolean => {
        if (email === '') {
            setEmailError('Email is required');
            return false;
        } else if (!isValidEmail(email)) {
            setEmailError('Please enter a valid email');
            return false;
        }
        return true;
    }

    const passwordIsValid = (): boolean => {
        if (password === '') {
            setPasswordError('Password is required');
            return false;
        } else if (password.length < 6) {
            setPasswordError('Password must be at least 6 characters');
            return false;
        }
        return true;
    }

    const buttonEnabled = (): boolean => {
        return email.length > 0 && password.length > 0;
    }

    return (
        <AuthModalDiv isMobile={isMobile}>
            <Typography variant='h2' style={{ marginBottom: '21px' }}>
                {t(Strings.welcomeBack)}
            </Typography>
            <SocialButton
                title='Sign in with Google'
                icon={GoogleLogo}
                onClick={onGooglePressed}
            />
            {/* <span style={{ height: '20px' }} />
            <SocialButton
                title='Sign in with LinkedIn'
                icon={LinkedInLogo}
                onClick={onLinkedInPressed}
            /> */}
            <Seperator />
            <EmailInput value={email} onValueChanged={onEmailChanged} onEnterPressed={onLoginPressed} error={emailError} />
            <span style={{ minHeight: '5px' }} />
            <PasswordInput value={password} onValueChanged={onPasswordChanged} onEnterPressed={onLoginPressed} error={passwordError} />
            <span style={{ minHeight: '22px' }} />
            <PrimaryButton title={t(Strings.logInButton).toUpperCase()} disabled={!buttonEnabled()} onClick={loading ? undefined : onLoginPressed} />
            {error &&
                <>
                    <span style={{ minHeight: '20px' }} />
                    <Typography variant='subtitle2' style={{ color: AppColors.error }}>
                        {error}
                    </Typography>
                </>
            }
            {loading && <>
                <span style={{ minHeight: '20px' }} />
                <Bars
                    height="30"
                    width="30"
                    color={AppColors.pink.dark}
                    ariaLabel="bars-loading"
                    visible={true}
                />
            </>}
            <Typography variant='h5' style={{ marginTop: '40px', display: 'flex', flexDirection: 'row' }}>
                Need to create a free account? <ClickableOpacityDiv onClick={switchToSignUpPressed} style={{ color: AppColors.link, marginLeft: '3px' }}>Sign Up.</ClickableOpacityDiv>
            </Typography>
            <Typography variant='h5' style={{ marginTop: '20px', display: 'flex', flexDirection: 'row' }}>
                <ClickableOpacityDiv onClick={switchToForgotPasswordPressed} style={{ color: AppColors.link, marginLeft: '3px' }}>Forgot password?</ClickableOpacityDiv>
            </Typography>
        </AuthModalDiv>
    )
}

export default LoginScreen
import { Typography } from '@mui/material';
import { getAuth } from "firebase/auth";
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Bars } from 'react-loader-spinner';
import GoogleLogo from '../assets/images/google.png';
import Seperator from '../chat/components/Seperator';
import SocialButton from '../chat/components/SocialButton';
import { Strings } from '../i18n';
import { GoogleAuthAction } from '../redux/actions/SocialAuthActions';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { clearAuthError } from '../redux/reducers/userReducer';
import ClickableOpacityDiv from '../shared/components/ClickableOpacityDiv';
import PrimaryButton from '../shared/components/PrimaryButton';
import { AppColors } from '../theme/AppTheme';
import AuthModalDiv from './components/AuthModalDiv';
import EmailInput from './components/EmailInput';
import { isValidEmail } from './helpers/EmailHelper';
import { onPrivacyPolicyPressed, onToSPressed } from './helpers/PolicyHelpers';

interface SignUpScreenProps {
    switchToLoginPressed: () => void;
    switchToSignUpInfoPressed: (email: string) => void;
}

const SignUpScreen = (props: SignUpScreenProps) => {
    const { switchToLoginPressed, switchToSignUpInfoPressed } = props;

    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');

    const { t } = useTranslation();
    const auth = getAuth();
    const dispatch = useAppDispatch();
    const loading = useAppSelector((s) => s.userState.authIsBusy);
    const error = useAppSelector((s) => s.userState.authError);
    const isMobile = useAppSelector((s) => s.appState.isMobile);

    const onGooglePressed = () => {
        setEmailError('');
        clearAuthError();
        dispatch(GoogleAuthAction(auth));
    }
    const onLinkedInPressed = () => {
        // TODO: LinkedIn Login
    }

    const onEmailChanged = (newEmail: string) => {
        setEmailError('');
        clearAuthError();
        setEmail(newEmail);
    }

    const onSignUpPressed = async () => {
        setEmailError('');
        clearAuthError();

        if (!emailIsValid()) {
            return;
        }

        switchToSignUpInfoPressed(email);
    }

    const emailIsValid = (): boolean => {
        if (email === '') {
            setEmailError('Email is required');
            return false;
        } else if (!isValidEmail(email)) {
            setEmailError('Please enter a valid email');
            return false;
        }
        return true;
    }

    const onLoginPressed = () => {
        switchToLoginPressed();
    }

    const buttonEnabled = (): boolean => {
        return email.length > 0;
    }

    return (
        <AuthModalDiv isMobile={isMobile}>
            <Typography variant='h2' style={{ marginBottom: '21px' }}>
                {t(Strings.signUp)}
            </Typography>
            <Typography variant='h5' style={{
                textAlign: 'center',
                marginBottom: '40px',
                color: AppColors.grey.darkish
            }}>
                {t(Strings.signUpDescription)}
            </Typography>
            <SocialButton
                title='Continue with Google'
                icon={GoogleLogo}
                onClick={onGooglePressed}
            />
            {/* <span style={{ minHeight: '20px' }} /> */}
            {/* <SocialButton
                title='Continue with LinkedIn'
                icon={LinkedInLogo}
                onClick={onLinkedInPressed}
            /> */}
            <Seperator />
            <EmailInput onEnterPressed={onSignUpPressed} value={email} onValueChanged={onEmailChanged} error={emailError} />
            <span style={{ minHeight: '10px', maxHeight: '10px' }} />
            <PrimaryButton title={t(Strings.continue).toUpperCase()} disabled={!buttonEnabled()} onClick={onSignUpPressed} />
            {error &&
                <>
                    <span style={{ minHeight: '20px', maxHeight: '20px' }} />
                    <Typography variant='subtitle2' style={{ color: AppColors.error }}>
                        {error}
                    </Typography>
                </>
            }
            {loading && <>
                <span style={{ minHeight: '20px', maxHeight: '20px' }} />
                <Bars
                    height="30"
                    width="30"
                    color={AppColors.pink.dark}
                    ariaLabel="bars-loading"
                    visible={true}
                />
            </>}
            <Typography variant='h5' style={{ marginTop: '24px', display: 'flex', flexDirection: 'row', justifyContent: 'center', flexWrap: 'wrap', textAlign: 'center' }}>
                By proceeding, you agree to the
                <ClickableOpacityDiv onClick={onToSPressed} style={{ color: AppColors.link, marginLeft: '3px', marginRight: '3px' }}>
                    Terms of Service
                </ClickableOpacityDiv>
                and
                <ClickableOpacityDiv onClick={onPrivacyPolicyPressed} style={{ color: AppColors.link, marginLeft: '3px' }}>
                    Privacy Policy.
                </ClickableOpacityDiv>
            </Typography>
            <Typography variant='h5' style={{ marginTop: '40px', display: 'flex', flexDirection: 'row' }}>
                Already have an account? <ClickableOpacityDiv onClick={onLoginPressed} style={{ color: AppColors.link, marginLeft: '3px' }}>Log in.</ClickableOpacityDiv>
            </Typography>
        </AuthModalDiv>
    )
}

export default SignUpScreen;
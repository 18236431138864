const DislikeIcon = () => {
    return (
        <svg width="23px" height="17px" viewBox="0 0 23 17" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <title>Group 66</title>
            <g id="PathPilotApp" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                <g id="PPDesktopChatThreadJobBoard1920Copy6" transform="translate(-774, -836)" stroke="#6C7275" strokeWidth="2">
                    <g id="Group66" transform="translate(775, 837)">
                        <path d="M8.41019178,14.6565043 L12.9680248,10.3694695 C13.2729452,10.0834252 13.4457334,9.68848075 13.4457334,9.2782903 L13.4457334,1.54783372 C13.4457334,0.696234773 12.7052125,0 11.7998895,0 L4.39468133,0 C3.7369246,0 3.14450794,0.371712412 2.88096965,0.936541039 L0.199122671,6.82513357 C-0.492030096,8.35772127 0.700789228,10.0601932 2.47005319,10.0601932 L7.11863635,10.0601932 L6.33673348,13.6038031 C6.25469539,13.9907615 6.37811553,14.385706 6.67432386,14.6644903 C7.1592924,15.1131588 7.93320925,15.1131588 8.41019178,14.6565043 Z" id="Stroke17"></path>
                        <path d="M18.4803312,0 C17.2911418,0 16.3183008,0.740520822 16.3183008,1.64584383 L16.3183008,8.22776713 C16.3183008,9.13309014 17.2911418,9.87361096 18.4803312,9.87361096 C19.6695205,9.87361096 20.6423616,9.13309014 20.6423616,8.22776713 L20.6423616,1.64584383 C20.6423616,0.740520822 19.6695205,0 18.4803312,0 Z" id="Stroke19"></path>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default DislikeIcon;

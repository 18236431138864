import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../redux/hooks';
import { setSelectedProfileTab } from '../../redux/reducers/appReducer';
import { setFocusedResource } from '../../redux/reducers/resourceReducer';
import URLDataService from '../../services/urlDataService';
import { AppColors } from '../../theme/AppTheme';
import Resource, { ResourceData } from '../models/Resource';
import { hostnameFromUrl } from '../utils/StringUtils';
import ClickableOpacityDiv from './ClickableOpacityDiv';

const size = '80px';

const StyledCard = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    borderRadius: '8px',
    backgroundColor: AppColors.white,
    boxShadow: '0px 0px 3px 1px rgba(248, 248, 248, 1)',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    border: '1px solid rgba(245, 245, 245, 1)',
}));

interface MobileURLPreviewCardProps {
    url: string;
}

const MobileURLPreviewCard: React.FC<MobileURLPreviewCardProps> = ({ url }) => {
    const [resource, setResource] = useState<Resource>({ id: 0, title: '', description: '', imageUrl: '', url: '', body: [], reviews: [], createdAt: (new Date()).toISOString() });
    const dispatch = useAppDispatch();

    useEffect(() => {
        URLDataService.fetchMetadata(url).then((metadata) => {
            if (!!metadata) {
                const resource: Resource = {
                    id: 0,
                    title: metadata.title,
                    description: metadata.description,
                    // TODO: Default image
                    imageUrl: metadata.image,
                    url: url,
                    body: metadata.body?.length > 0
                        ? (JSON.parse(metadata.body) as ResourceData[]).sort((a, b) => a.order - b.order)
                        : [],
                    audience: metadata.audience,
                    price: metadata.price,
                    rating: metadata.rating,
                    reviews: metadata.reviews,
                    createdAt: (new Date()).toISOString(),
                }
                setResource(resource)
            }
        });
    }, [url]);

    const onClick = () => {
        dispatch(setFocusedResource(resource));
        dispatch(setSelectedProfileTab(undefined));
    };

    if ((!resource.title || resource.title === '') || (!resource.imageUrl || resource.imageUrl === '')) {
        return null;
    }

    return (
        <ClickableOpacityDiv onClick={onClick}
            style={{
                height: size,
                width: '100%',
            }}
        >
            <StyledCard className="preview-card" >
                {resource.imageUrl && <img src={resource.imageUrl} style={{ objectFit: 'cover' }} height={size} width={size} alt={resource.title} />}
                <div style={{ display: 'flex', flexDirection: 'column', overflow: 'hidden', }}>
                    <Typography variant='body1' style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        paddingLeft: '14px',
                        paddingRight: '14px',
                        maxWidth: '100%',
                    }}>
                        {resource.title}
                    </Typography>
                    <Typography variant='h6' style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        paddingLeft: '14px',
                        paddingRight: '14px',
                        maxWidth: '100%',
                        color: AppColors.grey.darkish,
                    }} >
                        {hostnameFromUrl(resource.url)}
                    </Typography>
                </div>
            </StyledCard>
        </ClickableOpacityDiv>
    );
};

export default MobileURLPreviewCard;

import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import SaveIconSelected from '../../assets/icons/save_icon_selected';
import SaveIconUnselected from '../../assets/icons/save_icon_unselected';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setSelectedProfileTab } from '../../redux/reducers/appReducer';
import { setFocusedResource } from '../../redux/reducers/resourceReducer';
import URLDataService from '../../services/urlDataService';
import { AppColors } from '../../theme/AppTheme';
import Resource, { ResourceData } from '../models/Resource';
import ClickableOpacityDiv from './ClickableOpacityDiv';

const StyledCard = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    borderRadius: '8px',
    backgroundColor: AppColors.white,
    boxShadow: '0px 0px 3px 1px rgba(248, 248, 248, 1)',
    minHeight: '232px',
    maxHeight: '232px',
    width: '313px',
    overflow: 'hidden',
    cursor: 'pointer',
    border: '1px solid rgba(245, 245, 245, 1)',
}));

const StyledTextColumn = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    maxWidth: '100%',
    overflow: 'hidden',
}));

const StyledContentRow = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-bottom',
    justifyContent: 'space-between',
    width: '100%',
    overflow: 'hidden',
}));

interface URLPreviewCardProps {
    url: string;
    onBookmarkPressed: (adding: boolean, resource: Resource) => void;
}

const URLPreviewCard: React.FC<URLPreviewCardProps> = (props: URLPreviewCardProps) => {
    const { url, onBookmarkPressed } = props;
    const savedResources = useAppSelector(store => store.resourceState.savedResources ?? {});
    const savedResourcesArray = Object.values(savedResources).flat();
    const isMobile = useAppSelector(store => store.appState.isMobile);
    const savedResourceToggle = useAppSelector(store => store.resourceState.resourceToggle);

    const [resource, setResource] = useState<Resource>({ id: 0, title: '', description: '', imageUrl: '', url: '', body: [], reviews: [], createdAt: (new Date()).toISOString(), });
    const [bookmarked, setBookmarked] = useState(false);

    const dispatch = useAppDispatch();

    useEffect(() => {
        const isSaved = savedResourcesArray.some(j => j.url === resource.url);
        if (isSaved !== bookmarked) {
            setBookmarked(isSaved);
        }
    }, [savedResourceToggle, savedResourcesArray, resource.url, bookmarked]);

    useEffect(() => {
        URLDataService.fetchMetadata(url).then((metadata) => {
            if (!!metadata) {
                const resource: Resource = {
                    id: 0,
                    title: metadata.title,
                    description: metadata.description,
                    // TODO: Default image
                    imageUrl: metadata.image,
                    url: url,
                    body: metadata.body?.length > 0
                        ? (JSON.parse(metadata.body) as ResourceData[]).sort((a, b) => a.order - b.order)
                        : [],
                    audience: metadata.audience,
                    price: metadata.price,
                    rating: metadata.rating,
                    reviews: metadata.reviews,
                    createdAt: (new Date()).toISOString(),
                }
                setResource(resource)
            }
        });
    }, [url]);

    const onClick = () => {
        dispatch(setFocusedResource(resource));
        dispatch(setSelectedProfileTab(undefined));
    };

    const urlObj = new URL(url);
    const hostname = urlObj.hostname.replace(/^www\./, '');

    if ((!resource.title || resource.title === '') || (!resource.imageUrl || resource.imageUrl === '')) {
        return null;
    }

    const onHeartClicked = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
        onBookmarkPressed(!bookmarked, resource);
    }

    return (
        <ClickableOpacityDiv onClick={onClick}
            gradientBorder={!isMobile}
            style={{
                marginRight: isMobile ? 0 : '32px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                borderRadius: '8px',
                minHeight: '238px',
                maxHeight: '238px',
                minWidth: '319px',
                maxWidth: '319px',
                overflow: 'hidden',
                cursor: 'pointer',
            }}
        >
            <StyledCard className="preview-card" >
                {resource.imageUrl && <img src={resource.imageUrl} style={{ objectFit: 'cover', borderBottom: `1.5px solid ${AppColors.separator}` }} height={'143px'} width={'100%'} alt={resource.title} />}
                <StyledContentRow>
                    <StyledTextColumn>
                        <Typography variant='body1' style={{
                            paddingLeft: '20px',
                            paddingRight: '20px',
                            paddingTop: '15px',
                            fontSize: '12px',
                            lineHeight: '18px',
                            maxHeight: '60px',
                            overflow: 'hidden'
                        }}>
                            {resource.title}
                        </Typography>
                        <Typography variant='caption' style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            paddingLeft: '20px',
                            paddingRight: '14px',
                            paddingBottom: '18px',
                            paddingTop: '3px',
                            lineHeight: '19px',
                            fontSize: '12px',
                            color: AppColors.grey.darkish,
                        }} >
                            {hostname}
                        </Typography>
                    </StyledTextColumn>
                    <ClickableOpacityDiv id={resource.url} style={{ alignItems: 'flex-start', marginTop: '16px', marginRight: '22px' }} onClick={onHeartClicked}>
                        {bookmarked ? <SaveIconSelected color={AppColors.grey.dark} /> : <SaveIconUnselected color={AppColors.grey.dark} />}
                    </ClickableOpacityDiv>
                </StyledContentRow>
            </StyledCard>
        </ClickableOpacityDiv>
    );
};

export default URLPreviewCard;

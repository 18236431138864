
import JobsService from '../../services/jobsService';
import Job from '../../shared/models/Job';
import { setSavedJobBuckets, setSavedJobs } from '../reducers/savedJobsReducer';
import { AppDispatch, RootState } from '../store';


export const FetchSavedJobsAction = () => {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const jobs = await JobsService.fetchSavedJobs();
        if (jobs) {
            const sortedJobs = jobs.sort((a, b) => new Date(b.updatedAt!).getTime() - new Date(a.updatedAt!).getTime());
            const today = new Date();
            let bucketKeys: string[] = ['Today', 'This Week'];
            const sortedJobsHistory = sortedJobs.reduce((buckets: { [key: string]: Job[] }, job: Job) => {
                const createdDate = new Date(job.updatedAt ?? job.createdAt!);
                const year = createdDate.getFullYear();
                const month = createdDate.toLocaleString('default', { month: 'long' });

                let bucketKey = '';
                if (createdDate.toDateString() === today.toDateString()) {
                    bucketKey = 'Today';
                } else if (createdDate >= new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay())) {
                    bucketKey = 'This Week';
                } else {
                    bucketKey = `${month} ${year}`;
                }

                if (!buckets[bucketKey]) {
                    if (!bucketKeys.includes(bucketKey)) {
                        bucketKeys.push(bucketKey);
                    }
                    buckets[bucketKey] = [];
                }
                buckets[bucketKey].push(job);

                return buckets;
            }, {});

            for (const bucketKey in sortedJobsHistory) {
                sortedJobsHistory[bucketKey].sort((a, b) => new Date(b.updatedAt!).getTime() - new Date(a.updatedAt!).getTime());
            }
            dispatch(setSavedJobBuckets(bucketKeys));
            dispatch(setSavedJobs(sortedJobsHistory));
        }
    }
};

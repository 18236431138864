import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../redux/hooks";
import ClickableOpacityDiv from "../../shared/components/ClickableOpacityDiv";
import { AppColors } from "../../theme/AppTheme";

const StyledRow = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    paddingBottom: '14px',
    borderBottom: `1px solid ${AppColors.grey.light}`,
}));

interface ProfileSectionProps {
    title: string;
    icon?: ReactNode;
    action?: string;
    onActionPressed?: () => void;
}
const ProfileSectionHeader = (props: ProfileSectionProps) => {
    const { title, icon, action, onActionPressed } = props;
    const isMobile = useAppSelector(store => store.appState.isMobile);
    const { t } = useTranslation();

    return (
        <StyledRow>
            <Typography variant={isMobile ? 'h4' : 'h2'} style={{ fontSize: isMobile ? '16px' : '21px' }}>
                {title}
            </Typography>
            {!!action && !!onActionPressed &&
                <ClickableOpacityDiv style={{ display: 'flex', alignItems: 'center' }} onClick={onActionPressed}>
                    {icon}
                    <Typography variant='subtitle1' style={{ marginLeft: '8px', color: AppColors.grey.dark }}>
                        {action}
                    </Typography>
                </ClickableOpacityDiv>
            }
        </StyledRow>
    )
}

export default ProfileSectionHeader;
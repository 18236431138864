import styled from '@emotion/styled';
import { Box, SxProps, Theme, Typography } from '@mui/material';
import { useState } from 'react';
import SmallXIcon from '../../assets/icons/small_x_icon';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { removeResourceFromList, toggleResources } from '../../redux/reducers/resourceReducer';
import ResourcesService from '../../services/resourcesService';
import ClickableOpacityDiv from '../../shared/components/ClickableOpacityDiv';
import Resource from '../../shared/models/Resource';
import ResourceList from '../../shared/models/ResourceList';
import { hostnameFromUrl } from '../../shared/utils/StringUtils';
import { AppColors } from '../../theme/AppTheme';

const StyledCard = styled(Box)((props: { isMobile: boolean }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: props.isMobile ? 'calc(100% - 80px)' : 'calc(100% - 48px)',
    marginLeft: '24px',
    marginRight: '24px',
    marginBottom: '10px',
    padding: '15px',
    border: `2px solid ${AppColors.grey.light}`,
    borderRadius: '8px',
    userSelect: 'text',
    webkitUserSelect: 'text',
}));

const UnselectedStyledCard = styled(StyledCard)(() => ({
    '&:hover': {
        backgroundColor: AppColors.grey.lightest,
        cursor: 'pointer',
    }
}));

const StyledTypography = styled(Typography)(() => ({
    wordBreak: 'break-word',
    whiteSpace: 'normal',
    textAlign: 'left'
}));

const StyledColumn = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    paddingRight: '10px',
}));

const textTheme = (selected: boolean): SxProps<Theme> => {
    return {
        backgroundColor: selected ? AppColors.black : AppColors.white,
        color: selected ? AppColors.white : AppColors.grey.darkish,
    }
}

interface SavedResourcesCardProps {
    resource: Resource;
    list?: ResourceList;
    selected: boolean;
    onClick: () => void;
    onDeleted?: () => void;
}

const SavedResourcesCard = (props: SavedResourcesCardProps) => {
    const { resource, list, selected, onClick, onDeleted } = props;
    const isMobile = useAppSelector(state => state.appState.isMobile);
    const Card = selected ? StyledCard : UnselectedStyledCard;
    const [isHovered, setIsHovered] = useState(false);
    const dispatch = useAppDispatch();

    const onDelete = async (event: React.MouseEvent) => {
        if (!onDeleted || !list) return;
        event.stopPropagation();
        const success = await ResourcesService.removeResourceFromList(list.id, resource.url);
        if (success) {
            dispatch(removeResourceFromList({ listId: list.id, resource: resource }));
            dispatch(removeResourceFromList({ listId: list.id, resource: resource }));
            dispatch(toggleResources());
            onDeleted();
        }
    }

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <div
            style={{ position: 'relative' }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <Card sx={textTheme(selected)} onClick={onClick} isMobile={isMobile}>
                <img src={resource.imageUrl} style={{ objectFit: 'cover', aspectRatio: '1', marginRight: '15px' }} height={'60px'} alt={resource.title} />
                <StyledColumn>
                    <StyledTypography variant='body1' style={{ maxHeight: '40px', overflow: 'hidden', lineHeight: '20px', textOverflow: 'ellipsis', color: selected ? AppColors.white : AppColors.black }}>
                        {resource.title}
                    </StyledTypography>
                    <StyledTypography variant='h6' sx={{ marginTop: '2px', lineHeight: '18px', color: selected ? AppColors.white : AppColors.grey.darkish }} >
                        {hostnameFromUrl(resource.url)}
                    </StyledTypography>
                </StyledColumn>
                {list && onDelete && <ClickableOpacityDiv style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '20px', height: '20px', position: 'absolute', top: '6px', right: '30px' }} onClick={onDelete}>
                    <SmallXIcon color={selected ? AppColors.white : (isHovered ? AppColors.grey.darkish : AppColors.grey.neutral)} />
                </ClickableOpacityDiv>}
            </Card>
        </div>
    )
}

export default SavedResourcesCard;

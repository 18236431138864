import styled from "@emotion/styled";
import { Switch, SwitchProps, Typography, styled as muiStyled } from "@mui/material";
import { CSSProperties } from "react";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { resetCoverLetterPrompt, resetJobMatchPrompt, resetMockInterviewPrompt, resetResumeOptimizePrompt, resetUpskillPrompt, setCoverLetterPrompt, setJobMatchPrompt, setMockInterviewPrompt, setResumeOptimizePrompt, setUpskillPrompt } from "../redux/reducers/promptReducer";
import ClickableOpacityDiv from "../shared/components/ClickableOpacityDiv";
import PPMultiLineTextInput from "../shared/components/PPMultiLIneTextInput";
import { AppColors } from "../theme/AppTheme";
import AdminSettingRow from "./components/AdminSettingRow";
import ProfileSectionHeader from "./components/ProfileSectionHeader";
import { resetJobSummaryMessage, setJobSummaryMessage } from "../redux/reducers/appReducer";

const IOSSwitch = muiStyled((props: SwitchProps) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    marginRight: '12px',
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: AppColors.pink.dark,
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: AppColors.grey.darkish,
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

const ScrollingWrapper = styled('div')({
    overflowY: 'auto',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
});

const StyledContainer = styled('div')((props: { style?: CSSProperties, isMobile: boolean }) => ({
    padding: props.isMobile ? '24px' : '50px',
    maxWidth: '1200px',
    width: props.isMobile ? 'calc(100% - 48px)' : '100%',
    ...props.style,
}));

const Divider = () => {
    return <div style={{ marginTop: '12px', width: '100%', minHeight: '1.5px', backgroundColor: AppColors.separator }} />
}

const SectionTitle = (props: { title: string, top?: boolean }) => {
    return <div style={{ marginTop: props.top ? '40px' : '100px' }}>
        <Typography variant="h4" style={{ textAlign: 'left', color: AppColors.link, fontWeight: 500, fontSize: '18px' }}>
            {props.title}
        </Typography>
        <Divider />
    </div>
}

const PromptPage = () => {
    const currentJobMatchPrompt = useAppSelector(store => store.promptState.jobMatchPrompt);
    const currentJobSummaryMessagePrompt = useAppSelector(store => store.appState.jobSummaryMessage);
    const currentResumeOptimizationPrompt = useAppSelector(store => store.promptState.resumeOptimizePrompt);
    const currentCoverLetterPrompt = useAppSelector(store => store.promptState.coverLetterPrompt);
    const currentUpskillPrompt = useAppSelector(store => store.promptState.upskillPrompt);
    const currentMockInterviewPrompt = useAppSelector(store => store.promptState.mockInterviewPrompt);

    const isMobile = useAppSelector(store => store.appState.isMobile);
    const dispatch = useAppDispatch();

    return (
        <ScrollingWrapper>
            <StyledContainer isMobile={isMobile}>
                <ProfileSectionHeader title='Prompt Settings' />

                <SectionTitle title='Main Prompts' />

                <AdminSettingRow title='Job Matching Prompt' value={
                    <>
                        <PPMultiLineTextInput id={'match-job-prompt-input'} value={currentJobMatchPrompt} onChange={(newMessage) => dispatch(setJobMatchPrompt(newMessage))} maxRows={12} style={{ border: '1px #AAA solid', borderRadius: '24px', padding: '10px 12px', width: isMobile ? 'calc(100vw - 60px)' : 'clamp(100px, 500px, 500px)' }} />
                        <ClickableOpacityDiv onClick={() => dispatch(resetJobMatchPrompt())}>Reset</ClickableOpacityDiv>
                    </>
                } />

                <AdminSettingRow title='Job Summary Prompt' value={
                    <>
                        <PPMultiLineTextInput id={'job-summary-prompt-input'} value={currentJobSummaryMessagePrompt} onChange={(newMessage) => dispatch(setJobSummaryMessage(newMessage))} maxRows={12} style={{ border: '1px #AAA solid', borderRadius: '24px', padding: '10px 12px', width: isMobile ? 'calc(100vw - 60px)' : 'clamp(100px, 500px, 500px)' }} />
                        <ClickableOpacityDiv onClick={() => dispatch(resetJobSummaryMessage())}>Reset</ClickableOpacityDiv>
                    </>
                } />

                <AdminSettingRow title='Resume Optimization Prompt' value={
                    <>
                        <PPMultiLineTextInput id={'resume-prompt-input'} value={currentResumeOptimizationPrompt} onChange={(newMessage) => dispatch(setResumeOptimizePrompt(newMessage))} maxRows={12} style={{ border: '1px #AAA solid', borderRadius: '24px', padding: '10px 12px', width: isMobile ? 'calc(100vw - 60px)' : 'clamp(100px, 500px, 500px)' }} />
                        <ClickableOpacityDiv onClick={() => dispatch(resetResumeOptimizePrompt())}>Reset</ClickableOpacityDiv>
                    </>
                } />

                <AdminSettingRow title='Cover Letter Prompt' value={
                    <>
                        <PPMultiLineTextInput id={'cover-letter-prompt-input'} value={currentCoverLetterPrompt} onChange={(newMessage) => dispatch(setCoverLetterPrompt(newMessage))} maxRows={12} style={{ border: '1px #AAA solid', borderRadius: '24px', padding: '10px 12px', width: isMobile ? 'calc(100vw - 60px)' : 'clamp(100px, 500px, 500px)' }} />
                        <ClickableOpacityDiv onClick={() => dispatch(resetCoverLetterPrompt())}>Reset</ClickableOpacityDiv>
                    </>
                } />

                <AdminSettingRow title='Upskill Prompt' value={
                    <>
                        <PPMultiLineTextInput id={'upskill-prompt-input'} value={currentUpskillPrompt} onChange={(newMessage) => dispatch(setUpskillPrompt(newMessage))} maxRows={12} style={{ border: '1px #AAA solid', borderRadius: '24px', padding: '10px 12px', width: isMobile ? 'calc(100vw - 60px)' : 'clamp(100px, 500px, 500px)' }} />
                        <ClickableOpacityDiv onClick={() => dispatch(resetUpskillPrompt())}>Reset</ClickableOpacityDiv>
                    </>
                } />

                <AdminSettingRow title='Mock Interview Prompt' value={
                    <>
                        <PPMultiLineTextInput id={'mock-interview-prompt-input'} value={currentMockInterviewPrompt} onChange={(newMessage) => dispatch(setMockInterviewPrompt(newMessage))} maxRows={12} style={{ border: '1px #AAA solid', borderRadius: '24px', padding: '10px 12px', width: isMobile ? 'calc(100vw - 60px)' : 'clamp(100px, 500px, 500px)' }} />
                        <ClickableOpacityDiv onClick={() => dispatch(resetMockInterviewPrompt())}>Reset</ClickableOpacityDiv>
                    </>
                } />

                <div style={{ minHeight: '20px', maxHeight: '20px', }} />
            </StyledContainer>
        </ScrollingWrapper>
    )
}

export default PromptPage;
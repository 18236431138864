import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import Resource from '../../shared/models/Resource';
import ResourceList from '../../shared/models/ResourceList';

interface ResourcesState {
    resourceSearchTerm?: string;
    clearSelectedResourceToggle: boolean;
    searchResourceToggle: boolean;
    selectedSavedResource?: Resource;
    focusedResource?: Resource;
    savedResourceBuckets: string[];
    savedResources: { [key: string]: Resource[] };
    resourceList: ResourceList[];
    resourceToggle: boolean;
    mobileResourceListToggle: boolean;
    listModalOpen: boolean;
    selectedList?: ResourceList;
}

const initialState: ResourcesState = {
    searchResourceToggle: false,
    clearSelectedResourceToggle: false,
    savedResourceBuckets: [],
    savedResources: {},
    resourceList: [],
    resourceToggle: false,
    mobileResourceListToggle: false,
    listModalOpen: false,
};

export const resourceSlice = createSlice({
    name: 'resources',
    initialState,
    reducers: {
        setResourceSearchText: (state, action: PayloadAction<string>) => {
            state.resourceSearchTerm = action.payload;
            state.searchResourceToggle = !state.searchResourceToggle;
        },
        setFocusedResource: (state, action: PayloadAction<Resource>) => {
            state.focusedResource = action.payload;
        },
        clearFocusedResource: (state) => {
            state.focusedResource = undefined;
        },
        setSelectedSavedResource: (state, action: PayloadAction<Resource>) => {
            state.focusedResource = undefined;
            state.selectedSavedResource = action.payload;
        },
        clearSelectedSavedResource: (state) => {
            state.selectedSavedResource = undefined;
        },
        addSavedResource: (state, action: PayloadAction<Resource>) => {
            if (state.savedResources['Today'] === undefined) {
                state.savedResources['Today'] = [];
            }
            state.savedResources['Today'].unshift(action.payload);
        },
        removeSavedResource: (state, action: PayloadAction<Resource>) => {
            Object.keys(state.savedResources).forEach((bucket) => {
                state.savedResources[bucket] = state.savedResources[bucket].filter((cs) =>
                    cs.url !== action.payload.url
                );
                if (state.savedResources[bucket]?.length === 0) {
                    delete state.savedResources[bucket];
                }
            });
            state.resourceToggle = !state.resourceToggle;
        },
        clearSavedResourceBuckets: (state) => {
            state.savedResourceBuckets = [];
        },
        setSavedResourceBuckets: (state, action: PayloadAction<string[]>) => {
            state.savedResourceBuckets = action.payload;
        },
        setSavedResources: (state, action: PayloadAction<{ [key: string]: Resource[] }>) => {
            state.savedResources = action.payload;
            state.resourceSearchTerm = undefined;
        },
        setResourceList: (state, action: PayloadAction<ResourceList[]>) => {
            state.resourceList = action.payload;
        },
        addResourceList: (state, action: PayloadAction<ResourceList>) => {
            if (state.resourceList.length > 1) {
                state.resourceList.splice(1, 0, action.payload);
            } else {
                state.resourceList = [...state.resourceList, action.payload];
            }
        },
        addResourceToList: (state, action: PayloadAction<{ listId: number; resource: Resource }>) => {
            const list = state.resourceList.find((list) => list.id === action.payload.listId);
            if (list) {
                list.resources.push(action.payload.resource);
            }
            state.resourceToggle = !state.resourceToggle;
        },
        removeResourceFromList: (state, action: PayloadAction<{ listId: number; resource: Resource }>) => {
            const list = state.resourceList.find((list) => list.id === action.payload.listId);
            if (list) {
                list.resources = list.resources.filter((resource) => resource.url !== action.payload.resource.url);
            }
            state.resourceToggle = !state.resourceToggle;
        },
        toggleResources: (state) => {
            state.resourceToggle = !state.resourceToggle;
        },
        toggleMobileResourceList: (state) => {
            state.mobileResourceListToggle = !state.mobileResourceListToggle;
        },
        setListModalOpen: (state, action: PayloadAction<boolean>) => {
            state.listModalOpen = action.payload;
        },
        setSelectedList: (state, action: PayloadAction<ResourceList | undefined>) => {
            state.selectedList = action.payload;
        }
    },
})

export const {
    setFocusedResource,
    clearFocusedResource,
    setSelectedSavedResource,
    clearSelectedSavedResource,
    setResourceSearchText,
    addSavedResource,
    removeSavedResource,
    setSavedResourceBuckets,
    clearSavedResourceBuckets,
    setSavedResources,
    setResourceList,
    addResourceList,
    addResourceToList,
    removeResourceFromList,
    toggleResources,
    toggleMobileResourceList,
    setListModalOpen,
    setSelectedList,
} = resourceSlice.actions

export default resourceSlice.reducer

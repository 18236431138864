import styled from '@emotion/styled';
import { Checkbox, Typography } from '@mui/material';
import { getAuth } from "firebase/auth";
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Bars } from 'react-loader-spinner';
import { Strings } from '../i18n';
import { CreateUserAction } from '../redux/actions/UserActions';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import CloseButton from '../shared/components/CloseButton';
import PPTextInput from '../shared/components/PPTextInput';
import PrimaryButton from '../shared/components/PrimaryButton';
import { AppColors } from '../theme/AppTheme';
import EmailInput from './components/EmailInput';
import PasswordInput from './components/PasswordInput';
import { isValidEmail } from './helpers/EmailHelper';

const StyledContainer = styled('div')((props: { isMobile: boolean }) => ({
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: props.isMobile ? '100%' : '70%',
    height: props.isMobile ? '100%' : '90%',
    maxWidth: props.isMobile ? '100%' : '650px',
    maxHeight: props.isMobile ? '100%' : '85vh',
    backgroundColor: AppColors.white,
}));

const StyledScrollingContainer = styled('div')((props: { isMobile: boolean }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: props.isMobile ? 'calc(100% - 48px)' : '100%',
    height: '100%',
    maxHeight: '100%',
    overflowY: 'auto',
    backgroundColor: AppColors.white,
    padding: props.isMobile ? '44px 24px' : '54px 100px 64px',
}));

const StyledCheckRow = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    marginTop: '25px',
    marginBottom: '36px',
    width: '100%',
    cursor: 'pointer',
}));

interface SignUpInfoScreenProps {
    initialEmail: string;
    switchToSignUpPressed: () => void;
}

const SignUpInfoScreen = (props: SignUpInfoScreenProps) => {
    const { switchToSignUpPressed, initialEmail } = props;

    const [name, setName] = useState('');
    const [email, setEmail] = useState(initialEmail);
    const [password, setPassword] = useState('');

    const [nameError, setNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');

    const [marketingChecked, setMarketingChecked] = useState(false);

    const { t } = useTranslation();
    const auth = getAuth();
    const dispatch = useAppDispatch();
    const loading = useAppSelector((s) => s.userState.authIsBusy);
    const error = useAppSelector((s) => s.userState.authError);
    const isMobile = useAppSelector((s) => s.appState.isMobile);


    const onEmailChanged = (newEmail: string) => {
        setEmailError('');
        setEmail(newEmail);
    }

    const onPasswordChanged = (newPassword: string) => {
        setPasswordError('');
        setPassword(newPassword);
    }

    const onNameChanged = (newName: string) => {
        setNameError('');
        setName(newName);
    }

    const onSignUpPressed = async () => {
        setEmailError('');
        setPasswordError('');
        setNameError('');

        if (!emailIsValid() || !passwordIsValid() || !nameIsValid()) {
            return;
        }

        dispatch(CreateUserAction(auth, email, password, name, marketingChecked));
    }

    const emailIsValid = (): boolean => {
        if (email === '') {
            setEmailError('Email is required');
            return false;
        } else if (!isValidEmail(email)) {
            setEmailError('Please enter a valid email');
            return false;
        }
        return true;
    }

    const passwordIsValid = (): boolean => {
        if (password === '') {
            setPasswordError('Password is required');
            return false;
        } else if (password.length < 6) {
            setPasswordError('Password must be at least 6 characters');
            return false;
        }
        return true;
    }

    const nameIsValid = (): boolean => {
        if (name === '') {
            setNameError('Name is required');
            return false;
        }
        return true;
    }

    const onBackPressed = () => {
        switchToSignUpPressed();
    }

    const buttonEnabled = (): boolean => {
        return email.length > 0 && password.length > 0 && name.length > 0;
    }

    return (
        <StyledContainer isMobile={isMobile}>
            <CloseButton onClick={onBackPressed} />
            <StyledScrollingContainer isMobile={isMobile}>
                <Typography variant='h2' style={{ marginBottom: '21px' }}>
                    {t(Strings.createAccountTitle)}
                </Typography>
                <Typography variant='h5' style={{
                    textAlign: 'center',
                    marginBottom: '40px',
                    color: AppColors.grey.darkish
                }}>
                    {t(Strings.createAccountSubtitle)}
                </Typography>
                <PPTextInput id='name-input' value={name} onValueChanged={onNameChanged} title={'Full Name'} placeholder='Enter Name' />
                <Typography variant='h4' style={{ textAlign: 'left', paddingBottom: '15px', width: '100%' }}>
                    Email
                </Typography>
                <EmailInput hideIcon onEnterPressed={onSignUpPressed} value={email} onValueChanged={onEmailChanged} error={emailError} />
                <span style={{ minHeight: '30px' }} />
                <Typography variant='h4' style={{ textAlign: 'left', paddingBottom: '15px', width: '100%' }}>
                    Password
                </Typography>
                <PasswordInput hideIcon onEnterPressed={onSignUpPressed} placeholder='Enter password' value={password} onValueChanged={onPasswordChanged} error={passwordError} />
                <StyledCheckRow onClick={() => setMarketingChecked(!marketingChecked)}>
                    <Checkbox
                        disableRipple
                        checked={marketingChecked}
                        color='secondary'
                        sx={{
                            width: '18px',
                            height: '30px',
                            marginRight: '14px',
                            color: AppColors.grey.neutral,
                            '&.Mui-checked': {
                                color: AppColors.pink.dark,
                            },
                        }} />
                    <Typography variant='h5' style={{ lineHeight: '24px', color: AppColors.grey.darkish, display: 'flex', flexDirection: 'row', justifyContent: 'center', flexWrap: 'wrap', textAlign: 'left' }}>
                        By checking this box, you agree to receive marketing emails from Path Pilot.
                    </Typography>
                </StyledCheckRow>
                <PrimaryButton title={t(Strings.createAccount).toUpperCase()} disabled={!buttonEnabled()} onClick={onSignUpPressed} />
                {error &&
                    <>
                        <span style={{ minHeight: '20px', maxHeight: '20px' }} />
                        <Typography variant='subtitle2' style={{ color: AppColors.error }}>
                            {error}
                        </Typography>
                    </>
                }
                {loading && <>
                    <span style={{ minHeight: '20px', maxHeight: '20px' }} />
                    <Bars
                        height="30"
                        width="30"
                        color={AppColors.pink.dark}
                        ariaLabel="bars-loading"
                        visible={true}
                    />
                </>}
            </StyledScrollingContainer>
        </StyledContainer>
    )
}

export default SignUpInfoScreen;
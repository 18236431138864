
const SearchIcon = () => {
    return (
        <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="PP---Desktop---Chat-History---1920" transform="translate(-287, -139)" fill="#6C7275">
                    <g id="Group-3-Copy" transform="translate(287, 139.0078)">
                        <path d="M10.7928998,10.7928998 C11.1833998,10.4023998 11.8165997,10.4023998 12.2070997,10.7928998 L17.7070994,16.2928996 C18.0975994,16.6833996 18.0975994,17.3165994 17.7070994,17.7070994 C17.3165994,18.0975994 16.6833996,18.0975994 16.2928996,17.7070994 L10.7928998,12.2070997 C10.4023998,11.8165997 10.4023998,11.1833998 10.7928998,10.7928998 Z" id="Vector" fillRule="nonzero"></path>
                        <path d="M7,2 C4.23857999,2 2,4.23857999 2,7 C2,9.76139998 4.23857999,12 7,12 C9.76139998,12 12,9.76139998 12,7 C12,4.23857999 9.76139998,2 7,2 Z M0,7 C0,3.13401008 3.13401008,0 7,0 C10.8659999,0 14,3.13401008 14,7 C14,10.8659999 10.8659999,14 7,14 C3.13401008,14 0,10.8659999 0,7 Z" id="Vector"></path>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default SearchIcon;

const InterviewIcon = (props: { color?: string }) => {
    return (
        <svg width="21px" height="19px" viewBox="0 0 21 19" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <title>Interviews</title>
            <g id="PathPilotApp" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                <g id="Group56Copy2" transform="translate(1, 1)" stroke={props.color ?? "#6C7275"} strokeWidth="2">
                    <path d="M19,7.93769746 C19,12.2462931 14.7468524,15.7384692 9.5,15.7384692 C8.63837341,15.7384692 7.80338922,15.6443328 7.00921893,15.4674704 C6.44576049,15.3425257 5.85849394,15.3933023 5.33528253,15.6386275 L2.57864431,16.9297229 C1.96246793,17.2184079 1.32985262,16.5634466 1.63425622,15.9518451 L2.0287905,15.1582465 C2.48908049,14.233998 2.32412435,13.1385923 1.66316606,12.3472758 C0.613908944,11.0932645 0,9.57453021 0,7.93769746 C0,3.62910181 4.25314756,0 9.5,0 C14.7468524,0 19,3.62910181 19,7.93769746 Z" id="Stroke1"></path>
                    <path d="M5,7.5 C5,7.77640604 4.77625257,8 4.49965683,8 C4.22374743,8 4,7.77640604 4,7.5 C4,7.22359396 4.22374743,7 4.49965683,7 C4.77625257,7 5,7.22359396 5,7.5 Z" id="Stroke3" fill={props.color ?? "#6C7275"}></path>
                    <path d="M15,7.5 C15,7.77640604 14.7762526,8 14.4996568,8 C14.2230611,8 14,7.77640604 14,7.5 C14,7.22359396 14.2230611,7 14.4996568,7 C14.7762526,7 15,7.22359396 15,7.5 Z" id="Stroke5" fill={props.color ?? "#6C7275"}></path>
                    <path d="M10,7.5 C10,7.77640604 9.77625257,8 9.49965683,8 C9.22374743,8 9,7.77640604 9,7.5 C9,7.22359396 9.22374743,7 9.49965683,7 C9.77625257,7 10,7.22359396 10,7.5 Z" id="Stroke7" fill={props.color ?? "#6C7275"}></path>
                </g>
            </g>
        </svg>
    );
}

export default InterviewIcon;

import styled from "@emotion/styled";
import { InterviewQuestionsSelectedAction } from "../../redux/actions/InterviewActions";
import { useAppDispatch } from "../../redux/hooks";
import SelectionPill from "../../shared/components/SelectionPill";

const StyledRow = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    maxWidth: '100%',
    gap: '15px',
}));

const numQuestionsOptions = ["3", "5", "7", "10"];

const NumQuestionsPills = () => {
    const dispatch = useAppDispatch();

    const onNumQuestionsSelected = (numQuestions: string) => {
        dispatch(InterviewQuestionsSelectedAction(parseInt(numQuestions)));
    }

    return (
        <StyledRow>
            {numQuestionsOptions.map((numQuestions, index) => (
                <SelectionPill key={index} onClick={() => onNumQuestionsSelected(numQuestions)} title={numQuestions} selected={false} />
            ))}
        </StyledRow>
    )
}

export default NumQuestionsPills
import styled from "@emotion/styled";
import { SetUpskillingTypeAction } from "../../redux/actions/UpskillingActions";
import { useAppDispatch } from "../../redux/hooks";
import SuggestionRow from "../../shared/components/SuggestionRow";
import UpskillingType, { textForUpskillingType } from "../../shared/enums/UpskillingType";

const StyledRow = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    maxWidth: '100%',
    gap: '15px',
}));

const UpskillingTypeSelection = () => {
    const dispatch = useAppDispatch();

    const onTypeOfUpskillingSelected = (type: UpskillingType) => {
        dispatch(SetUpskillingTypeAction(type));
    }

    return (
        <StyledRow>
            {Object.values(UpskillingType).map((type, index) => (
                <SuggestionRow key={index} title={textForUpskillingType(type)} onClick={() => onTypeOfUpskillingSelected(type)} />
            ))}
        </StyledRow>
    )
}

export default UpskillingTypeSelection
import { Typography } from '@mui/material';
import { GoogleAuthProvider, MultiFactorError, getMultiFactorResolver, reauthenticateWithPopup } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { Bars } from 'react-loader-spinner';
import AuthModalDiv from '../auth/components/AuthModalDiv';
import { auth } from '../firebaseConfigs';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { set2FALoginInfo } from '../redux/reducers/userReducer';
import CloseButton from '../shared/components/CloseButton';
import { AppColors } from '../theme/AppTheme';

interface ReauthenticateModalProps {
    onClose: () => void;
    onSuccess: () => void;
    enabling2FA?: boolean;
}

const GoogleReauthenticateModal = (props: ReauthenticateModalProps) => {
    const { onClose, onSuccess, enabling2FA } = props;
    const isMobile = useAppSelector(state => state.appState.isMobile);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const dispatch = useAppDispatch();
    let twoFactorPending = false;

    const reauthGoogle = async () => {
        const user = auth.currentUser;
        if (!user) return;
        try {
            await reauthenticateWithPopup(user, new GoogleAuthProvider());
            onSuccess();
            setLoading(false);
        } catch (error) {
            if (typeof error === 'object' && error !== null && 'code' in error) {
                const firebaseError = error as { code?: string };
                if (firebaseError.code === 'auth/multi-factor-auth-required') {
                    const resolver = getMultiFactorResolver(auth, error as MultiFactorError);
                    dispatch(set2FALoginInfo({ resolver, onSuccess, onFail: () => setError('Could not reauthenticate. Please try again.') }));
                    return;
                }
            }
            console.error('Error reauthenticating:', error);
            setError('Something went wrong, please try again');
            setLoading(false);
        } finally {
            twoFactorPending = false;
        }
    }

    useEffect(() => {
        if (twoFactorPending) return;
        twoFactorPending = true;
        setLoading(true);
        reauthGoogle();
    }, []);


    return (
        <AuthModalDiv isMobile={isMobile} fitHeight>
            <CloseButton onClick={onClose} />
            <Typography variant='h2' style={{ marginBottom: '21px', marginTop: '14px' }}>
                {enabling2FA ? 'Enable' : 'Disable'} Two Factor Authentication
            </Typography>
            <Typography variant='h5' style={{
                textAlign: 'center',
                marginBottom: '40px',
                color: AppColors.grey.darkish
            }}>
                Please reauthenticate with your Google account.
            </Typography>
            {error &&
                <>
                    <span style={{ minHeight: '20px' }} />
                    <Typography variant='subtitle2' style={{ color: AppColors.error }}>
                        {error}
                    </Typography>
                </>
            }
            {loading && <>
                <span style={{ minHeight: '20px', maxHeight: '20px' }} />
                <Bars
                    height="30"
                    width="30"
                    color={AppColors.pink.dark}
                    ariaLabel="bars-loading"
                    visible={true}
                />
            </>}
        </AuthModalDiv>
    )
}

export default GoogleReauthenticateModal
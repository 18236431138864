import InterviewType from "../enums/InterviewType";
import Job from "./Job";

export enum InterviewState {
    pending = 'pending',
    started = 'started',
    completed = 'completed',
}

interface Interview {
    id: number;
    sessionId?: string;
    totalQuestions?: number;
    questions?: string[];
    answers?: string[];
    jobTitle?: string;
    jobSummary?: string;
    jobCompany?: string;
    state: InterviewState;
    type?: InterviewType;
    userSpecifiedRole?: string;
    saved?: boolean;
    preamble?: string;
    error?: boolean;
    feedback?: string[];
    finalSummary?: string;
    rating?: number;
    courseUrls?: string[];
    createdAt: string;
    updatedAt: string;
}

export const newPreInterview = (type?: InterviewType, error?: boolean, preamble?: string): Interview => {
    return {
        id: -1,
        state: InterviewState.pending,
        type: type,
        error: error ?? false,
        preamble: preamble,
        createdAt: (new Date()).toISOString(),
        updatedAt: (new Date()).toISOString(),
    }
}

export const newStarterInterview = (job: Job): Interview => {
    return {
        id: -1,
        jobTitle: job.title,
        jobSummary: job.summary ?? '',
        jobCompany: job.company,
        state: InterviewState.started,
        type: InterviewType.job,
        createdAt: (new Date()).toISOString(),
        updatedAt: (new Date()).toISOString(),
    }
}

export const addNumQuestionsToInterview = (interview: Interview, totalQuestions: number): Interview => {
    return {
        ...interview,
        totalQuestions: totalQuestions,
    }
}

export const titleForInterviewType = (type: InterviewType): string => {
    switch (type) {
        case InterviewType.resume:
            return 'Interview: Based on Resume';
        case InterviewType.job:
            return 'Interview: Saved Job';
        case InterviewType.specified:
            return 'Interview: Specified Job / Role';
    }
}

export default Interview;

import styled from "@emotion/styled";
import { SetCoverLetterTypeAction } from "../../redux/actions/CoverLetterActions";
import { useAppDispatch } from "../../redux/hooks";
import SuggestionRow from "../../shared/components/SuggestionRow";
import CoverLetterType, { textForCoverLetterType } from "../../shared/enums/CoverLetterType";

const StyledRow = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    maxWidth: '100%',
    gap: '15px',
}));

const CoverLetTypeSelection = () => {
    const dispatch = useAppDispatch();

    const onTypeOfCoverLetterSelected = (type: CoverLetterType) => {
        dispatch(SetCoverLetterTypeAction(type));
    }

    return (
        <StyledRow>
            {Object.values(CoverLetterType).map((type, index) => (
                <SuggestionRow key={index} title={textForCoverLetterType(type)} onClick={() => onTypeOfCoverLetterSelected(type)} />
            ))}
        </StyledRow>
    )
}

export default CoverLetTypeSelection
import VisibilityOffOutlined from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlined from '@mui/icons-material/VisibilityOutlined';
import { IconButton, useTheme } from '@mui/material';
import Input from '@mui/material/Input';
import { useState } from 'react';
import KeyIcon from '../../assets/icons/key_icon';
import { AppColors } from '../../theme/AppTheme';
import InputErrorText from './InputErrorText';

interface PasswordInputProps {
    value: string;
    placeholder?: string;
    error?: string;
    hideIcon?: boolean;
    onValueChanged: (value: string) => void;
    onEnterPressed?: () => void;
}

const PasswordInput = (props: PasswordInputProps) => {
    const [visible, setVisible] = useState(false);
    const theme = useTheme();

    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '75px' }}>
            <Input
                disableUnderline
                id="outlined-password"
                type={visible ? "text" : "password"}
                error={!!props.error}
                sx={{
                    backgroundColor: AppColors.white,
                    borderRadius: '12px',
                    minHeight: '60px',
                    width: '100%',
                    paddingLeft: props.hideIcon ? '6px' : '18px',
                    paddingRight: '18px',
                    border: `1px solid ${props.error ? AppColors.error : AppColors.grey.border}`,
                }}
                inputProps={{
                    style: {
                        paddingLeft: '12px',
                        paddingRight: '12px',
                        ...theme.typography.h5,
                        color: AppColors.black,
                    }
                }}
                placeholder={props.placeholder ?? 'Enter password'}
                startAdornment={
                    props.hideIcon ? undefined : <KeyIcon />
                }
                endAdornment={
                    <IconButton onClick={() => setVisible(!visible)} sx={{ color: AppColors.grey.darkish, fontWeight: '100', }}>
                        {visible ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                    </IconButton>
                }
                value={props.value}
                onChange={(e) => props.onValueChanged(e.target.value)}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        if (props.onEnterPressed)
                            props.onEnterPressed();
                    }
                }}
            />
            {props.error && <InputErrorText variant='caption'>{props.error}</InputErrorText>}
        </div>
    )
}

export default PasswordInput;
import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { Bars } from "react-loader-spinner";
import { Strings } from "../i18n";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { setUser } from "../redux/reducers/userReducer";
import UserService from "../services/userService";
import ClickableOpacityDiv from "../shared/components/ClickableOpacityDiv";
import OutlinedButton from "../shared/components/OutlinedButton";
import PrimaryButton from "../shared/components/PrimaryButton";
import { AppColors } from "../theme/AppTheme";
import ResumeUploadBox from "./components/ResumeUploadBox";

const StyledContainer = styled('div')((props: { isMobile: boolean }) => ({
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: props.isMobile ? '100%' : '70%',
    height: props.isMobile ? '100%' : undefined,
    maxWidth: props.isMobile ? '100%' : '650px',
    maxHeight: props.isMobile ? '100%' : '85vh',
    backgroundColor: AppColors.white,
}));

const StyledScrollingContainer = styled('div')((props: { isMobile: boolean }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: props.isMobile ? 'calc(100% - 48px)' : '100%',
    height: '100%',
    maxHeight: '100%',
    overflowY: 'auto',
    backgroundColor: AppColors.white,
    padding: props.isMobile ? '44px 24px' : '54px 100px 64px',
}));

const StyledButtons = styled('div')((props: { isMobile: boolean }) => ({
    padding: '20px',
    borderTop: `1px solid ${AppColors.grey.border}`,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '22px',
    width: props.isMobile ? 'calc(100% - 40px)' : '100%',
}));

interface OnboardingResumeScreenProps {
    onDone?: () => void;
}

const OnboardingResumeScreen = (props: OnboardingResumeScreenProps) => {
    const { onDone } = props;
    const replacing = useAppSelector(store => !!store.userState.currentUser?.resume)

    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState('');
    const [file, setFile] = useState<File | undefined>(undefined);
    const isMobile = useAppSelector(store => store.appState.isMobile);
    const dispatch = useAppDispatch();

    const onSkipStepPressed = () => {
        onDone?.();
        // dispatch(setOnboarding(false));
    }

    const onSubmitPressed = async () => {
        setError('');
        setLoading(true);
        try {
            const updatedUser = await UserService.uploadResume(file!);
            dispatch(setUser(updatedUser));
            setSuccess(true);
            setTimeout(() => {
                if (replacing) {
                    onDone?.();
                }
            }, 2000);

        } catch (e) {
            console.error(`${e}`);
            setError(`Something went wrong. Please try again.`);
        } finally {
            setLoading(false);
        }
    }

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (!!files && files.length > 0) {
            setFile(files[0]);
        }
    }

    const removeFile = () => {
        setFile(undefined);
    }

    return (
        <StyledContainer isMobile={isMobile}>
            <StyledScrollingContainer isMobile={isMobile}>
                <Typography variant='h2' style={{ marginBottom: '21px' }}>
                    {replacing ? t(Strings.replaceResume) : t(Strings.uploadResume)}
                </Typography>
                <Typography variant='h5' style={{
                    textAlign: 'center',
                    marginBottom: '40px',
                    color: AppColors.grey.darkish
                }}>
                    {t(Strings.resumeInfo)}
                </Typography>
                <ResumeUploadBox file={file} onFileChanged={handleFileChange} onRemoveFile={removeFile} />
                {!replacing &&
                    <>
                        <span style={{ minHeight: '22px', maxHeight: '22px' }} />
                        <PrimaryButton title={t(Strings.addResumeButton).toUpperCase()} disabled={!file || loading || success} onClick={onSubmitPressed} />
                    </>
                }
                {error &&
                    <>
                        <span style={{ minHeight: '20px', maxHeight: '20px' }} />
                        <Typography variant='subtitle2' style={{ color: AppColors.error }}>
                            {error}
                        </Typography>
                    </>
                }
                {loading && <>
                    <span style={{ minHeight: '20px', maxHeight: '20px' }} />
                    <Bars
                        height="30"
                        width="30"
                        color={AppColors.pink.dark}
                        ariaLabel="bars-loading"
                        visible={true}
                    />
                </>}
                {success && <Typography variant='subtitle2' style={{ marginTop: '20px', color: AppColors.success }}>
                    Upload successful!
                </Typography>}
                {!replacing && <Typography variant='h5' style={{ marginTop: '40px', display: 'flex', flexDirection: 'row' }}>
                    Or you can <ClickableOpacityDiv onClick={onSkipStepPressed} style={{ color: AppColors.link, marginLeft: '3px' }}>skip this step.</ClickableOpacityDiv>
                </Typography>}
            </StyledScrollingContainer>
            {replacing &&
                <StyledButtons isMobile={isMobile}>
                    <PrimaryButton title={t(Strings.addResumeButton).toUpperCase()} disabled={!file || loading} onClick={onSubmitPressed} />
                    <OutlinedButton title={t(Strings.cancel).toUpperCase()} disabled={false} onClick={onSkipStepPressed} />
                </StyledButtons>
            }
        </StyledContainer>
    )
}

export default OnboardingResumeScreen
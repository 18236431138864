enum LLMModelType {
    openai = 'openai',
    fireworks = 'fireworks.ai',
    groq = 'groq',
    google = 'google'
}

enum LLMModel {
    // openai models
    gpt4omini = "gpt-4o-mini",
    gpt4_1106 = "gpt-4-1106-preview",
    gpt4turbo = "gpt-4-turbo-preview",
    gpt4 = "gpt-4",
    gpt4o = "gpt-4o",

    // Fireworks.ai models
    mixtral = "mixtral-8x7b-instruct",
    firefunctionv1 = "firefunction-v1",
    mixtralhf = "mixtral-8x7b-instruct-hf",
    mixtral4k = "mixtral-7b-instruct-4k",
    capybara = "yi-34b-200k-capybara",
    mixtral8x22b = "mixtral-8x22b-instruct",
    mixtral8x22bhf = "mixtral-8x22b-instruct-hf",
    llamav3_70b = "llama-v3-70b-instruct",
    llamav3_70bhf = "llama-v3-70b-instruct-hf",
    llamav3_8b = "llama-v3-8b-instruct",

    // groq models
    mixtral8x7b = "mixtral-8x7b-32768",

    // Google models
    gemini15flash = "gemini-1.5-flash-latest",
    gemini15pro = "gemini-1.5-pro-latest",
}


export const typeForModel = (model: LLMModel) => {
    switch (model) {
        case LLMModel.gpt4omini:
        case LLMModel.gpt4:
        case LLMModel.gpt4o:
        case LLMModel.gpt4turbo:
        case LLMModel.gpt4_1106:
            return LLMModelType.openai;
        case LLMModel.mixtral:
        case LLMModel.firefunctionv1:
        case LLMModel.mixtralhf:
        case LLMModel.mixtral4k:
        case LLMModel.capybara:
        case LLMModel.mixtral8x22b:
        case LLMModel.mixtral8x22bhf:
        case LLMModel.llamav3_70b:
        case LLMModel.llamav3_70bhf:
        case LLMModel.llamav3_8b:
            return LLMModelType.fireworks;
        case LLMModel.mixtral8x7b:
            return LLMModelType.groq;
        case LLMModel.gemini15flash:
        case LLMModel.gemini15pro:
            return LLMModelType.google;
    }
}

export default LLMModel;
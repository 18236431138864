import { Typography } from '@mui/material';
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ThreeDots } from 'react-loader-spinner';
import { useAppSelector } from '../redux/hooks';
import ClickableOpacityDiv from '../shared/components/ClickableOpacityDiv';
import PrimaryButton from '../shared/components/PrimaryButton';
import { AppColors } from '../theme/AppTheme';
import AuthModalDiv from './components/AuthModalDiv';
import EmailInput from './components/EmailInput';

interface ForgotPasswordScreenProps {
    switchToLoginPressed: () => void;
}

const ForgotPasswordScreen = (props: ForgotPasswordScreenProps) => {
    const { switchToLoginPressed } = props;
    const isMobile = useAppSelector((s) => s.appState.isMobile);

    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [sending, setSending] = useState(false);
    const [sent, setSent] = useState(false);

    const { t } = useTranslation();
    const auth = getAuth();


    const onEmailChanged = (newEmail: string) => {
        setEmailError('');
        setEmail(newEmail);
    }

    const onSendEmailPressed = async () => {
        setEmailError('');
        setSent(false);

        if (!emailIsValid()) {
            return;
        }

        setSending(true);
        await sendPasswordResetEmail(auth, email);
        setSending(false);
        setSent(true);
    }

    const emailIsValid = (): boolean => {
        if (email === '') {
            setEmailError('Email is required');
            return false;
        }
        return true;
    }

    const buttonEnabled = (): boolean => {
        return !sending && email.length > 0;
    }

    return (
        <AuthModalDiv isMobile={isMobile}>
            <Typography variant='h2' style={{ marginBottom: '21px' }}>
                Forgot Password
            </Typography>
            <Typography variant='h5' style={{
                textAlign: 'center',
                marginBottom: '40px',
                color: AppColors.grey.darkish
            }}>
                To change your passsword just enter your email below and we'll send you an email with a link to change your password.
            </Typography>
            <EmailInput value={email} onValueChanged={onEmailChanged} onEnterPressed={onSendEmailPressed} error={emailError} />
            <span style={{ minHeight: '22px' }} />
            <PrimaryButton title={'SEND EMAIL'} disabled={!buttonEnabled()} onClick={sending ? undefined : onSendEmailPressed} />
            <span style={{ minHeight: '20px' }} />
            {sent && <Typography variant="subtitle2" style={{ color: AppColors.success }}>
                Email Sent
            </Typography>}
            <ThreeDots
                height="30"
                width="30"
                color={AppColors.pink.dark}
                ariaLabel="dots-loading"
                visible={sending}
            />
            <Typography variant='h5' style={{ marginTop: '10px', display: 'flex', flexDirection: 'row' }}>
                <ClickableOpacityDiv onClick={switchToLoginPressed} style={{ color: AppColors.link, marginLeft: '3px' }}>Back to log in</ClickableOpacityDiv>
            </Typography>
        </AuthModalDiv>
    )
}

export default ForgotPasswordScreen

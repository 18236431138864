import { auth } from "../firebaseConfigs";
import Resource from "../shared/models/Resource";
import ResourceList from "../shared/models/ResourceList";


class ResourcesService {
    static saveResource = async (resource: Resource): Promise<Resource | undefined> => {
        try {
            const token = await auth.currentUser?.getIdToken();

            const res = await fetch(`${process.env.REACT_APP_API_URL}/resources/save`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(resource),
            });

            const data: Resource = await res.json();
            return data;
        } catch (error) {
            console.error('Error saving resource:', error);
        }
    };

    static unsaveResource = async (resource: Resource) => {
        try {
            const token = await auth.currentUser?.getIdToken();

            const res = await fetch(`${process.env.REACT_APP_API_URL}/resources/remove`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(resource),
            });

            if (res.status !== 200) {
                console.error(`Failed to unsave resource: ${res.status}`);
            }
        } catch (error) {
            console.error('Error unsaving resource:', error);
        }
    };

    static createList = async (name: string): Promise<ResourceList | undefined> => {
        try {
            const token = await auth.currentUser?.getIdToken();

            const res = await fetch(`${process.env.REACT_APP_API_URL}/resources/lists`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({ name }),
            });

            const data: ResourceList = await res.json();
            return data;
        } catch (error) {
            console.error('Error creating list:', error);
        }
    }

    static deleteList = async (listId: number) => {
        try {
            const token = await auth.currentUser?.getIdToken();

            const res = await fetch(`${process.env.REACT_APP_API_URL}/resources/lists/${listId}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (res.status !== 200) {
                console.error(`Failed to delete list: ${res.status}`);
            }
        } catch (error) {
            console.error('Error deleting list:', error);
        }
    }

    static updateList = async (list: ResourceList) => {
        try {
            const token = await auth.currentUser?.getIdToken();

            const res = await fetch(`${process.env.REACT_APP_API_URL}/resources/lists/${list.id}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({ name: list.name }),
            });

            if (res.status !== 200) {
                console.error(`Failed to update list: ${res.status}`);
            }
        } catch (error) {
            console.error('Error updating list:', error);
        }
    }

    static addResourceToList = async (listId: number, resourceUrl: string): Promise<boolean> => {
        try {
            const token = await auth.currentUser?.getIdToken();

            const res = await fetch(`${process.env.REACT_APP_API_URL}/resources/lists/${listId}/add`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({ url: resourceUrl }),
            });

            if (res.status !== 200) {
                console.error(`Failed to add resource to list: ${res.status}`);
                return false;
            }
            return true;
        } catch (error) {
            console.error('Error adding resource to list:', error);
            return false;
        }
    }

    static removeResourceFromList = async (listId: number, resourceUrl: string): Promise<boolean> => {
        try {
            const token = await auth.currentUser?.getIdToken();

            const res = await fetch(`${process.env.REACT_APP_API_URL}/resources/lists/${listId}/remove`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({ url: resourceUrl }),
            });

            if (res.status !== 200) {
                console.error(`Failed to remove resource from list: ${res.status}`);
                return false;
            }
            return true;
        } catch (error) {
            console.error('Error removing resource from list:', error);
            return false;
        }
    }

    static fetchAllLists = async (): Promise<ResourceList[] | undefined> => {
        try {
            const token = await auth.currentUser?.getIdToken();

            const res = await fetch(`${process.env.REACT_APP_API_URL}/resources/lists`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            const data: ResourceList[] = await res.json();
            if (Object.keys(data).length === 0 && data.constructor === Object) {
                return [];
            }
            return data;
        } catch (error) {
            console.error('Error fetching lists:', error);
        }
    }
}

export default ResourcesService;

import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import NavigationConstants from "../../navigation/NavigationConstants";
import { ClearAllSelectedAction } from "../../redux/actions/GlobalActions";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setDrawerIsOpen, setSelectedProfileTab } from "../../redux/reducers/appReducer";
import { setSelectedSavedResource } from "../../redux/reducers/resourceReducer";
import Resource from "../../shared/models/Resource";
import SavedResourcesCard from "./SavedResourcesCard";

const ScrollingList = styled('div')((props: { isMobile: boolean }) => ({
    display: 'flex',
    flexDirection: 'column',
    overflowY: props.isMobile ? undefined : 'auto',
    width: props.isMobile ? '100%' : NavigationConstants.sidebarWidth,
    paddingBottom: '50px',
}));

interface SavedResourcesSearchViewProps {
    searchText: string;
    resources?: Resource[];
}

const SavedResourcesSearchView = (props: SavedResourcesSearchViewProps) => {
    const { searchText, resources } = props;
    const [filteredResources, setFilteredResources] = useState<Resource[]>([]);
    const savedResources = useAppSelector((s) => s.userState.currentUser?.savedResources ?? []);
    const resourcesToggle = useAppSelector((s) => s.resourceState.resourceToggle);
    const isMobile = useAppSelector((s) => s.appState.isMobile);
    const selectedResource = useAppSelector((s) => s.resourceState.selectedSavedResource);

    const dispatch = useAppDispatch();


    useEffect(() => {
        const lowerSearch = searchText.trim().toLowerCase();
        const targetResources = resources ?? savedResources;
        const filtered = targetResources.filter(resource => {
            return resource.title?.toLowerCase().includes(lowerSearch)
                || resource.description?.toLowerCase().includes(lowerSearch)
                || resource.url?.toLowerCase().includes(lowerSearch);
        });
        setFilteredResources(filtered);
    }, [searchText, resourcesToggle, resources]);


    const cardSelected = (resource: Resource) => {
        dispatch(ClearAllSelectedAction());
        dispatch(setSelectedSavedResource(resource));
        dispatch(setSelectedProfileTab(undefined));
        if (isMobile) {
            dispatch(setDrawerIsOpen(false));
        }
    }


    return (
        <ScrollingList isMobile={isMobile}>
            {filteredResources.map((resource, index) => <SavedResourcesCard
                key={index}
                onClick={() => cardSelected(resource)}
                resource={resource}
                selected={!!resource.url && resource.url.trim().length > 0 && selectedResource?.url === resource.url}
            />)}
        </ScrollingList>
    );
}

export default SavedResourcesSearchView
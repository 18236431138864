import UserInfo from "../models/UserInfo";

interface UserInfoDto {
    desiredPositions?: string[];
    desiredIncome?: string;
    preferredWorkEnvironments?: string[];
    preferredLocations?: string[];
    skills?: string[];
}

export const userInfoDtoFromUserInfo = (info?: UserInfo): UserInfoDto | undefined => {
    if (!info) return;
    return {
        desiredPositions: info?.desiredPositions,
        desiredIncome: info?.desiredIncome?.toString(),
        preferredWorkEnvironments: info?.preferredWorkEnvironments,
        preferredLocations: info?.preferredLocations,
        skills: info?.skills
    }
}

export default UserInfoDto;
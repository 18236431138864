import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import SmallCloseIcon from '../../assets/icons/SmallCloseIcon';
import { AppColors } from '../../theme/AppTheme';

const PillContainer = styled('div')((props: { selected: boolean, grey: boolean, onClick?: () => void }) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '8px 13px',
    backgroundColor: (props.selected && !props.grey) ? AppColors.black : AppColors.grey.lightest,
    borderRadius: '20px',
    // height: '40px',
    cursor: props.onClick ? 'pointer' : 'default',
    '&:hover': {
        opacity: (props.selected || !props.onClick) ? 1.0 : 0.8,
    },
    '&:active': {
        opacity: (props.selected || !props.onClick) ? 1.0 : 0.6,
    },
}));

const PillText = styled('div')((props: { selected: boolean, grey: boolean }) => ({
    whiteSpace: 'nowrap',
    color: (props.selected && !props.grey) ? 'white' : 'black',
    userSelect: 'none',
}));

const CloseButton = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    border: 'none',
    alignItems: 'center',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    '&:hover': {
        opacity: 0.8,
    },
    '&:active': {
        opacity: 0.6,
    },
});


interface PillProps {
    value: string;
    onRemove: () => void;
    onClick?: () => void;
    selected?: boolean;
    grey?: boolean;
}

const Pill = (props: PillProps) => {
    const { value, selected, grey, onRemove, onClick } = props;

    return (
        <PillContainer grey={grey ?? false} selected={selected ?? false} onClick={onClick}>
            <PillText grey={grey ?? false} selected={selected ?? false}>
                <Typography variant="h3" style={{ fontWeight: '400', lineHeight: '16px', fontSize: '14px' }}>
                    {value}
                </Typography>
            </PillText>
            {selected && (
                <CloseButton onClick={(event) => { event.stopPropagation(); onRemove(); }}>
                    <SmallCloseIcon color={grey ? "#000" : "#fff"} />
                </CloseButton>
            )}
        </PillContainer>
    );
}

export default Pill;
import { Typography } from '@mui/material';
import { EmailAuthProvider, MultiFactorError, getMultiFactorResolver, reauthenticateWithCredential } from 'firebase/auth';
import { useState } from 'react';
import { Bars, ThreeDots } from 'react-loader-spinner';
import AuthModalDiv from '../auth/components/AuthModalDiv';
import PasswordInput from '../auth/components/PasswordInput';
import { auth } from '../firebaseConfigs';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { set2FALoginInfo } from '../redux/reducers/userReducer';
import CloseButton from '../shared/components/CloseButton';
import PrimaryButton from '../shared/components/PrimaryButton';
import { AppColors } from '../theme/AppTheme';

interface ReauthenticateModalProps {
    onClose: () => void;
    onSuccess: () => void;
    enabling2FA?: boolean;
}

const ReauthenticateModal = (props: ReauthenticateModalProps) => {
    const { onClose, onSuccess, enabling2FA } = props;
    const isMobile = useAppSelector(state => state.appState.isMobile);
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [twoFactorPending, setTwoFactorPending] = useState(false);
    const dispatch = useAppDispatch();

    const onPasswordChanged = (newPassword: string) => {
        setPassword(newPassword);
    }

    const on2FAFailed = () => {
        setTwoFactorPending(false);
    }

    const onContinuePressed = async () => {
        const user = auth.currentUser
        const userEmail = user?.email;
        if (!userEmail) return;
        setLoading(true);
        var credential = EmailAuthProvider.credential(
            userEmail, password);
        try {
            await reauthenticateWithCredential(user, credential)
            onSuccess();
        } catch (error) {
            if (typeof error === 'object' && error !== null && 'code' in error) {
                const firebaseError = error as { code?: string };
                if (firebaseError.code === 'auth/multi-factor-auth-required') {
                    const resolver = getMultiFactorResolver(auth, error as MultiFactorError);
                    setTwoFactorPending(true);
                    dispatch(set2FALoginInfo({ resolver, onSuccess, onFail: on2FAFailed }));
                    return;
                }
            }
            console.error('Error reauthenticating:', error);
            setError('Invalid password');
        } finally {
            setLoading(false);
        }
    }

    return (
        <AuthModalDiv isMobile={isMobile} fitHeight>
            {twoFactorPending ? <div
                style={{
                    marginTop: '40px',
                }}>
                <ThreeDots
                    height="30"
                    width="30"
                    color={AppColors.pink.dark}
                    ariaLabel="dots-loading"
                    visible={twoFactorPending}
                />
                <Typography variant='h5' style={{
                    textAlign: 'center',
                    marginBottom: '40px',
                    color: AppColors.grey.darkish
                }}>
                    Disabling
                </Typography>
            </div> : <>
                <CloseButton onClick={onClose} />
                <Typography variant='h2' style={{ marginBottom: '21px', marginTop: '14px' }}>
                    {enabling2FA ? 'Enable' : 'Disable'} Two Factor Authentication
                </Typography>
                <Typography variant='h5' style={{
                    textAlign: 'center',
                    marginBottom: '40px',
                    color: AppColors.grey.darkish
                }}>
                    Please enter your password to continue
                </Typography>
                <PasswordInput hideIcon onEnterPressed={onContinuePressed} placeholder='Enter password' value={password} onValueChanged={onPasswordChanged} error={error} />
                <PrimaryButton title={'Continue'} disabled={password.length === 0} onClick={onContinuePressed} style={{ marginTop: '12px' }} />
                {loading && <>
                    <span style={{ minHeight: '20px', maxHeight: '20px' }} />
                    <Bars
                        height="30"
                        width="30"
                        color={AppColors.pink.dark}
                        ariaLabel="bars-loading"
                        visible={true}
                    />
                </>}
            </>}
        </AuthModalDiv>
    )
}

export default ReauthenticateModal
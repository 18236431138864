import { Typography } from '@mui/material';
import { RecaptchaVerifier, multiFactor } from 'firebase/auth';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ThreeDots } from 'react-loader-spinner';
import { auth } from '../firebaseConfigs';
import { Strings } from '../i18n';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { setTwoFactorOpen } from '../redux/reducers/userReducer';
import TwoFactorService from '../services/twoFactorService';
import CloseButton from '../shared/components/CloseButton';
import PrimaryButton from '../shared/components/PrimaryButton';
import { AppColors } from '../theme/AppTheme';
import Enter2FACodeScreen from './Enter2FACodeScreen';
import AuthModalDiv from './components/AuthModalDiv';
import PhoneInput from './components/PhoneInput';

interface Enable2FAScreenProps {
    fromOnboarding: boolean;
}

const Enable2FAScreen = (props: Enable2FAScreenProps) => {
    const { fromOnboarding } = props;
    const { t } = useTranslation();
    const isMobile = useAppSelector((s) => s.appState.isMobile);
    const resolver = useAppSelector((s) => s.userState.twoFactorResolver);
    const onSuccess = useAppSelector((s) => s.userState.onTwoFactorSuccess);
    const onFail = useAppSelector((s) => s.userState.onTwoFactorFail);
    const [loading, setLoading] = useState(false);
    const [verificationId, setVerificationId] = useState('');
    const [error, setError] = useState('');
    const [phone, setPhone] = useState('');
    const dispatch = useAppDispatch();
    let recaptchaVerifier: RecaptchaVerifier;

    const onPhoneChanged = (value: string) => {
        setPhone(value);
    }

    const enable2FA = async () => {
        setError('');
        setLoading(true);
        try {
            recaptchaVerifier = new RecaptchaVerifier(auth, "send-text-button", {
                "size": "invisible",
                "callback": function (verificationId: any) {

                }
            });
            const fbUser = auth.currentUser;
            if (!fbUser) return false;
            const mfaUser = multiFactor(fbUser);
            const cleanPhone = phone.replace(/\D/g, '');
            const vid = await TwoFactorService.sendCode(mfaUser, `+1${cleanPhone}`, recaptchaVerifier);
            setVerificationId(vid ?? '');
        } catch (e) {
            console.error('Error performing captcha:', e);
            setError('Something went wrong. Please check your number and try again.');
            return;
        } finally {
            setLoading(false);
        }
    }

    const on2FASuccess = () => {
        if (onSuccess) {
            onSuccess();
        }
        onClose();
    }

    const onClose = () => {
        dispatch(setTwoFactorOpen(false));
        if (onFail) {
            onFail();
        }
    }

    const resetCodeSent = () => {
        setVerificationId('');
        recaptchaVerifier?.clear();
    }

    return (
        <AuthModalDiv isMobile={isMobile} fitHeight={!fromOnboarding}>
            {verificationId.length > 0 || resolver !== undefined
                ? <Enter2FACodeScreen onSuccess={on2FASuccess} onResend={enable2FA} verificationId={verificationId} resolver={resolver} backPressed={resetCodeSent} />
                : <>
                    <CloseButton onClick={onClose} />
                    <Typography variant='h2' style={{ marginBottom: '21px' }}>
                        {t(Strings.enable2FATitle)}
                    </Typography>
                    <Typography variant='h5' style={{
                        textAlign: 'center',
                        marginBottom: '40px',
                        color: AppColors.grey.darkish
                    }}>
                        {t(Strings.enable2FASubtitle)}
                    </Typography>
                    <PhoneInput value={phone} onValueChanged={onPhoneChanged} onEnterPressed={enable2FA} />
                    <div id={'send-text-button'} />
                    <PrimaryButton disabled={phone.length < 14 || loading} title={'Submit'} onClick={enable2FA} />
                    {fromOnboarding && <Typography variant='h5' style={{ marginTop: '24px', display: 'flex', flexDirection: 'row', justifyContent: 'center', flexWrap: 'wrap', textAlign: 'center' }}>
                        <span style={{ color: AppColors.blue, cursor: 'pointer' }} onClick={onClose}>Skip it for now.</span>
                    </Typography>
                    }
                    {error && error.length > 0 && !loading &&
                        <Typography variant='subtitle2' style={{ color: AppColors.error }}>
                            {error}
                        </Typography>
                    }
                    <ThreeDots
                        height="30"
                        width="30"
                        color={AppColors.pink.dark}
                        ariaLabel="dots-loading"
                        visible={loading}
                    />
                    <Typography variant='h6' style={{ color: AppColors.grey.darkish, marginTop: '60px', display: 'flex', flexDirection: 'row', justifyContent: 'center', flexWrap: 'wrap', textAlign: 'center' }}>
                        Note: Standard messaging rates apply for texts.
                    </Typography>
                </>
            }
        </AuthModalDiv>
    )
}

export default Enable2FAScreen;
import styled from "@emotion/styled";
import { StartTopLevelInterviewAction } from "../../redux/actions/TopLevelInterviewActions";
import { useAppDispatch } from "../../redux/hooks";
import SuggestionRow from "../../shared/components/SuggestionRow";
import TopLevelInterviewType, { textForTopLevelInterviewType } from "../../shared/enums/TopLevelInterviewType";

const StyledRow = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    maxWidth: '100%',
    gap: '15px',
}));

const TopLevelInterviewTypeSelection = () => {
    const dispatch = useAppDispatch();

    const onTypeOfTopLevelInterviewSelected = (type: TopLevelInterviewType) => {
        dispatch(StartTopLevelInterviewAction(type));
    }

    return (
        <StyledRow>
            {Object.values(TopLevelInterviewType).map((type, index) => (
                <SuggestionRow key={index} title={textForTopLevelInterviewType(type)} onClick={() => onTypeOfTopLevelInterviewSelected(type)} />
            ))}
        </StyledRow>
    )
}

export default TopLevelInterviewTypeSelection;
import styled from '@emotion/styled';
import { useAppSelector } from '../../redux/hooks';
import zIndicies from '../../shared/utils/zIndexConstants';
import { AppColors, sharpTransition } from '../../theme/AppTheme';
import NavigationConstants from '../NavigationConstants';

const SidebarScaffold = styled('div')((props: { isMobile: boolean }) => ({
    backgroundColor: AppColors.white,
    height: props.isMobile ? '100%' : '100vh',
    zIndex: zIndicies.sidebar,
    transition: `width 0.2s ${sharpTransition}`,
    borderWidth: 0,
}));

const Sidebar = () => {
    const sidebarIsOpen = useAppSelector((s) => s.appState.sidebarIsOpen);
    const sidebar = useAppSelector((s) => s.appState.sidebar);
    const isMobile = useAppSelector((s) => s.appState.isMobile);
    const fbUser = useAppSelector((s) => s.userState.fbUser);

    const showSideBar = sidebarIsOpen && (!isMobile || !!fbUser);

    return (
        <SidebarScaffold isMobile={isMobile} style={{ width: showSideBar ? NavigationConstants.sidebarWidth : 0 }}>
            {sidebar}
        </SidebarScaffold>
    )
}

export default Sidebar
import { auth } from "../firebaseConfigs";
import SavedChat from "../shared/models/SavedChat";

class SavedChatsService {
    static saveChat = async (savedChat: Partial<SavedChat>): Promise<SavedChat | undefined> => {
        try {
            const token = await auth.currentUser?.getIdToken();

            const res = await fetch(`${process.env.REACT_APP_API_URL}/savedchats/save`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(savedChat),
            });

            const data: SavedChat = await res.json();
            return data;
        } catch (error) {
            console.error('Error saving chat:', error);
        }
    };

    static unsaveChat = async (savedChat: SavedChat): Promise<boolean> => {
        try {
            const token = await auth.currentUser?.getIdToken();

            const res = await fetch(`${process.env.REACT_APP_API_URL}/savedchats/remove`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(savedChat),
            });

            if (res.status !== 200) {
                console.error(`Failed to unsave chat: ${res.status}`);
            }
            return true;
        } catch (error) {
            console.error('Error unsaving chat:', error);
            return false;
        }
    };

    static updateTitle = async (savedChat: SavedChat, newTitle: string): Promise<boolean> => {
        try {
            const token = await auth.currentUser?.getIdToken();

            const res = await fetch(`${process.env.REACT_APP_API_URL}/savedchats/update`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({ "id": savedChat.id, "title": newTitle }),
            });

            if (res.status !== 200) {
                console.error(`Failed to update saved chat: ${res.status}`);
            }
            return true;
        } catch (error) {
            console.error('Error updating saved chat:', error);
            return false;
        }
    };

}

export default SavedChatsService;

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import SavedResume from '../../mainTabs/savedResumes/model/SavedResume';

interface DocumentsState {
    focusedResume?: SavedResume;
}

const initialState: DocumentsState = {
};

export const documentsSlice = createSlice({
    name: 'focusedResume',
    initialState,
    reducers: {
        setFocusedResume: (state, action: PayloadAction<SavedResume>) => {
            state.focusedResume = action.payload;
        },
        clearFocusedResume: (state) => {
            state.focusedResume = undefined;
        }
    },
})

export const {
    setFocusedResume,
    clearFocusedResume,
} = documentsSlice.actions

export default documentsSlice.reducer

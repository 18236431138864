import { initialMessageForUpskillType, titleForUpskillingType, upskillStartMessage } from "../../chat/utils/UpskillingHelper";
import PromptType from "../../shared/enums/PromptType";
import UpskillingType from "../../shared/enums/UpskillingType";
import { newUpskillingChat } from "../../shared/models/Chat";
import Job from "../../shared/models/Job";
import Message, { MessageType } from "../../shared/models/Message";
import { focusText, setActiveChat } from "../reducers/appReducer";
import { AppDispatch, RootState } from "../store";
import { sendMessageAction } from "./ChatActions";

export const upskillPromptMessage = 'What would you like to upskill for?';

export const StartUpskillingFlowAction = () => {
    return async (dispatch: AppDispatch) => {
        try {
            const message: Message = {
                content: upskillPromptMessage,
                dt: (new Date()).toISOString(),
                type: MessageType.received,
            }
            const newChat = newUpskillingChat([message], `Upskilling`);
            dispatch(setActiveChat(newChat));
        } catch (error) {
            console.error('Error starting upskilling chat:', error);
        }
    };
}

export const SetUpskillingTypeAction = (type: UpskillingType) => {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        try {
            const chat = getState().appState.activeChat;

            if (!chat) return;
            if (type == UpskillingType.resumeAndPreferences) {
                dispatch(StartUpskillingAction(type));
                return;
            }

            // Recreate the chat with the new upskilling type
            const selectedMessage: Message = {
                content: initialMessageForUpskillType(type),
                dt: (new Date()).toISOString(),
                type: MessageType.sent
            }
            const message: Message = {
                content: '',
                dt: (new Date()).toISOString(),
                type: MessageType.received,
            }

            const newChat = newUpskillingChat([selectedMessage, message], titleForUpskillingType(type), type);
            dispatch(setActiveChat({ ...newChat, sessionTitle: titleForUpskillingType(type) }));
            if (type === UpskillingType.specificRole) {
                dispatch(focusText());
            }
        } catch (error) {
            console.error('Error setting upskilling type:', error);
        }
    };
}

export const StartUpskillingAction = (type: UpskillingType, job?: Job, role?: string) => {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        try {
            const state = getState();
            dispatch(sendMessageAction(upskillStartMessage(type, state, job, role), true, false, { promptType: PromptType.upskilling }));
        } catch (error) {
            console.error('Error starting upskilling chat:', error);
        }
    };
}
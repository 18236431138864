export const incomeRanges = [
    '$25k to $50k CAD',
    '$50k to $75k CAD',
    '$75k to $100k CAD',
    '$100k to $125k CAD',
    '$125k to $150k CAD',
    '$150k to $200k CAD',
    '$200k to $250k CAD',
    '$250k to $350k CAD',
    'Above $350k CAD',
]

export const workEnvironments = [
    'Remote',
    'Onsite',
    'Hybrid',
];

import { createElement, useEffect } from 'react';


const TermlyTermsComponent = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://app.termly.io/embed-policy.min.js';
        script.async = true;
        document.body.appendChild(script);
    }, []);

    return createElement('div', {
        name: 'termly-embed',
        'data-id': 'cf5b1f63-d3df-446c-b450-41d45be7aa9c',
        'data-type': 'iframe'
    });
};

export default TermlyTermsComponent;

import styled from "@emotion/styled";
import { SetJobMatchTypeAction } from "../../redux/actions/JobMatchActions";
import { useAppDispatch } from "../../redux/hooks";
import SuggestionRow from "../../shared/components/SuggestionRow";
import JobMatchType, { textForJobMatchType } from "../../shared/enums/JobMatchType";

const StyledRow = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    maxWidth: '100%',
    gap: '15px',
}));

const JobMatchTypeSelection = () => {
    const dispatch = useAppDispatch();

    const onTypeOfJobMatchSelected = (type: JobMatchType) => {
        dispatch(SetJobMatchTypeAction(type));
    }

    return (
        <StyledRow>
            {Object.values(JobMatchType).map((type, index) => (
                <SuggestionRow key={index} title={textForJobMatchType(type)} onClick={() => onTypeOfJobMatchSelected(type)} />
            ))}
        </StyledRow>
    )
}

export default JobMatchTypeSelection
import { ThemeProvider } from '@mui/material';
import { PostHogProvider } from 'posthog-js/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import './firebaseConfigs';
import './i18n';
import './index.css';
import store from './redux/store';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import appTheme from './theme/AppTheme';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const phOptions = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
}

root.render(
  <React.StrictMode>
    <Provider store={store} >
      <ThemeProvider theme={appTheme}>
        <PostHogProvider
          apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
          options={phOptions}
        >
          <App />
        </PostHogProvider>
      </ThemeProvider>

    </Provider>
  </React.StrictMode>
);

serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

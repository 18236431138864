import styled from "@emotion/styled";
import { useAppSelector } from "../redux/hooks";
import TermlyTermsComponent from "./components/TermlyTermsComponent";

const ScrollingWrapper = styled('div')((props: { isMobile: boolean }) => ({
    overflowY: 'auto',
    width: props.isMobile ? 'calc(100% - 48px)' : '100%',
    height: props.isMobile ? 'calc(100% - 48px)' : '100vh',
    flexGrow: 1,
    padding: props.isMobile ? '24px' : '48px',
    maxWidth: '1200px',
    margin: 'auto',
}));

const TermsView = () => {
    const isMobile = useAppSelector((s) => s.appState.isMobile);
    return (
        <ScrollingWrapper isMobile={isMobile}>
            <TermlyTermsComponent />
        </ScrollingWrapper>
    )
}

export default TermsView
export enum ResourceType {
    html = "html",
    column = "column",
    doubleColumn = "doubleColumn",
}

export interface Review {
    content: string;
    rating: number;
    created: string;
    userName: string;
    image: string;
}

interface Resource {
    id: number;
    title: string;
    description: string;
    imageUrl: string;
    url: string;
    body: ResourceData[];
    audience?: string;
    rating?: string;
    price?: string;
    reviews: Review[];
    createdAt: string;
}

export interface ResourceData {
    title: string;
    data: any;
    order: number;
    type: ResourceType;
}

export default Resource;
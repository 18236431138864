import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import StopIcon from '../../assets/icons/stop_icon';
import NavigationConstants from '../../navigation/NavigationConstants';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setUserStoppedStreaming } from '../../redux/reducers/appReducer';
import ClickableOpacityDiv from '../../shared/components/ClickableOpacityDiv';
import PPMultiLineTextInput from '../../shared/components/PPMultiLIneTextInput';
import { chatIsInterview } from '../../shared/models/Chat';
import { InterviewState } from '../../shared/models/Interview';
import zIndicies from '../../shared/utils/zIndexConstants';
import { AppColors, BoxShadow, sharpTransition } from '../../theme/AppTheme';
import SendButton from './SendButton';

interface ChatBarProps {
    onAttachPressed: () => void;
    onSubmit: (text: string) => void;
}

const Container = styled(Box)(() => ({
    position: 'fixed',
    bottom: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    zIndex: zIndicies.chatBar,
    transition: `left 0.2s ${sharpTransition}`,
}));

const ChatRow = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderRadius: '20px',
    boxShadow: BoxShadow,
    marginLeft: '33px',
    marginRight: '33px',
    width: 'calc(100% - 46px)',
    marginBottom: '20px',
    paddingLeft: '0px',
    paddingRight: '20px',
    minHeight: '62px',
    maxWidth: '1160px',
    backgroundColor: AppColors.white,
}));

const ChatBar = (props: ChatBarProps) => {
    const { t } = useTranslation();
    const isNavOpen = useAppSelector(store => store.appState.drawerIsOpen);
    const isSidebarOpen = useAppSelector(store => store.appState.sidebarIsOpen);
    const isMobile = useAppSelector(store => store.appState.isMobile);
    const activeChat = useAppSelector(store => store.appState.activeChat);
    const dispatch = useAppDispatch();

    const [inputValue, setInputValue] = useState(''); // State to store input value

    const handleInputChange = (newValue: string) => {
        setInputValue(newValue);
    }

    const { onAttachPressed, onSubmit } = props;

    const onMessageSubmit = () => {
        if (!!(activeChat?.pending) || !!(activeChat?.streaming))
            return;
        onSubmit(inputValue);
        setInputValue('');
    }

    const onStopMessage = () => {
        dispatch(setUserStoppedStreaming(true));
    }

    let placeholder = t('messagePlaceholder');
    let isDisabled = !!(activeChat?.pending) || !!(activeChat?.streaming);
    if (activeChat && chatIsInterview(activeChat)) {
        const interview = activeChat.interview!;
        isDisabled = isDisabled || interview.state === InterviewState.completed;
        if (interview.state === InterviewState.completed) {
            placeholder = 'Your interview has concluded. You can save your interview results and start a new interview at any time.'
        }
    }

    return (
        <Container sx={{ left: isMobile ? 0 : (isNavOpen ? NavigationConstants.drawerWidthOpen : NavigationConstants.drawerWidthClosed) + (isSidebarOpen ? NavigationConstants.sidebarWidth : 0) }}>
            <ChatRow>
                {/* <IconButton sx={{ margin: 0 }} onClick={onAttachPressed}>
                    <AttachmentIcon />
                </IconButton> */}
                <PPMultiLineTextInput
                    autofocus={!isMobile}
                    disabled={isDisabled}
                    id='message-input'
                    style={{ paddingLeft: '20px', paddingRight: '34px', marginTop: '15px', marginBottom: '15px', width: 'auto' }}
                    placeholder={placeholder}
                    value={inputValue}
                    onChange={handleInputChange}
                    onEnterPressed={onMessageSubmit}
                />
                {!!(activeChat?.streaming)
                    ? <ClickableOpacityDiv onClick={onStopMessage}>
                        <StopIcon />
                    </ClickableOpacityDiv>
                    : <div onClick={onMessageSubmit}>
                        <SendButton disabled={inputValue.trim() === '' || !!(activeChat?.pending) || !!(activeChat?.streaming)} />
                    </div>}
            </ChatRow>
        </Container >
    )
}

export default ChatBar
import { Typography } from '@mui/material';
import { ReactNode, useState } from 'react';
import ChatHistoryIcon from '../../assets/icons/chat_history_icon';
import SendImage from '../../assets/images/send.png';
import { AppColors } from '../../theme/AppTheme';
import ClickableOpacityDiv from './ClickableOpacityDiv';

interface SuggestionRowProps {
    title: ReactNode;
    onClick: () => void;
}
const SuggestionRow = (props: SuggestionRowProps) => {
    const { title, onClick } = props;
    const [icon, setIcon] = useState(<ChatHistoryIcon color={AppColors.grey.dark} />);

    return (
        <ClickableOpacityDiv
            onMouseEnter={() => setIcon(<img src={SendImage} alt='send' style={{ width: '22px', height: '22px' }} />)}
            onMouseLeave={() => setIcon(<ChatHistoryIcon color={AppColors.grey.dark} />)}
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                paddingTop: '9px',
                paddingBottom: '9px',
                gap: '18px',

            }}
            onClick={onClick}
        >
            <div style={{
                minWidth: '22px',
                height: '22px',
            }}>
                {icon}
            </div>
            <Typography variant='h5'>
                {title}
            </Typography>
        </ClickableOpacityDiv>
    );
}

export default SuggestionRow;
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import Interview from '../../shared/models/Interview';

interface InterviewsState {
    interviewBuckets: string[];
    interviews: { [key: string]: Interview[] };
    interviewsArray: Interview[];
    interviewToggle: boolean;
    focusedInterview?: Interview;
    selectedInterview?: Interview;
}

const initialState: InterviewsState = {
    interviewBuckets: [],
    interviews: {},
    interviewsArray: [],
    interviewToggle: false,
};

export const interviewSlice = createSlice({
    name: 'interviews',
    initialState,
    reducers: {
        addSavedInterview: (state, action: PayloadAction<Interview>) => {
            if (state.interviews['Today'] === undefined) {
                state.interviews['Today'] = [];
            }
            state.interviews['Today'].unshift(action.payload);
            state.interviewsArray.unshift(action.payload);
            state.interviewToggle = !state.interviewToggle;
        },
        removeSavedInterview: (state, action: PayloadAction<Interview>) => {
            Object.keys(state.interviews).forEach((bucket) => {
                state.interviews[bucket] = state.interviews[bucket].filter((cs) =>
                    cs.id !== action.payload.id
                );
                if (state.interviews[bucket]?.length === 0) {
                    delete state.interviews[bucket];
                }
            });
            state.interviewsArray = state.interviewsArray.filter((cs) => cs.id !== action.payload.id);
            state.interviewToggle = !state.interviewToggle;
        },
        clearSavedInterviewBuckets: (state) => {
            state.interviewBuckets = [];
        },
        setInterviewBuckets: (state, action: PayloadAction<string[]>) => {
            state.interviewBuckets = action.payload;
        },
        setSavedInterviews: (state, action: PayloadAction<{ [key: string]: Interview[] }>) => {
            state.interviews = action.payload;
            state.interviewsArray = Object.values(action.payload).flat();
        },
        toggleInterviews: (state) => {
            state.interviewToggle = !state.interviewToggle;
        },
        setFocusedInterview: (state, action: PayloadAction<Interview>) => {
            state.focusedInterview = action.payload;
        },
        clearFocusedInterview: (state) => {
            state.focusedInterview = undefined;
        },
        setSelectedInterview: (state, action: PayloadAction<Interview>) => {
            state.selectedInterview = action.payload;
        },
        clearSelectedInterview: (state) => {
            state.selectedInterview = undefined;
        },
    },
})

export const {
    clearSavedInterviewBuckets,
    setInterviewBuckets,
    toggleInterviews,
    setSavedInterviews,
    removeSavedInterview,
    addSavedInterview,
    setFocusedInterview,
    clearFocusedInterview,
    setSelectedInterview,
    clearSelectedInterview,
} = interviewSlice.actions

export default interviewSlice.reducer

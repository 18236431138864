import { ReactNode } from "react";
import Message from "../../shared/models/Message";
import MessageCell from "../components/MessageCell";
import TopLevelInterviewTypeSelection from "../components/TopLevelInterviewTypeSelection";


export const messageCellForTopLevelInterviewChat = (message: Message): ReactNode => {
    return (<>
        <MessageCell
            key={0}
            message={message}
            onBookmarkPressed={() => { }}
            onBookmarkJobPressed={() => { }}
            isNewest={true}
            accessory={<><br /><TopLevelInterviewTypeSelection /></>}
        />
    </>);
}


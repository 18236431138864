import { ReactNode } from "react";

enum CoverLetterType {
    general = 'general',
    savedJobs = 'savedJobs',
    specificRole = 'specificRole',
}

export const textForCoverLetterType = (type: CoverLetterType): ReactNode => {
    switch (type) {
        case CoverLetterType.general:
            return <>Create a <b>general cover letter</b> based on my skills and experience</>
        case CoverLetterType.savedJobs:
            return <>Create a cover letter for one of my <b>saved jobs</b></>
        case CoverLetterType.specificRole:
            return <>Create a cover letter for a <b>specific role or position</b> that I'm interested in</>
    }
}

export default CoverLetterType;

const PDFIcon = () => {
    return (
        <svg width="72px" height="79px" viewBox="0 0 72 79" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="PP---Desktop---Landing---1024-Copy-7" transform="translate(-684, -327)" fill="#999DA0" fillRule="nonzero">
                    <g id="Group-15" transform="translate(395, 67)">
                        <g id="noun-pdf-3267707-999DA0" transform="translate(289, 260)">
                            <path d="M14.6826874,32.4805379 L14.6826874,1.51982605 C14.6826874,0.681768977 15.364875,0 16.2030063,0 L54.5527636,0 C54.9728434,0 55.3515138,0.170494911 55.6280372,0.444887833 L71.5549679,16.3666556 C71.8510567,16.6626484 72,17.0519796 72,17.4415807 L72,77.4801739 C72,78.318231 71.3178124,79 70.4796811,79 L16.2029931,79 C15.3646643,79 14.6826742,78.3180335 14.6826742,77.4801739 L14.6826742,63.8928527 L1.52031888,63.8928527 C0.681990052,63.8928527 0,63.2108862 0,62.3730266 L0,33.9982442 C0,33.1601871 0.682187616,32.4784181 1.52031888,32.4784181 L14.6826742,32.4784181 L14.6826874,32.4805379 Z M56.0749264,5.19134555 L56.1055383,16.7648282 L67.6524807,16.7648282 L56.0752452,5.19134555 L56.0749264,5.19134555 Z M68.9593754,19.805007 L54.5958325,19.805007 C53.7610598,19.7978061 53.0826918,19.119683 53.0826918,18.2851809 L53.0430763,3.04017877 L17.7213126,3.04017877 L17.7213126,32.4808013 L48.5155738,32.4808013 C49.3539026,32.4808013 50.0358926,33.1627678 50.0358926,34.0006273 L50.0358926,62.3754098 C50.0358926,63.2134668 49.353705,63.8952358 48.5155738,63.8952358 L17.7213126,63.8952358 L17.7213126,75.9624677 L68.9568361,75.9624677 L68.9568361,19.8067186 L68.9593754,19.805007 Z M46.9962137,35.5200584 L3.04091435,35.5200584 L3.04091435,60.8553204 L46.9962137,60.8553204 L46.9962137,35.5200584 Z" id="Shape"></path>
                            <polygon id="Path" points="34.8452611 43.5897768 34.8452611 46.5416272 41.0416552 46.5416272 41.0416552 49.1317422 34.8452611 49.1317422 34.8452611 55 32 55 32 41 42 41 42 43.590115"></polygon>
                            <path d="M19,55 L19,41 L24.0916142,41 C24.8798017,41 25.7919932,41.0203436 26.5631363,41.1992087 C27.3626408,41.3799256 28.0979697,41.7136152 28.7260631,42.2351234 C29.518046,42.8951031 30.0784444,43.7154751 30.4471439,44.6650186 C30.8477036,45.7046534 31,46.8238282 31,47.929814 C31,48.8571728 30.8983747,49.8027257 30.6255868,50.6933581 C30.4056299,51.4122624 30.0969863,52.1258911 29.6457693,52.7377951 C29.2751307,53.239256 28.8314285,53.6945079 28.3028418,54.0392492 C27.7723575,54.3840379 27.1761104,54.6181197 26.5574709,54.7655259 C25.8240051,54.9406899 25.0565884,54.9996077 24.3042841,54.9996077 L19.0002691,54.9996077 L19,55 Z M21.8456731,52.4097396 L24.1083137,52.4097396 C24.6951346,52.4097396 25.4210165,52.3801488 25.9836231,52.1996956 C26.3353955,52.0871424 26.685277,51.9193777 26.9467411,51.6596618 C27.34541,51.2689077 27.6049971,50.7489415 27.7799206,50.2308488 C28.0244092,49.4934799 28.0977281,48.6749476 28.0977281,47.9024078 C28.0977281,46.9898613 27.9923351,45.8561447 27.4995834,45.0595942 C27.1666842,44.5248495 26.7283713,44.0492459 26.1208417,43.8188772 C25.5717603,43.6088333 24.6479758,43.5903416 24.0630802,43.5903416 L21.8454315,43.5903416 L21.8454315,52.4094149 L21.8456731,52.4097396 Z" id="Shape"></path>
                            <path d="M10.8022786,49.7293879 L10.8022786,55 L8,55 L8,41 L13.4434387,41 C14.1158075,41 14.8455293,41.0166446 15.5104572,41.1307812 C16.1549581,41.2414812 16.7886001,41.445979 17.3311432,41.8203314 C17.8683994,42.1873115 18.2665951,42.6922286 18.5516613,43.2710585 C18.8627605,43.8959013 19,44.5706865 19,45.2676767 C19,46.4642273 18.618555,47.5334866 17.8034016,48.4145823 C16.7846384,49.5152249 15.0141023,49.7290227 13.5934603,49.7290227 L10.8023261,49.7290227 L10.8022786,49.7293879 Z M10.8022786,47.1373013 L13.619439,47.1373013 C14.2196069,47.1373013 15.1531561,47.0746852 15.6291045,46.6561853 C16.0216939,46.3077038 16.1459271,45.8302807 16.1459271,45.3195604 C16.1459271,44.9306527 16.0625749,44.5600068 15.8329659,44.2411025 C15.6328216,43.9644843 15.3790549,43.7747853 15.047488,43.6862766 C14.6641878,43.582976 13.9825161,43.5903686 13.5843748,43.5903686 L10.8024824,43.5903686 L10.8024824,47.1373013 L10.8022786,47.1373013 Z" id="Shape"></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default PDFIcon;

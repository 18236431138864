import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import CaretLeftIcon from '../../assets/icons/caret_left_icon';
import NavigationConstants from '../../navigation/NavigationConstants';
import { ClearAllSelectedAction } from '../../redux/actions/GlobalActions';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setDrawerIsOpen, setSelectedProfileTab } from '../../redux/reducers/appReducer';
import { removeSavedResource, setSelectedSavedResource, toggleResources } from '../../redux/reducers/resourceReducer';
import ResourcesService from '../../services/resourcesService';
import ClickableOpacityDiv from '../../shared/components/ClickableOpacityDiv';
import Resource from '../../shared/models/Resource';
import ResourceList from '../../shared/models/ResourceList';
import { AppColors, sharpTransition } from '../../theme/AppTheme';
import ResourceSearchTextInput from './ResourceSearchTextInput';
import SavedResourceHeader from './SavedResourceHeader';
import SavedResourcesCard from './SavedResourcesCard';
import SavedResourcesSearchView from './SavedResourcesSearchView';

const Container = styled('div')((props: { right: number, isMobile: boolean }) => ({
    position: 'absolute',
    top: '0',
    bottom: '0',
    right: `${props.right}px`,
    width: `${props.isMobile ? '93vw' : `${NavigationConstants.sidebarWidth}px`}`,
    transition: `right 0.2s ${sharpTransition}`,
    height: '100%',
    maxHeight: '100%',
    backgroundColor: AppColors.white,
    borderRight: `2px solid ${AppColors.grey.light}`,
    overflow: 'hidden',
}));

const TopBarRow = styled('div')((props: { isMobile: boolean }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    zIndex: 20,
    position: props.isMobile ? 'absolute' : undefined,
    left: props.isMobile ? '0px' : undefined,
    right: props.isMobile ? '12px' : undefined,
    top: props.isMobile ? 0 : undefined,
    height: props.isMobile ? '60px' : undefined,
    paddingLeft: props.isMobile ? '18px' : undefined,
    paddingTop: props.isMobile ? '6px' : undefined,
    backgroundColor: AppColors.white,
}));

interface ResourceSidebarListViewProps {
    list: ResourceList;
    onClosed: () => void;
}

const ResourceSidebarListView = (props: ResourceSidebarListViewProps) => {
    const { list: originalList, onClosed } = props;
    const selectedResource = useAppSelector((s) => s.resourceState.selectedSavedResource);
    const savedResourceLists = useAppSelector(s => s.resourceState.resourceList);
    const isMobile = useAppSelector(s => s.appState.isMobile);
    const [currentList, setCurrentList] = useState(originalList);
    const [searchText, setSearchText] = useState('');

    const [titleOpacity, setTitleOpacity] = useState(1);
    const [right, setRight] = useState(isMobile ? -window.outerWidth - 80 : -NavigationConstants.sidebarWidth);
    const dispatch = useAppDispatch();

    useEffect(() => {
        setTimeout(() => {
            setRight(0);
        }, 100);
    }, []);

    const closeView = () => {
        setRight(isMobile ? -window.outerWidth - 80 : -NavigationConstants.sidebarWidth);
        setTimeout(() => {
            onClosed();
        }, 220);
    }

    const onDeleted = async (fromList: ResourceList, deletedResource: Resource) => {
        // Check if resource exixts in any of the lists.resource arrays
        const resourceExists = savedResourceLists.some(list => {
            if (list.id === fromList.id) {
                return false;
            }
            return list.resources.find(resource => resource.url === deletedResource.url)
        }
        );
        if (!resourceExists) {
            dispatch(removeSavedResource(deletedResource));
            await ResourcesService.unsaveResource(deletedResource);
            dispatch(toggleResources());
        }
        // Remove from local list
        setCurrentList({ ...currentList, resources: currentList.resources.filter(resource => resource.url !== deletedResource.url) });
    }

    const cardSelected = (resource: Resource) => {
        dispatch(ClearAllSelectedAction());
        dispatch(setSelectedSavedResource(resource));
        dispatch(setSelectedProfileTab(undefined));
        if (isMobile) {
            dispatch(setDrawerIsOpen(false));
        }
    }

    const savedResourceBucketCards = (list: ResourceList) => {
        return (
            <div style={{ paddingBottom: '60px' }}>
                {currentList.resources.map((resource, index) => <SavedResourcesCard
                    key={index}
                    onClick={() => cardSelected(resource)}
                    resource={resource}
                    list={currentList}
                    selected={!!resource.url && resource.url.trim().length > 0 && selectedResource?.url === resource.url}
                    onDeleted={() => onDeleted(currentList, resource)}
                />)}
            </div>
        );
    }

    return (
        <Container isMobile={isMobile} right={right}>
            <SavedResourceHeader isMobile={isMobile}>
                <TopBarRow isMobile={isMobile}>
                    <ClickableOpacityDiv style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '21px',
                        width: '21px',
                        zIndex: 10,
                    }} onClick={() => {
                        closeView();
                    }}>

                        <CaretLeftIcon />
                    </ClickableOpacityDiv>
                    <Typography variant='h4' style={{ marginLeft: '10px', fontSize: isMobile ? '18px' : '14px', opacity: titleOpacity, transition: `opacity 0.2s ${sharpTransition}` }}>
                        {currentList.name}
                    </Typography>
                </TopBarRow>
            </SavedResourceHeader>
            <div style={{ height: 'calc(100% - 60px)', maxHeight: 'calc(100% - 60px)', overflowY: 'auto' }}>
                <ResourceSearchTextInput searchText={searchText} onSearchTextChanged={(newText) => setSearchText(newText)} marginTop={isMobile ? '0px' : undefined} />
                <div style={{ minHeight: '40px' }} />

                {!searchText && savedResourceBucketCards(currentList)}
                {searchText && <SavedResourcesSearchView searchText={searchText} resources={currentList.resources} />}
            </div>
        </Container>
    )
}

export default ResourceSidebarListView
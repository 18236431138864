import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { ReactNode, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Bars } from 'react-loader-spinner';
import { useAppSelector } from '../../redux/hooks';
import skillsService from '../../services/skillsService';
import ClickableOpacityDiv from '../../shared/components/ClickableOpacityDiv';
import { AppColors, BoxShadow } from '../../theme/AppTheme';
import SkillsArray from './SkillsArray';

const Container = styled('div')((props: { isMobile: boolean }) => ({
    position: 'absolute',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: props.isMobile ? '95%' : '80%',
    height: '60%',
    backgroundColor: 'white',
    padding: '15px',
    boxShadow: BoxShadow,
    zIndex: 100,
    borderRadius: '12px',
}));

const TopBar = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    backgroundColor: 'white',
    boxSizing: 'border-box',
    marginBottom: '110px'
});

interface TitlesSearchProps {
    searchText: string;
    selectedTitles: string[];
    onDone: () => void;
    onSelectTitle: (skill: string) => void;
    onRemoveTitle: (skill: string) => void;
    anchorElement: HTMLElement | null;
    scrollContainer: HTMLElement | null;
}

const TitlesSearch = (props: TitlesSearchProps) => {
    const { searchText, selectedTitles, scrollContainer, anchorElement, onRemoveTitle, onSelectTitle, onDone } = props;

    const [filteredTitles, setFilteredTitles] = useState<string[]>([]);
    const [fetchingTitles, setFetchingTitles] = useState(false);
    const [searchTextTimeout, setSearchTextTimeout] = useState<NodeJS.Timeout>();
    const isMobile = useAppSelector(store => store.appState.isMobile);
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (searchTextTimeout) {
            clearTimeout(searchTextTimeout);
        }
        setSearchTextTimeout(setTimeout(() => {
            fetchTitles();
        }, 400));
    }, [searchText]);

    useLayoutEffect(() => {
        if (anchorElement && containerRef.current && scrollContainer) {
            const anchorRect = anchorElement.getBoundingClientRect();
            const container = containerRef.current;
            const containerRect = container.getBoundingClientRect();
            const scrollContainerRect = scrollContainer.getBoundingClientRect();
            const topPosition = anchorRect.top + scrollContainer.scrollTop - scrollContainerRect.top - anchorRect.height;
            const adjustedLeft = anchorRect.left - scrollContainerRect.left;

            const widthDifference = containerRect.width - anchorRect.width;
            container.style.top = `${topPosition}px`;
            container.style.left = `${adjustedLeft - (widthDifference / 2.0)}px`;
        }
    }, [anchorElement, scrollContainer, containerRef]);

    const fetchTitles = async () => {
        // Add a cancel token to the fetchSkillsForSearch method if the search text changes while its fetching
        setFetchingTitles(true);
        const titles = await skillsService.fetchTitlesForSearch(searchText);
        setFilteredTitles(titles.map(title => title.name));
        setFetchingTitles(false);
    }

    const onTitleSelected = (title: string) => {
        if (selectedTitles.includes(title)) {
            onRemoveTitle(title);
        } else {
            onSelectTitle(title);
        }
    }

    const EmptyView = (): ReactNode => {
        let message = 'No results found';

        if (searchText.length === 0) {
            message = 'Search for positions';
        }
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
            }}>
                <Typography variant='body1' style={{ color: AppColors.grey.darkish }}>{message}</Typography>
            </div>
        )
    }

    return (
        <Container ref={containerRef} isMobile={isMobile}>
            <TopBar>
                <Typography variant='body1'>Select Desired Positions</Typography>
                <ClickableOpacityDiv onClick={onDone}>
                    <Typography variant='subtitle1'
                        style={{
                            color: AppColors.pink.dark,
                            fontWeight: 400,
                        }}>
                        Done
                    </Typography>
                </ClickableOpacityDiv>
            </TopBar>
            {fetchingTitles ?
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                }}>
                    <Bars
                        height="30"
                        width="30"
                        color={AppColors.pink.dark}
                        ariaLabel="bars-loading"
                        visible={true}
                    />
                </div>
                : (filteredTitles.length === 0) ? EmptyView()
                    : <SkillsArray
                        title=''
                        emptyStateText=''
                        skills={filteredTitles}
                        allSelected={false}
                        selectedSkills={selectedTitles}
                        onSelected={onTitleSelected}
                        onRemove={onRemoveTitle}
                    />}
        </Container>
    )
}

export default TitlesSearch
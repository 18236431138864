enum DocumentContentType {
    resume = 'resume',
    coverLetter = 'coverLetter',
    interview = 'interview',
}


export const nameForDocumentContentType = (type: DocumentContentType): string => {
    switch (type) {
        case DocumentContentType.resume:
            return 'Resume';
        case DocumentContentType.coverLetter:
            return 'Cover-Letter';
        case DocumentContentType.interview:
            return 'Interview';
    }
    return '';
}

export default DocumentContentType;
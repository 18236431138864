import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import preambleService from '../../services/preambleService';
import suggestedPromptsService from '../../services/suggestedPromptsService';
import LLMModel from '../../shared/models/LLMModels';

interface PromptState {
    interviewPreamble: string;
    interviewPrompt: string;
    interviewModel: string;

    resumePrompt: string;
    resumeNameModel: string;
    resumeNameToggle: boolean;

    jobMatchPrompt: string;
    resumeOptimizePrompt: string;
    coverLetterPrompt: string;
    upskillPrompt: string;
    mockInterviewPrompt: string;

}

const initialState: PromptState = {
    interviewModel: localStorage.getItem('interviewModel') ?? LLMModel.gpt4o,
    interviewPreamble: localStorage.getItem('interviewPreamble') ?? preambleService.getInterviewPreamble(),
    interviewPrompt: localStorage.getItem('interviewPrompt') ?? preambleService.getInterviewMessage(),

    resumePrompt: localStorage.getItem('resumePrompt') ?? suggestedPromptsService.getJobPrompts()[1].prompt,
    resumeNameModel: localStorage.getItem('resumeNamingModel') ?? LLMModel.gpt4omini,
    resumeNameToggle: false,

    jobMatchPrompt: localStorage.getItem('jobMatchPrompt') ?? suggestedPromptsService.getSuggestedPrompts()[0].prompt,
    resumeOptimizePrompt: localStorage.getItem('resumeOptimizationPrompt') ?? suggestedPromptsService.getSuggestedPrompts()[1].prompt,
    coverLetterPrompt: localStorage.getItem('coverLetterPrompt') ?? suggestedPromptsService.getSuggestedPrompts()[2].prompt,
    upskillPrompt: localStorage.getItem('upskillPrompt') ?? suggestedPromptsService.getSuggestedPrompts()[3].prompt,
    mockInterviewPrompt: localStorage.getItem('mockInterviewPrompt') ?? suggestedPromptsService.getSuggestedPrompts()[5].prompt,
};

export const promptsSlice = createSlice({
    name: 'prompts',
    initialState,
    reducers: {
        setInterviewModel: (state, action: PayloadAction<string>) => {
            state.interviewModel = action.payload;
            localStorage.setItem('interviewModel', action.payload);
        },
        setInterviewPreamble: (state, action: PayloadAction<string>) => {
            state.interviewPreamble = action.payload;
            localStorage.setItem('interviewPreamble', action.payload);
        },
        setInterviewPrompt: (state, action: PayloadAction<string>) => {
            state.interviewPrompt = action.payload;
            localStorage.setItem('interviewPrompt', action.payload);
        },
        resetInterviewPreamble: (state) => {
            state.interviewPreamble = preambleService.getInterviewPreamble();
            localStorage.setItem('interviewPreamble', preambleService.getInterviewPreamble());
        },
        resetInterviewPrompt: (state) => {
            state.interviewPrompt = preambleService.getInterviewMessage();
            localStorage.setItem('interviewPrompt', preambleService.getInterviewMessage());
        },
        setResumePrompt: (state, action: PayloadAction<string>) => {
            state.resumePrompt = action.payload;
            localStorage.setItem('resumePrompt', action.payload);
        },
        resetResumePrompt: (state) => {
            state.resumePrompt = suggestedPromptsService.getJobPrompts()[1].prompt;
            localStorage.setItem('resumePrompt', suggestedPromptsService.getJobPrompts()[1].prompt);
        },
        setResumeModel: (state, action: PayloadAction<string>) => {
            state.resumeNameModel = action.payload;
            localStorage.setItem('resumeNamingModel', action.payload);
        },
        toggleResumeName: (state) => {
            state.resumeNameToggle = !state.resumeNameToggle;
        },
        setJobMatchPrompt: (state, action: PayloadAction<string>) => {
            state.jobMatchPrompt = action.payload;
            localStorage.setItem('jobMatchPrompt', action.payload);
        },
        resetJobMatchPrompt: (state) => {
            state.jobMatchPrompt = suggestedPromptsService.getSuggestedPrompts()[0].prompt;
            localStorage.setItem('jobMatchPrompt', suggestedPromptsService.getSuggestedPrompts()[0].prompt);
        },
        setResumeOptimizePrompt: (state, action: PayloadAction<string>) => {
            state.resumeOptimizePrompt = action.payload;
            localStorage.setItem('resumeOptimizationPrompt', action.payload);
        },
        setCoverLetterPrompt: (state, action: PayloadAction<string>) => {
            state.coverLetterPrompt = action.payload;
            localStorage.setItem('coverLetterPrompt', action.payload);
        },
        setUpskillPrompt: (state, action: PayloadAction<string>) => {
            state.upskillPrompt = action.payload;
            localStorage.setItem('upskillPrompt', action.payload);
        },
        setMockInterviewPrompt: (state, action: PayloadAction<string>) => {
            state.mockInterviewPrompt = action.payload;
            localStorage.setItem('mockInterviewPrompt', action.payload);
        },
        resetResumeOptimizePrompt: (state) => {
            state.resumeOptimizePrompt = suggestedPromptsService.getSuggestedPrompts()[1].prompt;
            localStorage.setItem('resumeOptimizationPrompt', suggestedPromptsService.getSuggestedPrompts()[1].prompt);
        },
        resetCoverLetterPrompt: (state) => {
            state.coverLetterPrompt = suggestedPromptsService.getSuggestedPrompts()[2].prompt;
            localStorage.setItem('coverLetterPrompt', suggestedPromptsService.getSuggestedPrompts()[2].prompt);
        },
        resetUpskillPrompt: (state) => {
            state.upskillPrompt = suggestedPromptsService.getSuggestedPrompts()[3].prompt;
            localStorage.setItem('upskillPrompt', suggestedPromptsService.getSuggestedPrompts()[3].prompt);
        },
        resetMockInterviewPrompt: (state) => {
            state.mockInterviewPrompt = suggestedPromptsService.getSuggestedPrompts()[5].prompt;
            localStorage.setItem('mockInterviewPrompt', suggestedPromptsService.getSuggestedPrompts()[5].prompt);
        },
    },
})

export const {
    setInterviewModel,
    setInterviewPreamble,
    setInterviewPrompt,
    resetInterviewPreamble,
    resetInterviewPrompt,
    setResumePrompt,
    resetResumePrompt,
    setJobMatchPrompt,
    resetJobMatchPrompt,
    setResumeModel,
    toggleResumeName,
    setResumeOptimizePrompt,
    setCoverLetterPrompt,
    setUpskillPrompt,
    setMockInterviewPrompt,
    resetResumeOptimizePrompt,
    resetCoverLetterPrompt,
    resetUpskillPrompt,
    resetMockInterviewPrompt,
} = promptsSlice.actions

export default promptsSlice.reducer

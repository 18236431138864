import { ReactNode } from "react";

enum JobMatchType {
    resumeAndPreferences = 'general',
    specificRole = 'specificRole',
    advice = 'advice',
}

export const textForJobMatchType = (type: JobMatchType): ReactNode => {
    switch (type) {
        case JobMatchType.resumeAndPreferences:
            return <>Provide me with a list of job matches based on my <b>resume and job preferences</b></>
        case JobMatchType.specificRole:
            return <>Provide me with a list of jobs for a <b>specific role or position</b></>
        case JobMatchType.advice:
            return <>Provide me with <b>advice</b> on the types of jobs I should be looking for based on my skills and experience</>
    }
}

export default JobMatchType;
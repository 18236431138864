export interface SuggestedPrompt {
    title: string;
    description: string;
    prompt: string;
}

class PreambleService {
    static jobSummaryPreamble = "You are acting as a programming function that returns JSON responses. Your goal is to help summarize job descriptions into compact helpful summaries for the job seeker in a JSON format.";
    getJobSummaryPreamble = (): string => PreambleService.jobSummaryPreamble;

    static jobSummaryMessage = `Fetch my updated job search criteria.\nPlease take the following job details and summarize it in the following structure returning the response as a valid JSON object.\n\n**Please use the following JSON structure:**{ "Fit Summary": { "Role Fit": "{Indicate the role fit: Very Strong Fit, Strong Fit, Weak Fit, or Very Weak Fit}" }, "Overall Fit Assessment": "{Provide an overall assessment of the job compared to my skills and preferences.}", "Detailed Fit Assessment": [ { "emoji": "✅ or 🚫", "category": "{Category Name}", "explanation": "{Explanation of the fit}" }], "Job Summary": "{Provide a brief overview of the key relevant details of the role.}", "Company Overview": "{Provide a brief overview of the company and what they do.}" }`;
    getJobSummaryMessage = (): string => PreambleService.jobSummaryMessage;

    static interviewPreamble = "ROLE: Act as an expert domain resource that would be interviewing me for my job-related skills, soft skills as well as my major accomplishments.INTERVIEW OVERVIEW: Please give me <num_questions> questions all at once in a numbered list, with no other text.";
    getInterviewPreamble = (): string => PreambleService.interviewPreamble;

    static interviewMessage = "Act as an expert domain resource that would be interviewing me for my job-related skills, soft skills as well as my major accomplishments.INTERVIEW OVERVIEW: Please give me <num_questions> questions all at once in a numbered list, with no other text.";
    getInterviewMessage = (): string => PreambleService.interviewMessage;
}

const preambleService = new PreambleService();

export default preambleService;

import styled from '@emotion/styled';
import { Popover } from '@mui/material';
import { useEffect, useState } from 'react';
import Resource from '../../shared/models/Resource';
import zIndicies from '../../shared/utils/zIndexConstants';
import { AppColors } from '../../theme/AppTheme';
import SavedResourceListSelectView from './SavedResourceListSelectView';

const FullScreenContainer = styled('div')(() => ({
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
}));

const StyledContainer = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    backgroundColor: 'white',
    width: '350px',
    height: '333px',
    borderRadius: '8px',
    zIndex: zIndicies.resourceListsModalDesktop,
    boxShadow: '0px 10px 12px rgba(0, 0, 0, 0.2)',
    overflow: 'hidden',
    paddingTop: '20px',
    border: `1px solid ${AppColors.border}`,
    pointerEvents: 'all',
}));

interface SavedResourceListDesktopModalProps {
    savedResource: Resource;
    anchorEl: HTMLElement | null;
    backgroundClicked: (deleteResource: boolean) => void;
    topRight?: boolean;
}

const SavedResourceListDesktopModal = (props: SavedResourceListDesktopModalProps) => {
    const { savedResource, anchorEl, backgroundClicked, topRight } = props;
    const [popoverOpen, setPopoverOpen] = useState(false);
    let resourceIsSaved = true;

    const onClose = () => {
        setPopoverOpen(false);
        setTimeout(() => {
            backgroundClicked(!resourceIsSaved)
        }, 300);
    }

    const onResourceStatusChange = (saved: boolean) => {
        resourceIsSaved = saved;
    }

    useEffect(() => {
        setPopoverOpen(anchorEl !== null);
    }, [anchorEl]);

    return (
        <FullScreenContainer>
            <Popover
                open={popoverOpen}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                anchorEl={anchorEl}
                style={{ zIndex: zIndicies.resourceListsModalDesktop }}
                onClose={onClose}
            >
                <StyledContainer onClick={(e) => e.stopPropagation()}>
                    <SavedResourceListSelectView savedResource={savedResource} onResourceStatusChange={onResourceStatusChange} onClose={onClose} />
                </StyledContainer>
            </Popover>
        </FullScreenContainer>
    )
}

export default SavedResourceListDesktopModal
import { Avatar, Box, Card, CardActionArea } from "@mui/material";
import User from "../shared/models/User";
import { AppColors } from "../theme/AppTheme";


interface ProfileCardProps {
  user?: User;
  open: boolean;
  onClick: () => void;
}


const ProfileCard = (props: ProfileCardProps) => {
  const { user, open, onClick } = props;

  return (
    <Card sx={{
      marginLeft: open ? '20px' : '14px',
      marginRight: open ? '24px' : '2px',
      transition: 'margin 0.3s ease-in-out',
    }}
      elevation={0}>
      <CardActionArea
        sx={{
          display: 'flex',
          flexDirection: 'row',
          py: '10px',
          px: open ? '10px' : '0px',
          alignItems: 'center',
          justifyContent: 'flex-start',
          backgroundColor: open ? AppColors.white : undefined,
          marginBottom: '20px',
          borderRadius: '8px',
          width: open ? '100%' : '35px',
          transition: 'background-color 0.3s ease-in-out, width 0.3s ease-in-out, padding 0.3s ease-in-out',
        }}
        onClick={onClick}
      >
        <Avatar src={user?.avatarUrl} alt='Profile picture' sx={{ backgroundColor: AppColors.pink.dark, fontFamily: 'Poppins', fontWeight: '400', fontSize: '20px' }}>
          {user?.initials ?? ''}
        </Avatar>
        <Box sx={{ opacity: open ? 1 : 0, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', paddingLeft: '9px', transition: 'opacity 0.3s ease-in-out' }}>
          <Box sx={{ color: AppColors.black, fontFamily: 'Poppins', fontWeight: '500', fontSize: '14px', py: 0, lineHeight: 1, paddingBottom: '4px' }}>
            {user?.firstName ?? 'Path Pilot'}
          </Box>
          <Box sx={{ color: AppColors.black, fontFamily: 'Poppins', fontWeight: '500', fontSize: '14px', py: 0, }}>
            {user?.lastName ?? ''}
          </Box>
        </Box>
      </CardActionArea>
    </Card>
  )
}

export default ProfileCard

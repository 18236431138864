import CaretLeftIcon from "../../assets/icons/caret_left_icon";
import CloseIcon from "../../assets/icons/close_icon";
import { useAppDispatch } from "../../redux/hooks";
import { setDrawerIsOpen, setSelectedTab } from "../../redux/reducers/appReducer";
import ClickableOpacityDiv from "./ClickableOpacityDiv";

export const StyledBackButton = () => {
    const dispatch = useAppDispatch();
    return (
        <ClickableOpacityDiv style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            top: '24px',
            left: '16px',
            height: '21px',
            width: '21px',
            zIndex: 10,
        }} onClick={() => {
            dispatch(setSelectedTab(-1));
        }}>
            <CaretLeftIcon />
        </ClickableOpacityDiv>
    );
}

export const StyledCloseButton = () => {
    const dispatch = useAppDispatch();
    return (
        <ClickableOpacityDiv style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            top: '24px',
            right: '24px',
            height: '21px',
            width: '21px',
            zIndex: 10,
        }} onClick={() => {
            dispatch(setDrawerIsOpen(false));
        }}>
            <CloseIcon />
        </ClickableOpacityDiv>
    );
}
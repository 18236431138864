const BrandLogo = () => {
    return (
        <svg width="22px" height="22px" viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <title>Group 31</title>
            <defs>
                <linearGradient x1="-0.0429467085%" y1="49.8857971%" x2="100.016614%" y2="49.8857971%" id="linearGradient-1">
                    <stop stopColor="#96EAF1" offset="0%"></stop>
                    <stop stopColor="#9ADEEE" offset="12.98%"></stop>
                    <stop stopColor="#A6BDE8" offset="36.7%"></stop>
                    <stop stopColor="#B888DC" offset="68.35%"></stop>
                    <stop stopColor="#CD4CD0" offset="100%"></stop>
                    <stop stopColor="#CF50D1" offset="100%"></stop>
                    <stop stopColor="#D55DD3" offset="100%"></stop>
                    <stop stopColor="#DF73D6" offset="100%"></stop>
                    <stop stopColor="#EE91DB" offset="100%"></stop>
                    <stop stopColor="#FFB6E1" offset="100%"></stop>
                </linearGradient>
                <linearGradient x1="-0.180498866%" y1="49.8939698%" x2="99.9219955%" y2="49.8939698%" id="linearGradient-2">
                    <stop stopColor="#96EAF1" offset="0%"></stop>
                    <stop stopColor="#9ADEEE" offset="12.98%"></stop>
                    <stop stopColor="#A6BDE8" offset="36.7%"></stop>
                    <stop stopColor="#B888DC" offset="68.35%"></stop>
                    <stop stopColor="#CD4CD0" offset="100%"></stop>
                    <stop stopColor="#CF50D1" offset="100%"></stop>
                    <stop stopColor="#D55DD3" offset="100%"></stop>
                    <stop stopColor="#DF73D6" offset="100%"></stop>
                    <stop stopColor="#EE91DB" offset="100%"></stop>
                    <stop stopColor="#FFB6E1" offset="100%"></stop>
                </linearGradient>
            </defs>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="PP---Desktop---Chat-Thread---Job-Detail-Flyout---1920" transform="translate(-45, -52)" fillRule="nonzero">
                    <g id="Group-31" transform="translate(45, 52)">
                        <path d="M1.93213573,22 C0.878243513,22 0,21.1217565 0,20.0678643 L0,13.3493014 C0,9.74850299 2.89820359,6.8502994 6.499002,6.8502994 L12.0758483,6.8502994 C13.1297405,6.8502994 14.007984,7.72854291 14.007984,8.78243513 C14.007984,9.83632735 13.1297405,10.7145709 12.0758483,10.7145709 L6.499002,10.7145709 C5.0499002,10.7145709 3.86427146,11.9001996 3.86427146,13.3493014 L3.86427146,20.0678643 C3.90818363,21.1217565 3.02994012,22 1.93213573,22 Z" id="Path" fill="url(#linearGradient-1)"></path>
                        <path d="M12.8662675,17.4770459 L10.9341317,17.4770459 C9.88023952,17.4770459 9.00199601,16.5988024 9.00199601,15.5449102 C9.00199601,14.491018 9.88023952,13.6127745 10.9341317,13.6127745 L12.8662675,13.6127745 C15.7644711,13.6127745 18.0918164,11.4171657 18.0918164,8.73852295 C18.0918164,6.05988024 15.7205589,3.86427146 12.8662675,3.86427146 L4.56686627,3.86427146 C3.51297405,3.86427146 2.63473054,2.98602794 2.63473054,1.93213573 C2.63473054,0.878243513 3.51297405,0 4.56686627,0 L12.8662675,0 C17.9161677,0 22,3.95209581 22,8.78243513 C22,13.6127745 17.8722555,17.4770459 12.8662675,17.4770459 Z" id="Path" fill="url(#linearGradient-2)"></path>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default BrandLogo;

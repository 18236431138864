import { ReactNode } from "react";
import { RootState } from "../../redux/store";
import CoverLetterType from "../../shared/enums/CoverLetterType";
import Job from "../../shared/models/Job";
import Message from "../../shared/models/Message";
import CoverLetTypeSelection from "../components/CoverLetTypeSelection";
import MessageCell from "../components/MessageCell";
import SavedJobSelection, { SavedJobSelectionType } from "../components/SavedJobSelection";


export const messageCellForCoverLetterChat = (message: Message, coverLetterType?: CoverLetterType): ReactNode => {
    if (!coverLetterType) {
        return (<>
            <MessageCell
                key={0}
                message={message}
                onBookmarkPressed={() => { }}
                onBookmarkJobPressed={() => { }}
                isNewest={true}
                accessory={<><br /><CoverLetTypeSelection /></>}
            />
        </>);
    }
    let accessory: ReactNode | undefined;
    let messageText = '';

    switch (coverLetterType) {
        case CoverLetterType.savedJobs:
            messageText = `Which saved job would you like to create a cover letter for?`;
            accessory = <SavedJobSelection type={SavedJobSelectionType.coverLetter} />;
            break;
        case CoverLetterType.specificRole:
            messageText = `What type of job / role would you like to create a cover letter for?`;
            break;

    }
    return (<>
        <MessageCell
            key={0}
            message={{ ...message, content: messageText }}
            onBookmarkPressed={() => { }}
            onBookmarkJobPressed={() => { }}
            isNewest={true}
            accessory={accessory ? <><br />{accessory}</> : undefined}
        />
    </>);

}

export const titleForCoverLetterType = (type: CoverLetterType): string => {
    switch (type) {
        case CoverLetterType.general:
            return 'General Cover Letter';
        case CoverLetterType.savedJobs:
            return 'Saved Job Cover Letter';
        case CoverLetterType.specificRole:
            return 'Specific Role Cover Letter';
    }
}

export const initialMessageForCoverLetterType = (type: CoverLetterType): string => {
    switch (type) {
        case CoverLetterType.savedJobs:
            return `Create a cover letter for one of my saved jobs.`;
        case CoverLetterType.specificRole:
            return `Create a cover letter for a specific role or position that I'm interested in.`;
        default:
            return '';
    }
}

export const coverLetterStartMessage = (type: CoverLetterType, state: RootState, job?: Job, role?: string): string => {
    const userSkills = state.userState.currentUser?.info?.skills;
    switch (type) {
        case CoverLetterType.general:
            return `[COVER_LETTER_GENERAL]\n\nCan you create a cover letter based on my resume and skills.\n\n Fetch my latest resume and job search criteria, I've updated them.`;
        case CoverLetterType.savedJobs:
            return `[COVER_LETTER_JOB]\n\nCreate a cover letter for the job **${job?.title}** at **${job?.company}**.\n\n Job Description: ${job?.summary}\n\nFetch my latest resume, I've updated it`;
        case CoverLetterType.specificRole:
            return `[COVER_LETTER_ROLE]\n\nCreate a cover letter to help me apply to the role **${role}**.\n\nFetch my latest resume, I've updated it`;
    }
}

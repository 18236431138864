import styled from '@emotion/styled';
import { Check } from '@mui/icons-material';
import { Input, Typography, useTheme } from '@mui/material';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import InterviewIcon from '../../assets/icons/interview_icon';
import RewriteIconLarger from '../../assets/icons/rewrite_icon copy';
import SaveIconSelected from '../../assets/icons/save_icon_selected';
import SaveIconUnselected from '../../assets/icons/save_icon_unselected';
import SavedResourceListDesktopModal from '../../mainTabs/savedResources/SavedResourceListDesktopModal';
import SavedResourceListMobileModal from '../../mainTabs/savedResources/SavedResourceListMobileModal';
import { MainTabType } from '../../navigation/classes/MainTab';
import NavigationConstants from '../../navigation/NavigationConstants';
import { RegenerateLastMessageAction } from '../../redux/actions/ChatActions';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setSelectedTab, updateChatSession } from '../../redux/reducers/appReducer';
import { addResourceToList, addSavedResource, removeSavedResource, toggleResources } from '../../redux/reducers/resourceReducer';
import { addSavedChat, removeSavedChat, updateSavedChat } from '../../redux/reducers/userReducer';
import ChatService from '../../services/chatService';
import ResourcesService from '../../services/resourcesService';
import SavedChatsService from '../../services/savedChatsService';
import ClickableOpacityDiv from '../../shared/components/ClickableOpacityDiv';
import NotificationBar, { NotificationType } from '../../shared/components/NotificationBar';
import CoverLetterType from '../../shared/enums/CoverLetterType';
import InterviewQuestionsType from '../../shared/enums/InterviewQuestionsType';
import JobMatchType from '../../shared/enums/JobMatchType';
import ResumeFixType from '../../shared/enums/ResumeType';
import UpskillingType from '../../shared/enums/UpskillingType';
import APIJob from '../../shared/models/APIJob';
import Chat, { chatIsInterview } from '../../shared/models/Chat';
import GPTFunctions from '../../shared/models/FunctionTypes';
import { InterviewState } from '../../shared/models/Interview';
import Message, { MessageType } from '../../shared/models/Message';
import Resource from '../../shared/models/Resource';
import SavedChat from '../../shared/models/SavedChat';
import { isIOS } from '../../shared/utils/PlatformUtils';
import zIndicies from '../../shared/utils/zIndexConstants';
import { AppColors } from '../../theme/AppTheme';
import MessageActionsRow from '../components/MessageActionsRow';
import MessageCell from '../components/MessageCell';
import PendingMessageCell from '../components/PendingMessageCell';
import { messageCellForCoverLetterChat } from '../utils/CoverLetterHelper';
import { interviewConcludedMessageCell, messageCellForInterviewChat } from '../utils/InterviewHelper';
import { messageCellForInterviewQuestionsChat } from '../utils/InterviewQuestionsHelper';
import { messageCellForJobMatchChat } from '../utils/JobMatchHelper';
import { messageCellForResumeChat } from '../utils/ResumeHelper';
import { messageCellForTopLevelInterviewChat } from '../utils/TopLevelInterviewHelper';
import { messageCellForUpskillingChat } from '../utils/UpskillingHelper';
import { accessoryForPromptType, messageForPromptType } from '../../shared/enums/PromptType';


const DaddyBox = styled('div')(() => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    overflow: 'hidden',
}));


const StyledBox = styled('div')(() => ({
    minWidth: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexShrink: 1,
    flexGrow: 1,
    minHeight: 0,
    overflowY: 'auto',
    overflowX: 'hidden',
    maxWidth: `100%`,

}));

const StyledColumn = styled('div')((props: { fullSize: boolean }) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: 'calc(100%)',
    marginRight: '64px',
    transition: 'all 0.3s ease-in-out',
}));


const StyledRow = styled('div')((props: { fullSize: boolean }) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: '60px',
    paddingLeft: '60px',
    paddingTop: props.fullSize ? '55px' : '32px',
    paddingBottom: props.fullSize ? '20px' : '20px',
    width: '100%',
    transition: 'all 0.3s ease-in-out',
    zIndex: zIndicies.desktopChatTitleRow,
    backgroundColor: AppColors.white,
}));

const StyledRowContent = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    width: '100%',
    maxWidth: `${NavigationConstants.maxChatWidth}px`,
    transition: 'all 0.3s ease-in-out',
}));

const StyledRowBottomBorder = styled('div')((props: { visible: boolean }) => ({
    width: '100%',
    height: '1px',
    backgroundColor: AppColors.grey.light,
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    opacity: props.visible ? 1 : 0,
    transition: 'opacity 0.3s ease-in-out',
}));


interface ChatPageProps {
    chat: Chat;
}

const ChatPage = (props: ChatPageProps) => {
    const { chat } = props;
    const containerRef = useRef<HTMLDivElement | null>(null);
    const bottomRef = useRef<HTMLDivElement | null>(null);
    const [follow, setFollow] = useState(true);
    const [saving, setSaving] = useState(false);
    const [isEditingTitle, setIsEditingTitle] = useState(false);
    const [title, setTitle] = useState(chat.sessionTitle ?? '');
    const isMobile = useAppSelector(store => store.appState.isMobile);
    const [savedChat, setSavedChat] = useState<SavedChat | undefined>(undefined);
    const [savedMessages, setSavedMessages] = useState<Map<number, SavedChat>>(new Map());
    const [savedSessionChats, setSavedSessionChats] = useState<SavedChat[]>([]);
    const [topBarFull, setTopBarFull] = useState(true);
    const [showInterviewNotificationBar, setShowInterviewNotificationBar] = useState(false);
    const savedChats = useAppSelector(store => store.userState.currentUser?.savedChats);
    const activeChat = useAppSelector(store => store.appState?.activeChat);
    const savedChatsToggle = useAppSelector(store => store.userState.savedChatsToggle);
    const followChatToggle = useAppSelector(store => store.appState.followChatToggle);
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const messageCellRef = useRef<HTMLDivElement | null>(null);
    const [scrolledToSavedMessage, setScrolledToSavedMessage] = useState(false);

    const resourceLists = useAppSelector(store => store.resourceState.resourceList);
    const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);
    const [listModalOpen, setListModalOpen] = useState(false);
    const [resource, setResource] = useState<Resource | null>(null);
    const [notificationBarOpen, setNotificationBarOpen] = useState(false);
    const [jobNotificationBarOpen, setJobNotificationBarOpen] = useState(false);
    const [shouldCloseNotification, setShouldCloseNotification] = useState(false);
    const savedInterviews = useAppSelector(store => store.interviewState.interviewsArray);
    const [isInterviewSaved, setIsInterviewSaved] = useState(savedInterviews.some(i => i.id === chat.interview?.id));
    const interviewToggle = useAppSelector(store => store.interviewState.interviewToggle);

    const focusedInterview = useAppSelector(store => store.interviewState.focusedInterview);
    const debugMode = useAppSelector(store => store.appState.debugMode);

    useEffect(() => {
        setTitle(activeChat?.sessionTitle ?? '')
    }, [activeChat]);

    useEffect(() => {
        if (containerRef.current) {
            setFollow(true);
            if (isIOS()) {
                bottomRef.current?.scrollIntoView({ behavior: 'auto' });
            } else {
                containerRef.current.scrollTop = containerRef.current.scrollHeight;
            }
        }
    }, [followChatToggle]);

    useEffect(() => {
        if (!scrolledToSavedMessage && chat.scrollToIndex !== undefined) {
            setScrolledToSavedMessage(true);
            setTimeout(() => {
                if (messageCellRef.current) {
                    messageCellRef.current.scrollIntoView({ behavior: 'smooth' });
                }
                return;
            }, 300);
        }
        const handleScroll = () => {
            const isIos = isIOS();
            if (containerRef.current) {
                const scrollTop = isIos
                    ? -(document.getElementById('innerContainer')?.getBoundingClientRect().top ?? 0)
                    : containerRef.current.scrollTop;
                const scrollHeight = containerRef.current.scrollHeight;
                const clientHeight = isIos ? window.innerHeight : containerRef.current.clientHeight;
                const distanceFromBottom = scrollHeight - scrollTop - clientHeight;
                if (!follow && distanceFromBottom < 20) {
                    setFollow(true);
                } else if (follow && distanceFromBottom > 20) {
                    setFollow(false);
                }

                // Handle top bar grow/shrink
                if (!topBarFull && scrollTop < 100) {
                    setTopBarFull(true);
                } else if (topBarFull && scrollTop >= 100) {
                    setTopBarFull(false);
                }
            }
        };

        if (containerRef.current) {
            containerRef.current.addEventListener(isMobile ? 'touchmove' : 'scroll', handleScroll);
        }

        if (containerRef.current) {
            containerRef.current.classList.add('scroll-animation');
            if (follow) {
                if (isIOS()) {
                    bottomRef.current?.scrollIntoView({ behavior: 'auto' });
                } else {
                    containerRef.current.scrollTop = containerRef.current.scrollHeight;
                }
            }

            setTimeout(() => {
                if (containerRef.current) {
                    containerRef.current.classList.remove('scroll-animation');
                }
            }, 300);
        }

        return () => {
            if (containerRef.current) {
                containerRef.current.removeEventListener(isMobile ? 'touchmove' : 'scroll', handleScroll);
            }
        };
    }, [chat.messages, chat.suggestions, follow, topBarFull]);

    useEffect(() => {
        setSavedSessionChats(savedChats?.filter(c => c.sessionId === chat.sessionId) ?? []);
    }, [savedChats, savedChatsToggle]);

    useEffect(() => {
        const savedSessionChat = savedSessionChats?.find(c => c.savedIndex === -1);
        setSavedChat(savedSessionChat);
        const savedMessages = new Map<number, SavedChat>();
        savedSessionChats.forEach(c => {
            if (c.savedIndex !== -1) {
                savedMessages.set(c.savedIndex, c);
            }
        });
        setSavedMessages(savedMessages);
    }, [savedSessionChats]);

    useEffect(() => {
        const isSaved = savedInterviews.some(i => i.id === chat.interview?.id);
        if (isSaved && !focusedInterview && !isInterviewSaved) {
            setShowInterviewNotificationBar(true);
        }
        setIsInterviewSaved(isSaved);


    }, [interviewToggle]);

    const onCopy = (message: Message) => {
        navigator.clipboard.writeText(message.content);
    }

    const onSaveMessage = async (message: Message) => {
        const savedChatData: Partial<SavedChat> = {
            title: message.content?.substring(0, 128) ?? '',
            sessionId: chat.sessionId,
            chatCreatedAt: chat.created,
            // TODO: Set the message.i when the message is saved
            savedIndex: message.i ?? -1,
        }
        const savedMessage = await SavedChatsService.saveChat(savedChatData);
        if (savedMessage)
            dispatch(addSavedChat(savedMessage));
    }

    const onUnsaveMessage = async (message: Message) => {
        if (message.i === undefined) return;
        const savedMessage = savedMessages.get(message.i);
        if (!savedMessage) return;
        dispatch(removeSavedChat(savedMessage));
        await SavedChatsService.unsaveChat(savedMessage);
    }

    const onChatSave = async () => {
        const savedChatData: Partial<SavedChat> = {
            title: chat.sessionTitle ?? '',
            sessionId: chat.sessionId,
            chatCreatedAt: chat.created,
            savedIndex: -1,
        }
        setSaving(true);
        const savedChat = await SavedChatsService.saveChat(savedChatData);
        if (savedChat)
            dispatch(addSavedChat(savedChat))
        setSaving(false);
    }

    const onUnsaveChat = async () => {
        if (!savedChat) return;
        dispatch(removeSavedChat(savedChat));
        const success = await SavedChatsService.unsaveChat(savedChat);
        if (!success)
            dispatch(addSavedChat(savedChat));
    }

    const onRewrite = () => {
        dispatch(RegenerateLastMessageAction());
    }

    const onFeedback = (message: Message) => {
        // TODO: Prompt for more info, send the feedback somewhere
        console.log(`User no likely this message`);
    }

    const onDelete = (message: Message) => {
    }

    const startedDateString = () => {
        const createdDate = moment(chat.created);
        const today = moment().startOf('day');

        if (createdDate.isSame(today, 'day')) {
            return 'Today';
        } else {
            return createdDate.format('MMM DD, YYYY');
        }
    }
    const onTitleChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(event.target.value);
    }

    const onTitleAction = async () => {
        if (isEditingTitle) {
            setIsEditingTitle(false);
            const updatedSession = await ChatService.updateTitle(chat.sessionId, title);
            if (!updatedSession) {
                setTitle(chat.sessionTitle ?? '');
            } else {
                if (isSaved) {
                    dispatch(updateSavedChat({ ...savedChat!, title }));
                    await SavedChatsService.updateTitle(savedChat, title);
                }
                dispatch(updateChatSession(updatedSession))
            }
        } else {
            setIsEditingTitle(true);
        }
    }

    const onBookmarkPressed = async (bookmarking: boolean, resource: Resource) => {
        setResource(resource);
        if (bookmarking) {
            const savedResource = await ResourcesService.saveResource(resource);
            if (savedResource) {
                dispatch(addSavedResource(savedResource));
                const favouritesList = resourceLists.find(list => list.name === 'Likes');
                if (favouritesList) {
                    const success = await ResourcesService.addResourceToList(favouritesList.id, savedResource.url);
                    if (success) {
                        dispatch(addResourceToList({ listId: favouritesList.id, resource: savedResource }));
                        setNotificationBarOpen(true);
                    }
                } else {
                    console.error("Couldn't find likes list");
                }
            }
        } else {
            setAnchorElement(document.getElementById(resource.url ?? ''));
            setListModalOpen(true);
        }
    }

    const onBookmarkJobPressed = async () => {
        setJobNotificationBarOpen(true);
    }

    const onListModalClosed = async (shouldDelete: boolean) => {
        setListModalOpen(false);
        setShouldCloseNotification(true);
        if (!resource) return;
        if (shouldDelete) {
            await ResourcesService.unsaveResource(resource)
            dispatch(removeSavedResource(resource));
            dispatch(toggleResources());
        }
    }

    const isSaved = !!savedChat;


    const onShowLists = () => {
        setAnchorElement(document.getElementById('action-btn'));
        setListModalOpen(true);
    }

    const onShowSavedJobs = () => {
        dispatch(setSelectedTab(MainTabType.savedJobs));
    }

    return (
        <DaddyBox>
            {!isMobile &&
                <StyledRow fullSize={topBarFull}>
                    <StyledRowContent>
                        <StyledColumn fullSize={topBarFull}>
                            <span style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                textAlign: 'left',
                                display: 'flex',
                                alignItems: 'center',
                                width: '100%',
                                fontSize: topBarFull ? '18px' : '14px',
                                transition: 'all 0.3s ease-in-out',
                                marginBottom: topBarFull ? '12px' : '0px',
                                maxHeight: '24px',
                                willChange: 'font-size',
                                fontFamily: 'Poppins',
                                fontWeight: 600,
                            }}>
                                {isEditingTitle
                                    ? <Input
                                        autoFocus
                                        id={'session-title-input'}
                                        value={title}
                                        onChange={onTitleChanged}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                onTitleAction();
                                            }
                                        }}
                                        placeholder='Enter chat title'
                                        style={{ ...theme.typography.h4, width: '100%' }}
                                    />
                                    : title}
                            </span>
                            <Typography variant='caption' style={{ opacity: topBarFull ? 1 : 0, maxHeight: topBarFull ? '19px' : 0, whiteSpace: 'noWrap', transition: 'all 0.3s ease-in-out' }} >
                                {`Started ${startedDateString()} @ ${new Date(chat.created).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' })}`}
                            </Typography>
                        </StyledColumn>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                            <ClickableOpacityDiv onClick={onTitleAction} >
                                {isEditingTitle ? <Check htmlColor={AppColors.grey.dark} /> : <RewriteIconLarger color={AppColors.black} />}
                            </ClickableOpacityDiv>
                            <ClickableOpacityDiv style={{ display: 'flex', alignItems: 'center' }} onClick={saving ? undefined : isSaved ? onUnsaveChat : onChatSave}>
                                {isSaved ? <SaveIconSelected color={AppColors.black} /> : <SaveIconUnselected color={AppColors.black} />}
                            </ClickableOpacityDiv>
                        </div>
                    </StyledRowContent>
                    <StyledRowBottomBorder visible={!topBarFull} />
                </StyledRow>}
            <StyledBox>
                <div
                    ref={containerRef}
                    className={'chat-scroll-page'}
                    style={{
                        height: isMobile ? 'calc(100% - 130px)' : 'calc(100vh - 130px)',
                        overflowY: 'auto',
                        overflowX: 'hidden',
                        padding: '0px 0px 50px 0px',
                        width: '100%',
                        maxWidth: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        WebkitOverflowScrolling: 'touch',
                        paddingBottom: '150px',
                    }}
                >
                    <div id="innerContainer"
                        style={{
                            paddingRight: isMobile ? '20px' : '60px',
                            paddingLeft: isMobile ? '20px' : '60px',
                            marginTop: isMobile ? 0 : '48px',
                            width: isMobile ? 'calc(100% - 40px)' : 'calc(100%)',
                            maxWidth: `calc(${NavigationConstants.maxChatWidth}px + 120px)`,
                        }}>

                        {chat.messages.map((message, index) => {
                            const isLastReceived = index === (chat.messages.length - 1) && message.type === MessageType.received;
                            const isSaved = !!savedMessages.get(message.i ?? -1);
                            // Was last message a job prompt?
                            let isJobPromptResponse = false;
                            // Check if last message was the jobs response - feed it forward
                            let jobsData: APIJob[] | undefined = undefined;
                            if (index > 0) {
                                const lastMessage = chat.messages[index - 1];
                                if (lastMessage.type === MessageType.fnReturn && lastMessage.function_name === GPTFunctions.getJobs) {
                                    jobsData = JSON.parse(lastMessage.content ?? '{}');
                                }
                                let messageIndex = index - 1;
                                let lastSentMessage = lastMessage;
                                while (lastSentMessage.type !== MessageType.sent && messageIndex > 0) {
                                    messageIndex--;
                                    lastSentMessage = chat.messages[messageIndex];
                                }
                                if (lastSentMessage.type === MessageType.sent && lastSentMessage.content.includes('[JOB')) {
                                    isJobPromptResponse = true;
                                }
                            }

                            // Handle controlled interview flow
                            if (chatIsInterview(chat)) {
                                const interview = chat.interview!;
                                if (index === 0) {
                                    return messageCellForInterviewChat(message, interview);
                                } else if (isLastReceived) {
                                    if (interview.state == InterviewState.completed && message.type == MessageType.received) {
                                        return interviewConcludedMessageCell(message, interview);
                                    }
                                }
                            } else if (chat.isInterviewTopLevel) {
                                if (index === 0) {
                                    return messageCellForTopLevelInterviewChat(message);
                                }
                            } else if (chat.isInterviewQuestionsChat) {
                                const hasBonusMessage = (chat.interviewQuestionsType === InterviewQuestionsType.job || chat.interviewQuestionsType === InterviewQuestionsType.specified);
                                if (index === 1 && hasBonusMessage) {
                                    return messageCellForInterviewQuestionsChat(message, chat.interviewQuestionsType);
                                } else if (index === 0 && (!hasBonusMessage || !chat.interviewQuestionsType)) {
                                    return messageCellForInterviewQuestionsChat(message, chat.interviewQuestionsType);
                                }
                            } else if (chat.isResumeChat) {
                                const hasBonusMessage = (chat.resumeFixType === ResumeFixType.savedJobs || chat.resumeFixType === ResumeFixType.specificRole);
                                if (index === 1 && hasBonusMessage) {
                                    return messageCellForResumeChat(message, chat.resumeFixType);
                                } else if (index === 0 && (!hasBonusMessage || !chat.resumeFixType)) {
                                    return messageCellForResumeChat(message, chat.resumeFixType);
                                }
                            } else if (chat.isCoverLetterChat) {
                                const hasBonusMessage = (chat.coverLetterType === CoverLetterType.savedJobs || chat.coverLetterType === CoverLetterType.specificRole);
                                if (index === 1 && hasBonusMessage) {
                                    return messageCellForCoverLetterChat(message, chat.coverLetterType);
                                } else if (index === 0 && (!hasBonusMessage || !chat.coverLetterType)) {
                                    return messageCellForCoverLetterChat(message, chat.coverLetterType);
                                }
                            } else if (chat.isJobMatchChat) {
                                const hasBonusMessage = (chat.jobMatchType === JobMatchType.specificRole);
                                if (index === 1 && hasBonusMessage) {
                                    return messageCellForJobMatchChat(message, chat.jobMatchType);
                                } else if (index === 0 && (!hasBonusMessage || !chat.jobMatchType)) {
                                    return messageCellForJobMatchChat(message, chat.jobMatchType);
                                }
                            } else if (chat.isUpskillChat) {
                                const hasBonusMessage = (chat.upskillType === UpskillingType.savedJobs || chat.upskillType === UpskillingType.specificRole);
                                if (index === 1 && hasBonusMessage) {
                                    return messageCellForUpskillingChat(message, chat.upskillType);
                                } else if (index === 0 && (!hasBonusMessage || !chat.upskillType)) {
                                    return messageCellForUpskillingChat(message, chat.upskillType);
                                }
                            }

                            // Handle normal messages
                            if ((message.type === MessageType.received || message.type === MessageType.sent) && (message.content !== undefined && message.content.length > 0)) {
                                // Check if this message has a named document attached
                                const documentName = chat.data?.documentNames?.find(d => d.messageIndex === message.i)?.name;
                                return (<>
                                    {!!message.meta?.promptType
                                        ? <MessageCell
                                            reference={undefined}
                                            key={`start-${index}`}
                                            message={messageForPromptType(message.meta!.promptType)}
                                            onBookmarkPressed={onBookmarkPressed}
                                            onBookmarkJobPressed={onBookmarkJobPressed}
                                            isNewest={false}
                                            accessory={accessoryForPromptType(message.meta!.promptType)}
                                        />
                                        : undefined}
                                    <MessageCell
                                        reference={(message.i === chat.scrollToIndex) ? messageCellRef : undefined}
                                        key={index}
                                        message={message}
                                        suggestions={(isLastReceived && !isJobPromptResponse) ? chat.suggestions : undefined}
                                        onBookmarkPressed={onBookmarkPressed}
                                        onBookmarkJobPressed={onBookmarkJobPressed}
                                        jobsData={jobsData}
                                        isNewest={isLastReceived}
                                        documentName={documentName}
                                    />
                                    {message.type === MessageType.received && !chatIsInterview(chat) && !((chat.pending || chat.streaming) && isLastReceived) &&
                                        <MessageActionsRow
                                            onCopy={() => onCopy(message)}
                                            onRewrite={onRewrite}
                                            onSave={() => onSaveMessage(message)}
                                            onUnsave={() => onUnsaveMessage(message)}
                                            onFeedback={() => onFeedback(message)}
                                            onDelete={() => onDelete(message)}
                                            isLastSentMessage={isLastReceived}
                                            saved={isSaved}
                                        />
                                    }
                                </>);
                            } else if (message.type === MessageType.fnReturn) {
                                if (!debugMode) return null;
                                return <MessageCell
                                    reference={undefined}
                                    key={index}
                                    message={message}
                                    suggestions={undefined}
                                    onBookmarkPressed={() => { }}
                                    onBookmarkJobPressed={() => { }}
                                    jobsData={undefined}
                                    isNewest={isLastReceived}
                                    documentName={''}
                                    debug={true}
                                />;
                            } else if (message.type === MessageType.fnCall) {
                                if (!debugMode) return null;
                                return <MessageCell
                                    reference={undefined}
                                    key={index}
                                    message={{ ...message, content: JSON.stringify(message) }}
                                    suggestions={undefined}
                                    onBookmarkPressed={() => { }}
                                    onBookmarkJobPressed={() => { }}
                                    jobsData={undefined}
                                    isNewest={isLastReceived}
                                    documentName={''}
                                    debug={true}
                                />;
                            }
                            return null;
                        }
                        )}
                        {chat.pending && <PendingMessageCell />}
                        <div ref={bottomRef} style={{ minHeight: '1px' }}></div>
                    </div>
                </div>
                {notificationBarOpen && <NotificationBar
                    type={NotificationType.resource}
                    marginTop={topBarFull ? 130 : 76}
                    onClose={() => {
                        setShouldCloseNotification(false);
                        setNotificationBarOpen(false);
                    }}
                    onAction={onShowLists}
                    shouldClose={shouldCloseNotification}
                />}
                {jobNotificationBarOpen && <NotificationBar
                    type={NotificationType.savedJob}
                    marginTop={topBarFull ? 130 : 76}
                    onClose={() => {
                        setShouldCloseNotification(false);
                        setJobNotificationBarOpen(false);
                    }}
                    onAction={onShowSavedJobs}
                    shouldClose={shouldCloseNotification}
                />}
                {showInterviewNotificationBar && <NotificationBar
                    type={NotificationType.message}
                    marginTop={topBarFull ? 130 : 76}
                    onClose={() => setShowInterviewNotificationBar(false)}
                    shouldClose={false}
                    message={'Interview Saved'}
                    icon={<InterviewIcon color={AppColors.white} />}
                />}
                {!isMobile && listModalOpen && resource &&
                    <SavedResourceListDesktopModal
                        backgroundClicked={onListModalClosed}
                        savedResource={resource}
                        topRight={false}
                        anchorEl={anchorElement}
                    />
                }
                {isMobile && listModalOpen && resource &&
                    <SavedResourceListMobileModal
                        backgroundClicked={onListModalClosed}
                        savedResource={resource}
                    />
                }
            </StyledBox>
        </DaddyBox >
    )
}

export default ChatPage
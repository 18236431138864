import ResumeService from "../../services/resumeService";

class ResumeNameCache {
    private cache: Map<string, string> = new Map();
    private queue: Map<string, boolean> = new Map();

    async createAndStoreName(sessionId: string, model: string, message: string, messageIndex: number, jobName?: string): Promise<string | undefined> {
        const uniqueKey = `${sessionId}-${messageIndex}`;
        if (this.queue.has(uniqueKey)) {
            return undefined;
        }
        if (this.cache.has(uniqueKey)) {
            return this.cache.get(uniqueKey) as string;
        }
        this.queue.set(uniqueKey, true);
        console.log('Generating resume name for key:', uniqueKey);
        const name = await ResumeService.generateResumeName(model, message, new AbortController().signal, jobName) ?? 'Resume';
        this.cache.set(uniqueKey, name);
        this.queue.delete(uniqueKey);
        return name;
    }

    getResumeName(sessionId: string | undefined, messageIndex: number): string | undefined {
        if (!sessionId || !messageIndex) return;
        const uniqueKey = `${sessionId}-${messageIndex}`;
        return this.cache.get(uniqueKey);
    }
}

const resumeNameCache = new ResumeNameCache();

export default resumeNameCache;
import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { useLayoutEffect, useRef } from 'react';
import useClickedOutside from '../../hooks/useClickedOutside';
import { useAppSelector } from '../../redux/hooks';
import ClickableOpacityDiv from '../../shared/components/ClickableOpacityDiv';
import { AppColors, BoxShadow } from '../../theme/AppTheme';
import MultiCheckList from './MultiCheckList';

const Container = styled('div')((props: { isMobile: boolean }) => ({
    position: 'absolute',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: props.isMobile ? '95%' : '80%',
    backgroundColor: 'white',
    paddingTop: '18px',
    paddingBottom: '25px',
    boxShadow: BoxShadow,
    zIndex: 9999999,
    borderRadius: '12px',
}));

const TopBar = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    backgroundColor: 'white',
    boxSizing: 'border-box',
    marginBottom: '15px',
    padding: '0px 15px',
});

interface SingleSelectOptionProps {
    title: string;
    allItems: string[];
    selectedItems: string[];
    onDone: () => void;
    onSelected: (item: string) => void;
    anchorElement: HTMLElement | null;
    scrollContainer: HTMLElement | null;
}

const SelectOption = (props: SingleSelectOptionProps) => {
    const { title, allItems, selectedItems, scrollContainer, anchorElement, onSelected, onDone } = props;
    const isMobile = useAppSelector(store => store.appState.isMobile);
    const containerRef = useRef<HTMLDivElement>(null);

    useClickedOutside(containerRef, onDone);

    useLayoutEffect(() => {
        if (anchorElement && containerRef.current && scrollContainer) {
            const anchorRect = anchorElement.getBoundingClientRect();
            const container = containerRef.current;
            const containerRect = container.getBoundingClientRect();
            const scrollContainerRect = scrollContainer.getBoundingClientRect();
            const topPosition = anchorRect.top + scrollContainer.scrollTop - scrollContainerRect.top;
            const adjustedLeft = anchorRect.left - scrollContainerRect.left;

            const widthDifference = containerRect.width - anchorRect.width;
            container.style.top = `${topPosition}px`;
            container.style.left = `${adjustedLeft - (widthDifference / 2.0)}px`;
        }
    }, [anchorElement, scrollContainer, containerRef]);


    return (
        <Container isMobile={isMobile} onClick={(e) => e.stopPropagation()} ref={containerRef}>
            <TopBar>
                <Typography variant='body1'>{title}</Typography>
                <ClickableOpacityDiv onClick={onDone}>
                    <Typography variant='subtitle1'
                        style={{
                            color: AppColors.pink.dark,
                            fontWeight: 400,
                        }}>
                        Done
                    </Typography>
                </ClickableOpacityDiv>
            </TopBar>
            <MultiCheckList
                items={allItems}
                selectedItems={selectedItems}
                onSelectItem={(item) => {
                    onSelected(item);
                }}
            />
        </Container>
    )
}

export default SelectOption

import { formatChatForInterview } from '../../chat/utils/InterviewHelper';
import { auth } from '../../firebaseConfigs';
import ChatService from '../../services/chatService';
import CoverLetterType from '../../shared/enums/CoverLetterType';
import InterviewQuestionsType from '../../shared/enums/InterviewQuestionsType';
import JobMatchType from '../../shared/enums/JobMatchType';
import ResumeFixType from '../../shared/enums/ResumeType';
import UpskillingType from '../../shared/enums/UpskillingType';
import Chat, { addMessageIndexToStreamingChat, addSessionInfo, appendFunctionToChat, appendNewStreamingResponse, chatIsInterview, getLastUserMessage, newChat, updateStreamingChatMessage } from '../../shared/models/Chat';
import ChatSession, { sessionFromChat } from '../../shared/models/ChatSession';
import GPTFunctions from '../../shared/models/FunctionTypes';
import Message, { MessageMetadata, MessageType } from '../../shared/models/Message';
import resumeNameCache from '../../shared/utils/ResumeNameCache';
import { addChatToHistory, clearActiveChat, removeChatFromHistory, setAbortController, setActiveChat, setChatHistory, setChatHistoryArray, setChatHistoryBuckets, setCurrentChatAction, setFetchingChat, setUserStoppedStreaming, updateChatSession } from '../reducers/appReducer';
import { removeSavedInterview } from '../reducers/interviewReducer';
import { toggleResumeName } from '../reducers/promptReducer';
import { AppDispatch, RootState } from '../store';
import { StartCoverLetterAction } from './CoverLetterActions';
import { AddInterviewAnswerAction } from './InterviewActions';
import { StartInterviewQuestionsAction } from './InterviewQuestionsActions';
import { StartJobMatchAction } from './JobMatchActions';
import { StartResumeOptimizationAction } from './SavedResumeActions';
import { StartUpskillingAction } from './UpskillingActions';



export const sendMessageAction = (messageContent: string, startNew?: boolean, noFunctions?: boolean, metadata?: MessageMetadata) => {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        try {
            const chat = getState().appState.activeChat;

            // Hijack the chat to send an interview answer if needed
            if (!!chat && chatIsInterview(chat)) {
                dispatch(AddInterviewAnswerAction(messageContent));
                return
            }
            // Hijack the chat to send a resume job/role name if needed
            if (!startNew && !!chat && chat.isResumeChat) {
                if (chat.resumeFixType === ResumeFixType.specificRole) {
                    dispatch(StartResumeOptimizationAction(ResumeFixType.specificRole, undefined, messageContent));
                    return;
                }
            }
            // Hijack the chat for cover letter if needed
            if (!startNew && !!chat && chat.isCoverLetterChat) {
                if (chat.coverLetterType == CoverLetterType.specificRole) {
                    dispatch(StartCoverLetterAction(CoverLetterType.specificRole, undefined, messageContent));
                    return;
                }
            }
            // Hijack the chat for job match if needed
            if (!startNew && !!chat && chat.isJobMatchChat) {
                if (chat.jobMatchType == JobMatchType.specificRole || !chat.jobMatchType) {
                    dispatch(StartJobMatchAction(JobMatchType.specificRole, undefined, messageContent));
                    return;
                }
            }
            // 3 the chat for interview questions if needed
            if (!startNew && !!chat && chat.isInterviewQuestionsChat) {
                if (chat.interviewQuestionsType == InterviewQuestionsType.specified) {
                    dispatch(StartInterviewQuestionsAction(InterviewQuestionsType.specified, undefined, messageContent));
                    return;
                }
            }

            if (!!chat && chat?.sessionId.startsWith('start-')) {
                startNew = true;
            }

            if (!startNew && !!chat && chat.isUpskillChat) {
                if (chat.upskillType == UpskillingType.specificRole) {
                    dispatch(StartUpskillingAction(UpskillingType.specificRole, undefined, messageContent));
                    return;
                }
            }

            const message: Message = {
                content: messageContent,
                dt: (new Date()).toISOString(),
                type: MessageType.sent,
                meta: metadata,
            }

            const useMemory = getState().appState.memory;
            const isPrompt = message.content.startsWith('[');
            const actuallyUseMemory = useMemory && !isPrompt;

            if (startNew || !chat) {
                const freshChat = newChat(message);
                dispatch(setActiveChat(freshChat));
                console.log(`Use function: ${!noFunctions}`);
                createNewChat(freshChat, message, actuallyUseMemory, noFunctions ?? false, dispatch, getState);
            } else {
                const updatedChat = updateChat(chat, message, dispatch, true);
                sendMessageToExistingChat(updatedChat, message, actuallyUseMemory, dispatch, getState);
            }

        } catch (error) {
            console.error('Error sending chat:', error);
        }
    };
};

const createNewChat = async (chat: Chat, message: Message, memory: boolean, noFunctions: boolean, dispatch: AppDispatch, getState: () => RootState) => {
    try {
        const abortController = new AbortController();
        dispatch(setCurrentChatAction(undefined));
        dispatch(setAbortController(abortController));
        const token = await auth.currentUser?.getIdToken();
        const chatModel = getState().appState.chatModel;
        const suggModel = getState().appState.suggModel;
        console.log(`Using functions: ${!noFunctions}`);
        const res = await fetch(`${process.env.REACT_APP_API_URL}/chats/new`, {
            method: 'POST',
            signal: abortController.signal,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/x-ndjson',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({
                message: message.content,
                metadata: message.meta,
                chatModel,
                suggModel,
                memory,
                noFunctions,
            }),
        });

        if (!res.ok) {
            console.error('Failed to create new chat', res.status);
            throw new Error('Failed to create new chat');
        }

        const reader = res.body?.getReader();
        if (!reader) {
            console.error(`No reader available: ${JSON.stringify(res)}`);
            return;
        }
        const streamingChat = appendNewStreamingResponse(chat);
        await readStream(streamingChat, reader, dispatch, true, getState);

    } catch (error) {
        console.error('Error creating new chat:', error);
    }
};

export const sendMessageToExistingChat = async (chat: Chat, message: Message, memory: boolean, dispatch: AppDispatch, getState: () => RootState) => {
    const abortController = new AbortController();
    dispatch(setAbortController(abortController));
    dispatch(setCurrentChatAction(undefined));
    const token = await auth.currentUser?.getIdToken();
    const res = await fetch(`${process.env.REACT_APP_API_URL}/chats/${chat.sessionId}/send`, {
        method: 'PUT',
        signal: abortController.signal,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/x-ndjson',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
            message: message.content,
            metadata: message.meta,
            memory,
        }),
    });

    const reader = res.body?.getReader();

    if (!reader) return;
    const streamingChat = appendNewStreamingResponse(chat);
    await readStream(streamingChat, reader, dispatch, false, getState);
}

const updateChat = (chat: Chat, message: Message, dispatch: AppDispatch, pending?: boolean): Chat => {
    const updatedChat: Chat = {
        ...chat,
        messages: [...chat.messages, message],
        pending: pending ?? chat.pending ?? false,
        suggestions: undefined,
    };
    dispatch(setActiveChat(updatedChat));

    return updatedChat;
}

export enum StreamType {
    chatNew,
    chatExisting,
    interviewNew,
    interviewExisting,
}

const readStream = async (streamingChat: Chat, reader: ReadableStreamDefaultReader<Uint8Array>, dispatch: AppDispatch, newChat: boolean, getState: () => RootState) => {
    const isInterview = chatIsInterview(streamingChat);
    const useSuggestions = !isInterview && getState().appState.useSuggestedPrompts;

    const decoder = new TextDecoder('utf-8');
    let buffer = '';
    const processText = async ({ done, value }: ReadableStreamReadResult<Uint8Array>): Promise<void> => {
        const stopStreaming = getState().appState.activeChat?.stoppedByUser;
        if (done || stopStreaming) {
            if (stopStreaming) {
                getState().appState.abortController?.abort();
                dispatch(setUserStoppedStreaming(false));
                if (!streamingChat.sessionId) {
                    dispatch(clearActiveChat());
                }
            } else {
                const messages = streamingChat.messages;
                if (messages.length > 1
                    && messages[messages.length - 2].type === MessageType.fnReturn
                    && messages[messages.length - 2].function_name === GPTFunctions.getJobs) {
                    if (newChat && !isInterview) {
                        dispatch(addChatToHistory(sessionFromChat(streamingChat)));
                    }
                    return;
                }

                const suggestions = useSuggestions ? await ChatService.fetchSuggestions(streamingChat.sessionId) : [];
                streamingChat = {
                    ...streamingChat,
                    suggestions: suggestions,
                }
                dispatch(setActiveChat(streamingChat));
                if (newChat && !isInterview) {
                    dispatch(addChatToHistory(sessionFromChat(streamingChat)));
                }
            }
            return;
        }

        const lines = decoder.decode(value, { stream: true }).split('\n');
        for (let i = 0; i < lines.length; i++) {
            const line = lines[i];
            if (line) {
                buffer += line;
                try {
                    const data = JSON.parse(buffer);
                    if (data.type === 'ERROR') {
                        console.log('Error:', data);
                        streamingChat = updateChat(streamingChat, { content: "Something went wrong. Please refresh and try again", dt: (new Date()).toISOString(), type: MessageType.received }, dispatch, false);
                        dispatch(setActiveChat(streamingChat));
                    } else if (data.type === 'SESSION_INFO') {
                        streamingChat = addMessageIndexToStreamingChat(streamingChat, data["msg_total"] - 1);
                        streamingChat = addSessionInfo(streamingChat, data);
                        dispatch(setActiveChat(streamingChat));
                    } else if (data.type === MessageType.fnCall) {
                        const fnName = data.fncalls[0]._action_label;
                        if (fnName && fnName.length > 0) {
                            dispatch(setCurrentChatAction(fnName));
                        }
                    } else if (data.fragment === true) {
                        streamingChat = updateStreamingChatMessage(streamingChat, data.content);
                        dispatch(setActiveChat(streamingChat));
                    }
                    if (data.type === MessageType.fnCall || data.type === MessageType.fnReturn) {
                        streamingChat = appendFunctionToChat(streamingChat, data as Message);
                        dispatch(setActiveChat(streamingChat));
                    }
                    buffer = '';
                } catch (e) {
                    console.error('Error parsing line:', e);
                }
            }
        }

        return reader.read().then(processText);
    };
    reader.read().then(processText);
}

export const RegenerateLastMessageAction = () => {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        try {
            const activeChat = getState().appState.activeChat;
            if (activeChat?.streaming || activeChat?.pending || !(activeChat?.sessionId)) return;
            const message = getLastUserMessage(activeChat);
            if (!message) return;
            const amountToSlice = activeChat.messages.length - (message.i ?? 1);
            const newChat = {
                ...activeChat,
                messages: activeChat.messages.slice(0, -amountToSlice),
                suggestions: undefined,
                pending: true,
            }
            dispatch(setActiveChat(newChat));
            rewriteLastMessage(newChat, message, dispatch, getState);
        } catch (error) {
            console.error('Error rewriting chat:', error);
        }
    };
};

export const GetChatHistoryAction = () => {
    return async (dispatch: AppDispatch) => {
        try {
            const chatHistory = await ChatService.fetchChatHistory();
            const descendingChatHistory = chatHistory.sort((a, b) => new Date(b.updated).getTime() - new Date(a.updated).getTime());
            const today = new Date();
            let bucketKeys: string[] = ['Today', 'This Week'];
            const sortedChatHistory = descendingChatHistory.reduce((buckets: { [key: string]: ChatSession[] }, chat: ChatSession) => {
                const createdDate = new Date(chat.updated ?? chat.created);
                const year = createdDate.getFullYear();
                const month = createdDate.toLocaleString('default', { month: 'long' });

                let bucketKey = '';
                if (createdDate.toDateString() === today.toDateString()) {
                    bucketKey = 'Today';
                } else if (createdDate >= new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay())) {
                    bucketKey = 'This Week';
                } else {
                    bucketKey = `${month} ${year}`;
                }

                if (!buckets[bucketKey]) {
                    if (!bucketKeys.includes(bucketKey)) {
                        bucketKeys.push(bucketKey);
                    }
                    buckets[bucketKey] = [];
                }
                buckets[bucketKey].push(chat);

                return buckets;
            }, {});

            for (const bucketKey in sortedChatHistory) {
                sortedChatHistory[bucketKey].sort((a, b) => new Date(b.updated).getTime() - new Date(a.updated).getTime());
            }

            dispatch(setChatHistoryBuckets(bucketKeys));
            dispatch(setChatHistory(sortedChatHistory));
            dispatch(setChatHistoryArray(descendingChatHistory));
        } catch (error) {
            console.error('Error sending chat:', error);
        }
    };
};

export const FetchAndShowChatAction = (sessionId: string, scrollToIndex?: number) => {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const activeChat = getState().appState.activeChat;
        if (sessionId === activeChat?.sessionId && (activeChat?.scrollToIndex === scrollToIndex || scrollToIndex === undefined)) {
            dispatch(setActiveChat(activeChat));
            return
        };
        dispatch(setFetchingChat(true));
        try {
            let [chat, suggestions] = await Promise.all([
                ChatService.fetchChat(sessionId),
                ChatService.fetchSuggestions(sessionId)
            ]);
            // TODO: Show error if null
            if (!chat) return;
            const interview = chat.interview;
            const chatIsInterview = !!interview && Object.keys(interview).length > 0;
            if (chatIsInterview) {
                chat = formatChatForInterview(chat, interview!, false);
            }
            chat = { ...chat, suggestions: chatIsInterview ? [] : suggestions, scrollToIndex: scrollToIndex };
            dispatch(setActiveChat(chat));
        } catch (error) {
            console.error('Error fetching chat:', error);
        } finally {
            dispatch(setFetchingChat(false));
        }
    };
};

const rewriteLastMessage = async (chat: Chat, message: Message, dispatch: AppDispatch, getState: () => RootState) => {
    const abortController = new AbortController();
    dispatch(setAbortController(abortController));
    const token = await auth.currentUser?.getIdToken();
    const res = await fetch(`${process.env.REACT_APP_API_URL}/chats/${chat.sessionId}/rewrite`, {
        method: 'PUT',
        signal: abortController.signal,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/x-ndjson',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ message: message.content, index: message.i ?? -1 }),
    });

    const reader = res.body?.getReader();

    if (!reader) {
        console.error('No reader available');
        return
    }
    const streamingChat = appendNewStreamingResponse(chat);
    await readStream(streamingChat, reader, dispatch, false, getState);
}

export const DeleteChatAction = (chat: ChatSession) => {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        // Check if chat has interview, remove it too
        const interview = getState().interviewState.interviewsArray.find(i => i.sessionId === chat.sessionId);
        if (interview) {
            dispatch(removeSavedInterview(interview));
        }
        dispatch(removeChatFromHistory(chat));
        await ChatService.deleteChat(chat.sessionId);
    }
}

export const saveTitle = async (chat: Chat, title: string, dispatch: AppDispatch) => {
    const updatedSession = await ChatService.updateTitle(chat.sessionId, title);
    if (updatedSession) {
        dispatch(updateChatSession(updatedSession))
        dispatch(setActiveChat({ ...chat, sessionTitle: title }));
    }
}

export const GenerateResumeNameAction = (sessionId: string, message: string, messageIndex: number, jobName?: string) => {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const name = await resumeNameCache.createAndStoreName(sessionId, getState().promptState.resumeNameModel, message, messageIndex, jobName);
        if (!name) {
            // returns undefined if the name is already generating from a previous request
            return;
        } else if (messageIndex) {
            await ChatService.appendDocumentName(sessionId, messageIndex, name);
        }
        dispatch(toggleResumeName());
    }
}

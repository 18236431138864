import { createElement, useEffect } from 'react';


const TermlyPrivacyComponent = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://app.termly.io/embed-policy.min.js';
        script.async = true;
        document.body.appendChild(script);
    }, []);

    return createElement('div', {
        name: 'termly-embed',
        'data-id': 'c3573dd8-8c75-41e7-b1b5-d1f6e2e0d95f',
        'data-type': 'iframe'
    });
};

export default TermlyPrivacyComponent;

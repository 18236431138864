import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import DocxLogo from '../../assets/images/docx-logo.png';
import { useAppSelector } from '../../redux/hooks';
import ClickableOpacityDiv from '../../shared/components/ClickableOpacityDiv';
import CloseButton from '../../shared/components/CloseButton';
import FullScreenModalDiv from '../../shared/components/FullScreenModalDiv';
import { DownloadFileType, downloadFile } from '../../shared/utils/MarkdownUtils';
import { AppColors } from '../../theme/AppTheme';
import DocumentConentType, { nameForDocumentContentType } from '../enums/DocumentContentType';


const StyledContainer = styled('div')((props: { isMobile: boolean }) => ({
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: props.isMobile ? '90%' : '30vw',
    maxHeight: '85vh',
    backgroundColor: AppColors.white,
}));

interface DownloadFileProps {
    content: string;
    title?: string;
    type: DocumentConentType;
    onDownloaded: () => void;
}

const DownloadFileModal = (props: DownloadFileProps) => {
    const { content, title, type, onDownloaded } = props;
    const user = useAppSelector(store => store.userState.currentUser);
    const isMobile = useAppSelector(store => store.appState.isMobile);

    const resumeFilename = (): string => {
        const usersName = user?.firstName ? `${user?.firstName} ` : '';
        return `${usersName}${nameForDocumentContentType(type)}-${(new Date()).toISOString()}`;
    }

    const downloadDocxPressed = () => {
        const filename = title ?? resumeFilename();
        downloadFile(content ?? "This is my new resume", filename, DownloadFileType.docx);
        onDownloaded();
    }

    return (
        <FullScreenModalDiv onClick={onDownloaded}>
            <StyledContainer onClick={(event: React.MouseEvent) => event.stopPropagation()} isMobile={isMobile}>
                <CloseButton onClick={onDownloaded} />
                <Typography variant='h2' style={{ marginBottom: '21px', marginTop: '25px', }}>
                    Download Document
                </Typography>
                <Typography variant='h5' style={{
                    textAlign: 'center',
                    marginBottom: '25px',
                    color: AppColors.grey.darkish,
                    paddingLeft: '50px',
                    paddingRight: '50px',
                }}>
                    Please choose a file type
                </Typography>
                <ClickableOpacityDiv onClick={downloadDocxPressed} style={{ border: `2px solid ${AppColors.border}`, borderRadius: '12px', padding: '16px', marginBottom: '36px' }}>
                    <img src={DocxLogo} alt='docx logo' style={{ width: '64px', height: '64px' }} />
                </ClickableOpacityDiv>
            </StyledContainer>
        </FullScreenModalDiv>
    )
}

export default DownloadFileModal
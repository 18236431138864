import { Auth, GoogleAuthProvider, MultiFactorError, User, getMultiFactorResolver, signInWithPopup } from "firebase/auth";
import UserService from "../../services/userService";
import IUser from "../../shared/interfaces/IUser";
import { set2FALoginInfo, setAuthError, setAuthIsBusy, setOnboarding, setUser } from "../reducers/userReducer";
import { setJobViews } from "../reducers/viewedJobsReducer";
import { AppDispatch } from "../store";

export const GoogleAuthAction = (auth: Auth) => {
    return async (dispatch: AppDispatch) => {
        const provider = new GoogleAuthProvider();
        provider.addScope('https://www.googleapis.com/auth/userinfo.email');
        provider.addScope('https://www.googleapis.com/auth/userinfo.profile');
        provider.setCustomParameters({
            'login_hint': 'user@example.com',
            'prompt': 'select_account',
        });
        try {
            const result = await signInWithPopup(auth, provider);
            const credential = GoogleAuthProvider.credentialFromResult(result);
            if (credential === null) {
                throw new Error('Failed to get credentials');
            };
            dispatch(setAuthIsBusy(true));
            const existingUser = await UserService.fetchSelf();
            if (!existingUser) {
                dispatch(setOnboarding(true));
                const userInfo = result.user;
                const user = await UserService.createUser(extractUserInfo(userInfo));
                dispatch(setUser(user));
            } else {
                dispatch(setUser(existingUser));
                dispatch(setJobViews(existingUser.viewedJobs));
            }
        } catch (error) {
            if (typeof error === 'object' && error !== null && 'message' in error) {
                const firebaseError = error as { code?: string };
                switch (firebaseError.code) {
                    case 'auth/multi-factor-auth-required':
                        const resolver = getMultiFactorResolver(auth, error as MultiFactorError);
                        dispatch(set2FALoginInfo({ resolver }));
                        return;
                }
                console.error(error.message);
                dispatch(setAuthError(`${error.message}`));
            } else {
                dispatch(setAuthError(`Something went wrong. Check your info and try again.`));
            }
        } finally {
            dispatch(setAuthIsBusy(false));
        }
    };
};

const extractUserInfo = (userInfo: User): Partial<IUser> => {
    let returnInfo: Partial<IUser> = {};
    const name = userInfo.displayName;
    const names = name?.split(' ');
    if (!!names) {
        returnInfo.firstName = names[0];
        if (names.length > 1) {
            returnInfo.lastName = names[1];
        }
    }
    returnInfo.avatarUrl = userInfo.photoURL ?? '';
    return returnInfo;
}
import { ReactNode } from "react";

enum UpskillingType {
    resumeAndPreferences = 'general',
    savedJobs = 'savedJobs',
    specificRole = 'specificRole',
}

export const textForUpskillingType = (type: UpskillingType): ReactNode => {
    switch (type) {
        case UpskillingType.resumeAndPreferences:
            return <>Give me some upskilling suggestions based on my <b>resume and job preferences</b></>
        case UpskillingType.specificRole:
            return <>Give me some upskilling suggestions based on a <b>specific job / role</b> I'm interested in</>
        case UpskillingType.savedJobs:
            return <>Give me some upskilling suggestions based on one of my <b>saved jobs</b></>
    }
}

export default UpskillingType;
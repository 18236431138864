import styled from "@emotion/styled";
import { Modal, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import EditIcon from "../../assets/icons/edit_icon";
import { Strings } from "../../i18n";
import { useAppSelector } from "../../redux/hooks";
import { AppColors } from "../../theme/AppTheme";
import EditProfileModal from "../modals/EditProfileInfoModal";
import { EditableObject } from "../models/EditableValue";
import ProfileSectionHeader from "./ProfileSectionHeader";

const StyledColumn = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: '100%',
    marginBottom: '80px',
}));

interface ProfileSectionProps {
    title: string;
    data: EditableObject;
    stacked?: boolean;
    onDataEdited: (newData: EditableObject) => Promise<void>;
}

const ProfileSection = (props: ProfileSectionProps) => {
    const { title, data, onDataEdited, stacked } = props;
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const isMobile = useAppSelector(store => store.appState.isMobile);

    const onEditPressed = () => {
        setOpen(true);
    }

    const onEditData = async (newData: EditableObject) => {
        setLoading(true);
        await onDataEdited(newData);
        setLoading(false);
        setOpen(false);
    }

    return (
        <StyledColumn>
            <ProfileSectionHeader title={title} icon={<EditIcon />} action={t(Strings.edit)} onActionPressed={onEditPressed} />
            {Object.entries(data).map(([key, value]) => (
                <div key={key} style={{ display: 'flex', flexDirection: `${stacked ? 'column' : 'row'}`, alignItems: 'flex-start', justifyContent: 'flex-start', paddingTop: isMobile ? '25px' : '40px' }}>
                    <Typography variant="h4" style={{ textAlign: 'left', width: stacked ? '100%' : isMobile ? '100px' : '200px', minWidth: isMobile ? '100px' : '200px' }}>
                        {key}:
                    </Typography>
                    <Typography variant="subtitle2" style={{ marginTop: stacked ? '15px' : 0, lineHeight: '30px', textAlign: 'left', paddingLeft: stacked ? 0 : isMobile ? 'clamp(24px, 60px, 140px)' : 'clamp(40px, 140px, 140px)', color: AppColors.grey.darkish }}>
                        {value?.value ?? ''}
                    </Typography>

                </div>
            ))}
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="edit-profile"
                aria-describedby="edit-profile-info"
            >
                <EditProfileModal
                    editableData={data}
                    onClose={() => setOpen(false)}
                    loading={loading}
                    title={`Edit ${title}`}
                    subtitle="Enter information to help improve Path Pilot's recommendations"
                    onSubmit={(newData) => {
                        onEditData(newData);
                    }}
                />
            </Modal>
        </StyledColumn>
    )
}

export default ProfileSection
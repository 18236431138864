const TextLogo = () => {
    return (
        <svg width="89px" height="17px" viewBox="0 0 89 17" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <title>Group 21</title>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="PP---Desktop---Chat-Thread---Job-Detail-Flyout---1920" transform="translate(-78, -54)" fill="#D8D8D8">
                    <g id="Group-32" transform="translate(45, 52)">
                        <g id="Group-21" transform="translate(33, 2)">
                            <path d="M7.97774695,6.16048826 C7.97774695,7.10792628 7.7063364,7.77880518 7.14800612,8.21237851 C6.55607264,8.67182351 5.68755887,8.90466844 4.56659022,8.90466844 L2.79165138,8.90466844 L2.79165138,3.65272151 C3.00188685,3.62060497 3.22849312,3.59294905 3.4680237,3.56886165 C3.86092279,3.53139235 4.26157646,3.51176557 4.65878364,3.51176557 C5.76596637,3.51176557 6.61552447,3.73301287 7.1841942,4.16926258 C7.71839909,4.57964063 7.97774695,5.23000063 7.97774695,6.16048826 M4.70444955,0.930844476 C4.09700688,0.930844476 3.45768425,0.963853146 2.80371407,1.02897836 C2.13768119,1.09588782 1.55953364,1.20651147 1.09167355,1.35638867 C0.747886851,1.46165956 0.492847095,1.60975251 0.309321866,1.81048091 C0.104256116,2.03618884 0,2.34397238 0,2.72312601 L0,15.4234346 C0,16.2763072 0.55229893,16.7848192 1.47681804,16.7848192 C1.69739297,16.7848192 1.90504358,16.7598396 2.09546178,16.7107727 C2.26433945,16.6679506 2.39961392,16.6286971 2.49956193,16.593012 L2.81491514,16.4841726 L2.81491514,11.4855895 L4.68118578,11.4855895 C6.71461086,11.4855895 8.25518885,10.9967044 9.26070031,10.0332081 C10.2774129,9.0598984 10.7926621,7.74847289 10.7926621,6.13640085 C10.7926621,4.55555322 10.2886139,3.27624425 9.2934419,2.33415899 C8.31033258,1.40277924 6.76630811,0.930844476 4.70444955,0.930844476" id="Fill-1"></path>
                            <path d="M42.4148346,7.09017297 C42.1735808,6.47639014 41.8315173,5.95984907 41.3972604,5.55393165 C40.9655884,5.15247486 40.4468927,4.84915196 39.8558208,4.65377632 C39.2819814,4.46464556 38.6417971,4.36918806 37.9516389,4.36918806 C37.2744049,4.36918806 36.654038,4.46553769 36.108632,4.6564527 C35.8647933,4.74209681 35.6338789,4.83398581 35.4167505,4.93211969 L35.4167505,1.41000546 C35.4167505,0.572298957 34.881684,0.071816159 33.98646,0.071816159 C33.7676083,0.071816159 33.5573728,0.0959035664 33.3600616,0.144078381 C33.1713667,0.19136107 33.0378155,0.231506749 32.9387291,0.27343668 L32.6475013,0.392981591 L32.6475013,15.4467191 C32.6475013,16.2853177 33.191184,16.7849084 34.1010555,16.7849084 C34.3216305,16.7849084 34.5301427,16.7599288 34.7205609,16.7108619 C34.9015013,16.6644713 35.0359141,16.6216493 35.1263843,16.58418 L35.4167505,16.4637429 L35.4167505,7.6905739 C35.695054,7.4898455 36.0121305,7.31320452 36.3636717,7.16421944 C36.7738032,6.99025483 37.2235693,6.90193434 37.6983224,6.90193434 C38.4186373,6.90193434 38.9752444,7.08928084 39.3991618,7.47467936 C39.8049852,7.84491173 40.003158,8.42211738 40.003158,9.24019711 L40.003158,15.4467191 C40.003158,16.2853177 40.5468407,16.7849084 41.4567123,16.7849084 C41.6609164,16.7849084 41.8633973,16.760821 42.0589852,16.7126462 C42.2476802,16.6662556 42.3812314,16.6252178 42.482041,16.58418 L42.7715456,16.4637429 L42.7715456,9.19291442 C42.7715456,8.40249061 42.6517803,7.69592666 42.4156962,7.09106509 L42.4148346,7.09017297 Z" id="Fill-3"></path>
                            <path d="M30.6777499,14.569313 C30.5778019,14.3837507 30.4692377,14.2320893 30.3460259,14.1054073 L30.0970175,13.8475829 L29.7911422,14.0295766 C29.6093402,14.1366318 29.3723944,14.2338735 29.087198,14.3186255 C28.8063096,14.4015932 28.5547163,14.4435232 28.3375879,14.4435232 C27.8102759,14.4435232 27.4096223,14.3418208 27.1459663,14.1419845 C26.9279762,13.976049 26.8168272,13.6522072 26.8168272,13.1793803 L26.8168272,7.45103802 L29.6515596,7.45103802 C30.3735978,7.45103802 30.8052698,6.96839774 30.8052698,6.1601314 C30.8052698,5.94780537 30.7733899,5.74618485 30.7104915,5.55973047 C30.6536246,5.39290287 30.595896,5.25997607 30.5329977,5.15113667 L30.3985848,4.91829174 L26.8168272,4.91829174 L26.8168272,2.65139906 C26.8168272,1.81369256 26.2817606,1.31320977 25.3865366,1.31320977 C25.167685,1.31320977 24.9574495,1.33729717 24.7601383,1.38547199 C24.572305,1.43275468 24.4378921,1.47290035 24.3388058,1.51483029 L24.0475779,1.6343752 L24.0475779,13.2989253 C24.0475779,14.6032138 24.4508165,15.5622494 25.2452308,16.1474842 C25.9905328,16.6970339 26.9615795,16.9762694 28.1299373,16.9762694 C28.9217668,16.9762694 29.536964,16.8478033 30.0091322,16.584626 C30.7053218,16.1965511 30.8517974,15.6737652 30.8517974,15.3035328 C30.8517974,15.0323265 30.7940688,14.7852075 30.6786116,14.5702051 L30.6777499,14.569313 Z" id="Fill-5"></path>
                            <path d="M18.8372708,11.4188585 L18.8372708,14.0649048 C18.7080277,14.1255694 18.521056,14.1996159 18.2522303,14.28526 C17.875702,14.4065891 17.3819933,14.4672537 16.7866133,14.4672537 C15.9809979,14.4672537 15.3882028,14.3325427 15.0245988,14.0657969 C14.701491,13.8293835 14.551569,13.5100023 14.551569,13.0594786 C14.551569,12.638395 14.6747807,12.3466697 14.94016,12.143265 C15.2537899,11.903283 15.7733473,11.7453767 16.4884925,11.6740066 L18.8372708,11.4188585 Z M20.2262035,5.39879091 C19.3637211,4.71542224 18.1988098,4.36927727 16.7633496,4.36927727 C16.1024864,4.36927727 15.4803962,4.43261823 14.9143113,4.55840803 C14.3396103,4.68598207 13.8191913,4.87332858 13.3633939,5.11777115 C13.0721661,5.279246 12.8576225,5.45410273 12.7094237,5.65126262 C12.5379612,5.87964693 12.4509375,6.16334306 12.4509375,6.49432188 C12.4509375,6.77266525 12.5310682,7.02692122 12.6878832,7.25173702 C12.8085101,7.42570163 12.9446462,7.58093159 13.092845,7.71475052 L13.3297907,7.92886081 L13.6098174,7.77898361 C14.0294268,7.5541678 14.5179658,7.34719453 15.0616485,7.16520078 C15.5812058,6.99034405 16.1455674,6.90202355 16.7400858,6.90202355 C17.4164581,6.90202355 17.950663,7.05279288 18.3263297,7.35165516 C18.6701164,7.62464577 18.8372708,8.07070887 18.8372708,8.71482399 L18.8372708,8.94945318 L15.978413,9.24117845 C14.6963213,9.37767375 13.6778855,9.74433762 12.9515392,10.3304645 C12.1752188,10.9567371 11.7814581,11.8827641 11.7814581,13.083566 C11.7814581,14.3557379 12.2458717,15.345998 13.1617746,16.0266903 C14.0302884,16.6725897 15.2494818,17 16.7866133,17 C17.6068763,17 18.3487318,16.9098953 18.9915009,16.7332543 C19.6463327,16.5530448 20.1624436,16.3451794 20.5708519,16.0989525 C20.8844818,15.9080375 21.1283205,15.692143 21.2963366,15.4566217 C21.4867548,15.1907681 21.5832563,14.8258885 21.5832563,14.3726883 L21.5832563,8.71482399 C21.5832563,7.22764961 21.1265973,6.11159974 20.2262035,5.39879091 L20.2262035,5.39879091 Z" id="Fill-7"></path>
                            <path d="M65.250629,0.071816159 C65.0481481,0.071816159 64.8456673,0.0959035664 64.6474945,0.144078381 C64.4587995,0.19136107 64.3243867,0.232398875 64.2261619,0.27343668 L63.9349341,0.392981591 L63.9349341,15.4467191 C63.9349341,16.2853177 64.4786168,16.7849084 65.38935,16.7849084 C65.6099249,16.7849084 65.8184371,16.7599288 66.0079937,16.7108619 C66.1880725,16.6644713 66.3233469,16.6216493 66.4146787,16.58418 L66.7041833,16.4637429 L66.7041833,1.41000546 C66.7041833,0.572298957 66.1613622,0.071816159 65.250629,0.071816159" id="Fill-9"></path>
                            <path d="M53.6065994,6.16048826 C53.6065994,7.10703415 53.3351889,7.77880518 52.7768586,8.21237851 C52.1849251,8.67182351 51.3164114,8.90466844 50.1954427,8.90466844 L48.4205039,8.90466844 L48.4205039,3.65272151 C48.6307393,3.62060497 48.8573456,3.59294905 49.0968762,3.56886165 C49.4897753,3.53139235 49.8904289,3.51176557 50.2876361,3.51176557 C51.3948189,3.51176557 52.244377,3.73301287 52.8130467,4.16926258 C53.3472516,4.57964063 53.6065994,5.23000063 53.6065994,6.16048826 M50.333302,0.930844476 C49.7258594,0.930844476 49.0865367,0.963853146 48.4325666,1.02897836 C47.7665337,1.09588782 47.1883861,1.20651147 46.720526,1.35638867 C46.3767393,1.46165956 46.1216996,1.60975251 45.9381744,1.81048091 C45.7331086,2.03618884 45.6288525,2.34397238 45.6288525,2.72312601 L45.6288525,15.4234346 C45.6288525,16.2763072 46.1811514,16.7848192 47.1056705,16.7848192 C47.3253838,16.7848192 47.5338961,16.7598396 47.7243143,16.7107727 C47.8931919,16.6679506 48.0284664,16.6286971 48.1284144,16.593012 L48.4437676,16.4841726 L48.4437676,11.4855895 L50.3100383,11.4855895 C52.3434633,11.4855895 53.8840413,10.9967044 54.8895528,10.0332081 C55.9062653,9.0598984 56.4215146,7.74847289 56.4215146,6.13640085 C56.4215146,4.55555322 55.9174664,3.27624425 54.9222944,2.33415899 C53.9391851,1.40277924 52.3951606,0.930844476 50.333302,0.930844476" id="Fill-11"></path>
                            <path d="M88.8259526,14.569313 C88.7260046,14.3837507 88.6174404,14.2320893 88.4942286,14.1054073 L88.2452202,13.8475829 L87.9393448,14.0295766 C87.7575428,14.1366318 87.5205971,14.2338735 87.2354006,14.3186255 C86.9545122,14.4015932 86.702919,14.4435232 86.4857905,14.4435232 C85.9584786,14.4435232 85.5578249,14.3418208 85.294169,14.1419845 C85.0761789,13.976049 84.9650298,13.6522072 84.9650298,13.1793803 L84.9650298,7.45103802 L87.7997622,7.45103802 C88.5218005,7.45103802 88.9534725,6.96839774 88.9534725,6.1601314 C88.9534725,5.94780537 88.9215925,5.74618485 88.8586942,5.55973047 C88.8018272,5.39290287 88.7440986,5.25997607 88.6812003,5.15113667 L88.5467875,4.91829174 L84.9650298,4.91829174 L84.9650298,2.65139906 C84.9650298,1.81369256 84.4299633,1.31320977 83.5347393,1.31320977 C83.3158876,1.31320977 83.1056521,1.33729717 82.908341,1.38547199 C82.7205076,1.43275468 82.5860948,1.47290035 82.4870084,1.51483029 L82.1957806,1.6343752 L82.1957806,13.2989253 C82.1957806,14.6032138 82.5990191,15.5622494 83.3934335,16.1474842 C84.1387355,16.6970339 85.1097821,16.9762694 86.2781399,16.9762694 C87.0699694,16.9762694 87.6851667,16.8478033 88.1573349,16.584626 C88.8535245,16.1965511 89,15.6737652 89,15.3035328 C89,15.0323265 88.9422714,14.7852075 88.8259526,14.569313" id="Fill-13"></path>
                            <path d="M77.4233491,10.6724165 C77.4233491,11.8901688 77.1631397,12.8411753 76.6504753,13.4986723 C76.1533201,14.1347583 75.4640235,14.443434 74.5420892,14.443434 C73.6175701,14.443434 72.9239654,14.1365425 72.4199172,13.5067014 C71.9012215,12.8581257 71.6384271,11.9053349 71.6384271,10.6724165 C71.6384271,9.4564485 71.904668,8.50722622 72.4311183,7.85062134 C72.942921,7.21185898 73.6330793,6.90139906 74.5420892,6.90139906 C75.4493759,6.90139906 76.1360877,7.21096685 76.6401358,7.84883709 C77.1596932,8.50544197 77.4233491,9.45555637 77.4233491,10.6724165 M78.6752842,6.09937761 L78.6752842,6.09848548 C78.1764057,5.53912236 77.5663782,5.10644115 76.8598491,4.80936313 C76.1619363,4.51674573 75.3813079,4.36865278 74.5420892,4.36865278 C73.7020089,4.36865278 72.9222421,4.51674573 72.2243293,4.80936313 C71.5186618,5.10554902 70.9051878,5.53823023 70.4011397,6.09580911 C69.8979531,6.6507116 69.505054,7.32337476 69.2319202,8.09684817 C68.9605097,8.86229245 68.823512,9.72854699 68.823512,10.6724165 C68.823512,11.6145018 68.9562016,12.4798642 69.2181343,13.2435242 C69.4843751,14.0187819 69.8746894,14.6932293 70.3778759,15.2490239 C70.8827857,15.8066028 71.4962597,16.2401761 72.2010655,16.5354699 C72.8989784,16.8280873 73.6864998,16.9761802 74.5420892,16.9761802 C75.3976787,16.9761802 76.1852001,16.8280873 76.8831129,16.5354699 C77.5887803,16.239284 78.1996695,15.8057107 78.6985479,15.2463475 C79.1922566,14.691445 79.5774011,14.0178898 79.8427803,13.2435242 C80.1055747,12.4780799 80.2382643,11.6136096 80.2382643,10.6724165 C80.2382643,9.72854699 80.100405,8.86229245 79.8289944,8.09684817 C79.5558606,7.32515901 79.1672696,6.65249585 78.6752842,6.09937761" id="Fill-15"></path>
                            <path d="M58.9753586,4.63218686 C58.7875253,4.67946955 58.6531124,4.71961523 58.554026,4.76154516 L58.2636598,4.88109007 L58.2636598,15.4474328 C58.2636598,16.2851393 58.8064809,16.7856221 59.7172141,16.7856221 C59.937789,16.7856221 60.1454397,16.7606425 60.3358578,16.7115756 C60.5167982,16.6642929 60.6529343,16.6214709 60.7425429,16.5831094 L61.0320475,16.4626724 L61.0320475,5.92220135 C61.0320475,5.0693287 60.4969809,4.55992464 59.6017569,4.55992464 C59.3829052,4.55992464 59.1726698,4.58401205 58.9753586,4.63218686 Z" id="Fill-17"></path>
                            <path d="M60.8331854,0.492453662 C60.5281716,0.165935473 60.1292412,0 59.6475952,0 C59.168534,0 58.7678803,0.164151221 58.4568352,0.486208779 C58.1449285,0.809158463 57.9863903,1.20793887 57.9863903,1.67273662 C57.9863903,2.13664225 58.1449285,2.53631478 58.4559736,2.85748022 C58.7670187,3.18132203 59.1676724,3.34458112 59.6475952,3.34458112 C60.1301028,3.34458112 60.5290332,3.17864565 60.8331854,2.85212746 C61.133891,2.53096203 61.2855363,2.13396587 61.2855363,1.67273662 C61.2855363,1.21150738 61.133891,0.81451122 60.834047,0.492453662 L60.8331854,0.492453662 Z" id="Fill-19"></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default TextLogo;

import { interviewStartMessage } from "../chat/utils/InterviewHelper";
import { auth } from "../firebaseConfigs";
import { RootState } from "../redux/store";
import Interview, { newPreInterview } from "../shared/models/Interview";
import preambleService from "./preambleService";

class InterviewService {
    static createNewInterview = async (interview: Interview, numQuestions: string, state: RootState): Promise<Interview> => {
        const model = state.promptState.interviewModel;
        const preamble = state.promptState.interviewPreamble;
        const token = await auth.currentUser?.getIdToken();
        const message = `${preambleService.getInterviewMessage().replace(/<num_questions>/g, numQuestions.toString())}. ${interviewStartMessage(interview, state)}`;

        const res = await fetch(`${process.env.REACT_APP_API_URL}/interviews/new`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({ ...interview, model, preamble, message }),
        });

        if (!res.ok) {
            if (res.status === 401) {
                const body = await res.json();
                if (body.error === 'invalid-input') {
                    return newPreInterview(interview.type, true, `**The position you specified is not valid. Please try again.**
                    &nbsp;
                    `);
                }
            } else {
                console.error('Failed to create new interview chat', res.status);
                throw new Error('Failed to create new interview chat');
            }
        }

        const data: Interview = await res.json();
        return data;
    }

    static addInterviewAnswer = async (interview: Interview, answer: string): Promise<Interview> => {
        const token = await auth.currentUser?.getIdToken();

        const res = await fetch(`${process.env.REACT_APP_API_URL}/interviews/${interview.sessionId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({ answer }),
        });

        if (!res.ok) {
            console.error('Failed to add answer to interview', res.status);
            throw new Error('Failed to add answer to interview');
        }

        const data: Interview = await res.json();
        return data;
    }

    static completeInterview = async (interview: Interview, message: string): Promise<Interview> => {
        const token = await auth.currentUser?.getIdToken();

        const body = { message }
        const res = await fetch(`${process.env.REACT_APP_API_URL}/interviews/${interview.sessionId}/complete`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(body),
        });

        if (!res.ok) {
            console.error('Failed to complete interview', res.status);
            throw new Error('Failed to mark interview as completed');
        }

        const data: Interview = await res.json();
        return data;
    }

    static saveInterview = async (interview: Interview): Promise<Interview> => {
        return InterviewService.toggleSavedInterview(interview, true);
    }

    static removeSavedInterview = async (interview: Interview): Promise<Interview> => {
        return InterviewService.toggleSavedInterview(interview, false);
    }

    static toggleSavedInterview = async (interview: Interview, saved: boolean): Promise<Interview> => {
        const token = await auth.currentUser?.getIdToken();
        const res = await fetch(`${process.env.REACT_APP_API_URL}/interviews/${interview.sessionId}/${saved ? 'save' : 'remove'}`, {
            method: saved ? 'POST' : 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        });

        if (!res.ok) {
            console.error('Failed to modify interviews saved status', res.status);
            throw new Error('Failed to modify interviews saved status');
        }

        const data: Interview = await res.json();
        return data;
    }

    static fetchSavedInterviews = async (): Promise<Interview[]> => {
        const token = await auth.currentUser?.getIdToken();

        const res = await fetch(`${process.env.REACT_APP_API_URL}/interviews/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        });

        if (!res.ok) {
            console.error('Failed to get interviews', res.status);
            throw new Error('Failed to get interviews');
        }

        const data: Interview[] = await res.json();
        return data;
    }
}

export default InterviewService;
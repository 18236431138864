import styled from '@emotion/styled';
import { Box, SxProps, Theme, Typography } from '@mui/material';
import { useState } from 'react';
import LocationIcon from '../../assets/icons/location_icon';
import SmallXIcon from '../../assets/icons/small_x_icon';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { removeSavedJob } from '../../redux/reducers/savedJobsReducer';
import JobsService from '../../services/jobsService';
import ClickableOpacityDiv from '../../shared/components/ClickableOpacityDiv';
import Job from '../../shared/models/Job';
import { AppColors } from '../../theme/AppTheme';

const StyledCard = styled(Box)((props: { isMobile: boolean }) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    width: props.isMobile ? 'calc(100% - 90px)' : 'calc(100% - 48px)',
    marginLeft: '24px',
    marginRight: '24px',
    marginBottom: '10px',
    padding: '20px',
    border: `2px solid ${AppColors.grey.light}`,
    borderRadius: '8px',
    userSelect: 'text',
    webkitUserSelect: 'text',
}));

const UnselectedStyledCard = styled(StyledCard)(() => ({
    '&:hover': {
        backgroundColor: AppColors.grey.lightest,
        cursor: 'pointer',
    }
}));

const StyledTypography = styled(Typography)(() => ({
    wordBreak: 'break-word',
    whiteSpace: 'normal',
    textAlign: 'left'
}));

const StyledRow = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    marginTop: '10px',
}));

const textTheme = (selected: boolean): SxProps<Theme> => {
    return {
        backgroundColor: selected ? AppColors.black : AppColors.white,
        color: selected ? AppColors.white : AppColors.grey.darkish,
    }
}

interface SavedJobCardProps {
    job: Job;
    selected: boolean;
    onClick: () => void;
}

const SavedJobCard = (props: SavedJobCardProps) => {
    const { job, selected, onClick } = props;
    const isMobile = useAppSelector(state => state.appState.isMobile);
    const Card = selected ? StyledCard : UnselectedStyledCard;
    const [isHovered, setIsHovered] = useState(false);

    const dispatch = useAppDispatch();

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const onDelete = async (event: React.MouseEvent) => {
        event.stopPropagation();
        await JobsService.unsaveJob(job)
        dispatch(removeSavedJob(job));
    }

    return (
        <Card sx={textTheme(selected)} onClick={onClick} isMobile={isMobile} >
            <StyledTypography variant='body1' style={{ lineHeight: '17px', color: selected ? AppColors.white : AppColors.black }}>
                {job.title}
            </StyledTypography>
            <StyledTypography variant='h6' sx={{ marginTop: '2px', color: selected ? AppColors.white : AppColors.grey.darkish }} >
                {job.company}
            </StyledTypography>
            <StyledRow>
                <LocationIcon />
                <Typography variant='caption' sx={{ marginLeft: '10px', color: selected ? AppColors.white : AppColors.grey.dark }}>
                    {job.location1}
                </Typography>
            </StyledRow>
            <ClickableOpacityDiv onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '20px', height: '20px', position: 'absolute', top: '12px', right: '12px' }}
                onClick={onDelete}
            >
                <SmallXIcon color={selected ? AppColors.white : (isHovered ? AppColors.grey.darkish : AppColors.grey.neutral)} />
            </ClickableOpacityDiv>
            {job.expired && <Typography variant='caption' sx={{ borderRadius: '8px', backgroundColor: AppColors.grey.light, color: AppColors.grey.dark, position: 'absolute', bottom: '18px', right: '18px', padding: '2px 6px' }}>
                Expired
            </Typography>}
        </Card>
    )
}

export default SavedJobCard
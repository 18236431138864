import { Typography } from '@mui/material';
import { MultiFactorResolver, PhoneAuthProvider, PhoneMultiFactorGenerator, RecaptchaVerifier, multiFactor } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ThreeDots } from 'react-loader-spinner';
import VerificationInput from 'react-verification-input';
import { auth } from '../firebaseConfigs';
import { useAppDispatch } from '../redux/hooks';
import { setTwoFactorOpen } from '../redux/reducers/userReducer';
import TwoFactorService from '../services/twoFactorService';
import CloseButton from '../shared/components/CloseButton';
import { AppColors } from '../theme/AppTheme';
import './Enter2FACodeScreen.css';

interface Enter2FACodeScreenProps {
    verificationId?: string;
    resolver?: MultiFactorResolver;
    backPressed: () => void;
    onResend: () => void;
    onSuccess: () => void;
}

const Enter2FACodeScreen = (props: Enter2FACodeScreenProps) => {
    const { resolver, verificationId, backPressed } = props;
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState('');
    const [verId, setVerId] = useState(verificationId);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (resolver) {
            sendCode();
        }
    }, []);

    const sendCode = async () => {
        if (!resolver) return;
        setLoading(true);
        const phoneInfoOptions = {
            multiFactorHint: resolver.hints[0],
            session: resolver.session
        };
        const recaptchaVerifier = new RecaptchaVerifier(auth, "recaptcha-div", {
            "size": "invisible",
            "callback": function (verificationId: any) { }
        });
        const phoneAuthProvider = new PhoneAuthProvider(auth);
        const id = await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
        setLoading(false);
        setVerId(id);
    }

    const resolveSignIn = async (verificationCode: string) => {
        if (!verId || !resolver) {
            console.error('No verification id');
            return;
        }
        const cred = PhoneAuthProvider.credential(
            verId, verificationCode);
        const multiFactorAssertion =
            PhoneMultiFactorGenerator.assertion(cred);
        try {
            setLoading(true);
            await resolver.resolveSignIn(multiFactorAssertion)
            onSuccess();
            dispatch(setTwoFactorOpen(false));
        } catch (e) {
            console.error('Error resolving sign in:', e);
            setError('Incorrect Code');
            return;
        } finally {
            setLoading(false);
        }
    }

    const submitCode = async (code: string) => {
        if (resolver) {
            resolveSignIn(code);
            return;
        }
        if (success || loading || !verificationId) return;
        setError('');
        setLoading(true);
        try {
            const fbUser = auth.currentUser;
            if (!fbUser) return false;
            const mfaUser = multiFactor(fbUser);
            await TwoFactorService.verifyCodeAndEnroll(mfaUser, verificationId, code);
            onSuccess();
        } catch (e) {
            console.error('Error verifying 2fa code:', e);
            setError('Something went wrong. Please check your number and try again.');
            return;
        } finally {
            setLoading(false);
        }
    }

    const onSuccess = () => {
        setSuccess(true);
        setTimeout(() => {
            props.onSuccess();
        }, 2000);
    }

    const onClose = () => {
        backPressed();
    }

    return (
        <>
            <CloseButton onClick={onClose} />
            <Typography variant='h2' style={{ marginBottom: '21px' }}>
                Two Factor Authentication
            </Typography>
            <Typography variant='h5' style={{
                textAlign: 'center',
                marginBottom: '40px',
                color: AppColors.grey.darkish
            }}>
                A code was sent to your mobile device. Please enter it to continue.
            </Typography>
            <VerificationInput
                placeholder=' '
                validChars='0-9'
                onComplete={submitCode}
                autoFocus
                inputProps={{ autoComplete: "one-time-code" }}
                classNames={{
                    character: "character",
                }}
            />
            <Typography variant='h5' style={{ marginTop: '24px', display: 'flex', flexDirection: 'row', justifyContent: 'center', flexWrap: 'wrap', textAlign: 'center' }}>
                Didn't get the code?
                <span style={{ marginLeft: '6px', color: AppColors.blue, cursor: 'pointer' }} onClick={props.onResend}>
                    Resend it.
                </span>
            </Typography>
            <div id={'recaptcha-div'} />
            {error && error.length > 0 && !loading &&
                <Typography variant='subtitle2' style={{ color: AppColors.error }}>
                    {error}
                </Typography>
            }
            {success &&
                <Typography variant='subtitle2' style={{ color: AppColors.success }}>
                    Two Factor Authentication enabled!
                </Typography>
            }
            <ThreeDots
                height="30"
                width="30"
                color={AppColors.pink.dark}
                ariaLabel="dots-loading"
                visible={loading}
            />
            <Typography variant='h6' style={{ color: AppColors.grey.darkish, marginTop: '60px', display: 'flex', flexDirection: 'row', justifyContent: 'center', flexWrap: 'wrap', textAlign: 'center' }}>

            </Typography>
        </>
    )
}

export default Enter2FACodeScreen;
import styled from "@emotion/styled";
import { Box } from "@mui/material";

const SavedResourceHeader = styled(Box)((props: { isMobile: boolean }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: '24px',
    paddingRight: '16px',
    marginTop: props.isMobile ? '72px' : '46px',
    width: props.isMobile ? 'calc(100% - 40px)' : '100%',
}));

export default SavedResourceHeader;
import { ReactNode } from "react";

enum TopLevelInterviewType {
    questions = 'questions',
    mock = 'mock',
}

export const textForTopLevelInterviewType = (type: TopLevelInterviewType): ReactNode => {
    switch (type) {
        case TopLevelInterviewType.questions:
            return <>Give me a <b>list of questions</b> I should prepare for</>
        case TopLevelInterviewType.mock:
            return <>Let's do a <b>mock interview</b></>
    }
}

export default TopLevelInterviewType;
import { LoadScript } from '@react-google-maps/api';
import { User, onAuthStateChanged } from 'firebase/auth';
import { useEffect } from 'react';
import './App.css';
import { auth } from './firebaseConfigs';
import DesktopNavigation from './navigation/DesktopNavigation';
import MobileNavigation from './navigation/MobileNavigation';
import { LogoutAction } from './redux/actions/GlobalActions';
import { SetCachedUserAction, VerifyEmailAction } from './redux/actions/UserActions';
import { useAppDispatch, useAppSelector } from './redux/hooks';
import { setMobile } from './redux/reducers/appReducer';
import { doneInitializing, setFirebaseUser } from './redux/reducers/userReducer';

function App() {
  const isMobile = useAppSelector(store => store.appState.isMobile);
  const onboarding = useAppSelector(store => store.userState.onboarding);
  const dispatch = useAppDispatch();
  let currentUser: User | null = null;

  function handleWindowSizeChange() {
    if (window.innerWidth <= 768 && !isMobile) {
      dispatch(setMobile(true));
    } else if (window.innerWidth > 768 && isMobile) {
      dispatch(setMobile(false));
    }
  }

  onAuthStateChanged(auth, (user) => {
    if (onboarding) return;
    if (user && currentUser !== user) {
      currentUser = user;
      if (!user.emailVerified) {
        dispatch(VerifyEmailAction());
        dispatch(doneInitializing());
        dispatch(setFirebaseUser(user));
        return;
      }
      dispatch(SetCachedUserAction());
    } else {
      currentUser = null
      dispatch(LogoutAction());
      dispatch(doneInitializing());
    }
  });

  useEffect(() => {
    if (window.innerWidth <= 768) {
      dispatch(setMobile(true));
    }
    dispatch(setFirebaseUser(auth.currentUser));
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, [isMobile]);


  return (
    <LoadScript
      googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? ''}
      libraries={['places']}
    >
      <div className="App">
        {!isMobile && <DesktopNavigation />}
        {isMobile && <MobileNavigation />}
      </div>
    </LoadScript>
  );
}

export default App;

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import ViewedJob from '../../shared/models/ViewedJob';

interface ViewedJobsState {
    viewedJobs: { [key: string]: ViewedJob };
    viewedJobsToggle: boolean;
}

const initialState: ViewedJobsState = {
    viewedJobs: {},
    viewedJobsToggle: false,
};

export const viewedJobsSlice = createSlice({
    name: 'viewedjobs',
    initialState,
    reducers: {
        addJobView: (state, action: PayloadAction<ViewedJob>) => {
            const viewedJob = action.payload;
            state.viewedJobs[viewedJob.jobId] = viewedJob;
            state.viewedJobsToggle = !state.viewedJobsToggle;
        },
        setJobViews: (state, action: PayloadAction<ViewedJob[]>) => {
            const viewedJobs = action.payload;
            for (const viewedJob of viewedJobs) {
                state.viewedJobs[viewedJob.jobId] = viewedJob;
            }
            state.viewedJobsToggle = !state.viewedJobsToggle;
        },
    },
})

export const {
    addJobView,
    setJobViews,
} = viewedJobsSlice.actions

export default viewedJobsSlice.reducer

import styled from '@emotion/styled';
import { MouseEvent, useEffect, useState } from 'react';
import SavedResume, { SavedResumeType } from '../../mainTabs/savedResumes/model/SavedResume';
import { ClearAllFocusedAction } from '../../redux/actions/GlobalActions';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { clearFocusedInterview } from '../../redux/reducers/interviewReducer';
import Interview from '../../shared/models/Interview';
import Job from '../../shared/models/Job';
import Resource from '../../shared/models/Resource';
import zIndicies from '../../shared/utils/zIndexConstants';
import { AppColors, sharpTransition } from '../../theme/AppTheme';
import InterviewContentPage from './InterviewContentPage';
import JobDetailsContentPage from './JobDetailsContentPage';
import ResourceDetailsContentPage from './ResourceDetailsContentPage';
import ResumeContentPage from './ResumeContentPage';

const Scaffold = styled('div')((props: { opacity: number, isMobile: boolean, nested: boolean }) => ({
    position: props.isMobile ? 'fixed' : 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: props.isMobile ? undefined : 0,
    backgroundColor: AppColors.loading,
    zIndex: props.nested ? zIndicies.flyoutPanelNested : props.isMobile ? zIndicies.flyoutPanelMobile : zIndicies.flyoutPanelDesktop,
    opacity: props.opacity,
    transition: `opacity 0.2s ${sharpTransition}`,
    height: props.isMobile ? '100%' : '100vh',
}));


interface RightPaneContainerProps {
    job?: Job;
    resource?: Resource;
    resume?: SavedResume;
    interview?: Interview;
    isNested?: boolean;
}

const RightPaneContainer = (props: RightPaneContainerProps) => {
    const { job, resource, resume, interview } = props;
    const [bgOpacity, setBgOpacity] = useState(0);
    const isMobile = useAppSelector((s) => s.appState.isMobile);

    const dispatch = useAppDispatch();

    useEffect(() => {
        setBgOpacity(1);
    }, []);

    const close = () => {
        setBgOpacity(0);
        setTimeout(() => {
            dispatch(ClearAllFocusedAction());
            if (interview) {
                dispatch(clearFocusedInterview());
            }
        }, 200);
    }

    const onScaffoldClick = (event: MouseEvent) => {
        if (event.target === event.currentTarget) {
            close();
        }
    }

    return (
        <Scaffold isMobile={isMobile} opacity={bgOpacity} nested={props.isNested ?? false} onClick={onScaffoldClick}>
            {!!job && <JobDetailsContentPage onClose={close} job={job} animated={true} />}
            {!!resource && <ResourceDetailsContentPage onClose={close} resource={resource} animated={true} />}
            {!!resume && <ResumeContentPage onClose={close} resumeString={resume.content} type={resume.type ?? SavedResumeType.resume} animated={true} messageIndex={0} />}
            {!!interview && <InterviewContentPage onClose={close} interview={interview} animated={true} />}
        </Scaffold>
    )
}


export default RightPaneContainer
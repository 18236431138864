import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import Resource from '../../shared/models/Resource';
import zIndicies from '../../shared/utils/zIndexConstants';
import { AppColors } from '../../theme/AppTheme';
import SavedResourceListSelectView from './SavedResourceListSelectView';

const FullScreenContainer = styled('div')(() => ({
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: zIndicies.resourceListsModalMobile,
}));

const StyledContainer = styled('div')((props: { bottom: number }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    backgroundColor: 'white',
    width: 'auto',
    maxHeight: '80vh',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    position: 'absolute',
    left: '7px',
    right: '7px',
    bottom: `${props.bottom}px`,
    overflow: 'hidden',
    paddingTop: '20px',
    border: `1px solid ${AppColors.border}`,
    pointerEvents: 'all',
    transition: 'bottom 0.3s ease-in-out',
}));

interface SavedResourceListMobileModalProps {
    savedResource: Resource;
    backgroundClicked: (deleteResource: boolean) => void;
}

const SavedResourceListMobileModal = (props: SavedResourceListMobileModalProps) => {
    const { savedResource, backgroundClicked } = props;
    const [bottom, setBottom] = useState(-1000);
    let resourceIsSaved = true;
    let closing = false;

    const onClose = () => {
        if (closing) return;
        closing = true;
        setBottom(-1000);
        setTimeout(() => {
            backgroundClicked(!resourceIsSaved)
        }, 300)
    }

    const onResourceStatusChange = (saved: boolean) => {
        resourceIsSaved = saved;
    }

    useEffect(() => {
        setBottom(0);
    }, [])

    return (
        <FullScreenContainer onClick={onClose}>
            <StyledContainer onClick={(e) => e.stopPropagation()} bottom={bottom}>
                <SavedResourceListSelectView savedResource={savedResource} onResourceStatusChange={onResourceStatusChange} onClose={onClose} />
            </StyledContainer>
        </FullScreenContainer>
    )
}

export default SavedResourceListMobileModal
import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import NavigationConstants from "../../navigation/NavigationConstants";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setDrawerIsOpen, setSelectedProfileTab } from "../../redux/reducers/appReducer";
import { clearSelectedSavedResource } from "../../redux/reducers/resourceReducer";
import { setSelectedJob } from "../../redux/reducers/savedJobsReducer";
import Job from "../../shared/models/Job";
import SavedJobCard from "./SavedJobCard";

const ScrollingList = styled('div')((props: { isMobile: boolean }) => ({
    display: 'flex',
    flexDirection: 'column',
    overflowY: props.isMobile ? undefined : 'auto',
    width: props.isMobile ? '100%' : NavigationConstants.sidebarWidth,
    paddingBottom: '50px',
}));

interface SavedJobsSearchViewProps {
    searchText: string;
}

const SavedJobsSearchView = (props: SavedJobsSearchViewProps) => {
    const { searchText } = props;
    const [filteredJobs, setFilteredJobs] = useState<Job[]>([]);
    const savedJobs = useAppSelector((s) => s.savedJobsState.jobsArray ?? []);
    const jobsToggle = useAppSelector((s) => s.savedJobsState.savedJobsToggle);
    const isMobile = useAppSelector((s) => s.appState.isMobile);
    const selectedJob = useAppSelector((s) => s.savedJobsState.selectedJob);

    const dispatch = useAppDispatch();


    useEffect(() => {
        const lowerSearch = searchText.trim().toLowerCase();
        const filtered = savedJobs.filter(job => {
            return job.title?.toLowerCase().includes(lowerSearch)
                || job.company?.toLowerCase().includes(lowerSearch)
                || job.skills?.toLowerCase().includes(lowerSearch)
                || job.summary?.toLowerCase().includes(lowerSearch)
                || job.location1?.toLowerCase().includes(lowerSearch);
        });
        setFilteredJobs(filtered);
    }, [searchText, jobsToggle]);

    const cardSelected = (job: Job) => {
        dispatch(setSelectedJob(job));
        dispatch(setSelectedProfileTab(undefined));
        dispatch(clearSelectedSavedResource(undefined));
        if (isMobile) {
            dispatch(setDrawerIsOpen(false));
        }
    }
    return (
        <ScrollingList isMobile={isMobile}>
            {filteredJobs.map((job, index) => <SavedJobCard
                key={index}
                onClick={() => cardSelected(job)}
                job={job}
                selected={(!!job.id && selectedJob?.jobId == job.id) || (!!job.url && job.url.trim().length > 0 && selectedJob?.url === job.url)}
            />)}
        </ScrollingList>
    );
}

export default SavedJobsSearchView
import { PhoneIphoneOutlined } from '@mui/icons-material';
import { Typography, useTheme } from '@mui/material';
import Input from '@mui/material/Input';
import { AppColors } from '../../theme/AppTheme';
import InputErrorText from './InputErrorText';


interface PhoneInputProps {
    value: string;
    error?: string;
    onValueChanged: (value: string) => void;
    onEnterPressed?: () => void;
}

const PhoneInput = (props: PhoneInputProps) => {
    const theme = useTheme();

    const onPhoneChanged = (value: string) => {
        if (props.value.length > value.length) {
            props.onValueChanged(value);
            return;
        }
        const numericValue = value.replace(/\D/g, '');
        const formattedValue = numericValue
            .slice(0, 10)
            .replace(/(\d{3})?(\d{3})?(\d{4})?/, (match, g1, g2, g3) => {
                return `${g1 ? `(${g1})` : ''}${g2 ? ` ${g2}` : ''}${g3 ? `-${g3}` : ''}`;
            });
        props.onValueChanged(formattedValue);
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '75px' }}>
            <Input
                disableUnderline
                id="outlined-search"
                type="email"
                error={!!props.error}
                sx={{
                    backgroundColor: AppColors.white,
                    borderRadius: '12px',
                    minHeight: '60px',
                    width: '100%',
                    paddingLeft: '18px',
                    border: `1px solid ${props.error ? AppColors.error : AppColors.grey.border}`,
                }}
                inputProps={{
                    style: {
                        paddingLeft: '6px',
                        paddingRight: '12px',
                        ...theme.typography.h5,
                        color: AppColors.black,
                    }
                }}
                placeholder='Enter your phone number'
                startAdornment={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <PhoneIphoneOutlined />
                        <Typography variant='h5' style={{ lineHeight: '44px', marginLeft: '12px', color: AppColors.black }}>
                            +1
                        </Typography>

                    </div>
                }
                value={props.value}
                onChange={(e) => onPhoneChanged(e.target.value)}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        if (props.onEnterPressed)
                            props.onEnterPressed();
                    }
                }}
            />
            {props.error && <InputErrorText variant='caption'>{props.error}</InputErrorText>}
        </div>
    )
}

export default PhoneInput
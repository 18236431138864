export enum SavedResumeType {
    resume = "resume",
    coverLetter = "coverLetter",
}
interface SavedResume {
    id: number;
    title: string;
    content: string;
    type?: SavedResumeType;
    updatedAt: string;
    createdAt: string;
}


export default SavedResume;
import styled from "@emotion/styled";
import { Modal, Switch, SwitchProps, Typography, styled as muiStyled } from "@mui/material";
import { CSSProperties, useEffect, useState } from "react";
import { Bars } from "react-loader-spinner";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { setMemory } from "../redux/reducers/appReducer";
import MemoryService from "../services/memoryService";
import ClickableOpacityDiv from "../shared/components/ClickableOpacityDiv";
import ConfirmationModal from "../shared/components/ConfirmationModal";
import zIndicies from "../shared/utils/zIndexConstants";
import { AppColors } from "../theme/AppTheme";
import MemoryRow from "./components/MemoryRow";
import ProfileSectionHeader from "./components/ProfileSectionHeader";
import Memory from "./models/Memory";

const IOSSwitch = muiStyled((props: SwitchProps) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    marginRight: '12px',
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: AppColors.pink.dark,
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: AppColors.grey.darkish,
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

const ScrollingWrapper = styled('div')(() => ({
    overflowY: 'auto',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
}));

const StyledContainer = styled('div')((props: { style?: CSSProperties, isMobile: boolean }) => ({
    padding: props.isMobile ? '24px' : '50px',
    maxWidth: '1200px',
    width: props.isMobile ? 'calc(100% - 48px)' : '100%',
    ...props.style,
}));

const Divider = () => {
    return <div style={{ marginTop: '12px', width: '100%', minHeight: '1.5px', backgroundColor: AppColors.separator }} />
}


const MemoryManagementPage = () => {
    const useMemory = useAppSelector(store => store.appState.memory);
    const isMobile = useAppSelector(store => store.appState.isMobile);

    const [memories, setMemories] = useState<Memory[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [deleteMemory, setDeleteMemory] = useState<Memory | undefined>();
    const [deleteAllMemories, setDeleteAllMemories] = useState(false);
    const [deletingMemories, setDeletingMemories] = useState(false);

    const dispatch = useAppDispatch();

    useEffect(() => {
        loadMemories();
    }, []);

    const loadMemories = async () => {
        const fetchedMemories = await MemoryService.fetchMemories();
        setMemories(fetchedMemories);
        setLoading(false);
    }

    const performDeleteMemory = async () => {
        if (!deleteMemory) return;
        const memToDelete = deleteMemory;
        setMemories(memories.filter((mem) => mem.id !== deleteMemory.id))
        setDeleteMemory(undefined);
        await MemoryService.removeMemory(memToDelete);
    }

    const onMemoryModeChanged = async () => {
        if (useMemory) {
            dispatch(setMemory(false));
        } else {
            dispatch(setMemory(true));
        }
    }

    const performDeleteAllMemories = async () => {
        setDeleteAllMemories(false);
        setDeletingMemories(true);
        for (const mem of memories) {
            await MemoryService.removeMemory(mem);
        }
        setMemories([]);
        setDeletingMemories(false);
    }

    return (
        <ScrollingWrapper>
            <StyledContainer isMobile={isMobile}>
                <ProfileSectionHeader title='Manage Memory' />

                <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', marginTop: '40px', flexDirection: `${isMobile ? 'column' : 'row'}` }}>
                    <Typography variant="h4" style={{ textAlign: 'left', width: '200px', minWidth: '200px' }}>
                        Enable Memory
                    </Typography>
                    <Typography variant="subtitle2" style={{ lineHeight: '30px', textAlign: 'left', paddingLeft: isMobile ? 0 : 'clamp(40px, 140px, 140px)', color: AppColors.grey.darkish, marginTop: isMobile ? '15px' : 0 }}>
                        By enabling memory Path Pilot will get more helpful as you interact, learning your details and preferences to customize responses just for you.
                    </Typography>
                </div>
                <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', marginTop: '28px', flexDirection: `${isMobile ? 'column' : 'row'}` }}>
                    <Typography variant="h4" style={{ textAlign: 'left', width: '200px', minWidth: '200px' }}>
                    </Typography>
                    <div style={{ display: 'flex', alignItems: 'center', marginLeft: isMobile ? 0 : 'clamp(40px, 140px, 140px)', color: AppColors.grey.darkish }}>
                        <><IOSSwitch checked={useMemory} onChange={onMemoryModeChanged} inputProps={{ color: 'red' }} /> {useMemory ? 'Enabled' : 'Disabled'}</>
                    </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', marginTop: '40px', flexDirection: `${isMobile ? 'column' : 'row'}` }}>
                    <Typography variant="h4" style={{ textAlign: 'left', width: '200px', minWidth: '200px' }}>
                        Saved Memories
                    </Typography>
                    {deletingMemories ?
                        <div style={{ paddingLeft: isMobile ? 0 : 'clamp(40px, 140px, 140px)' }}>
                            <Bars
                                height="30"
                                width="30"
                                color={AppColors.pink.dark}
                                ariaLabel="bars-loading"
                                visible={true}
                            />
                            <Typography variant="subtitle2" style={{ lineHeight: '30px', textAlign: 'left', color: AppColors.grey.darkish, marginTop: isMobile ? '15px' : 0 }}>
                                Deleting all memories...
                            </Typography>
                        </div> :
                        <div style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', justifyContent: 'flex-start', width: '100%', gap: '8px', paddingLeft: isMobile ? 0 : 'clamp(40px, 140px, 140px)' }}>
                            <Typography variant="subtitle2" style={{ lineHeight: '30px', textAlign: 'left', color: AppColors.grey.darkish, marginTop: isMobile ? '15px' : 0 }}>
                                {memories.length === 0 ? 'You currently have no saved memories.' : 'Below is a list of all memories that Path Pilot has saved for you.'}
                            </Typography>
                            {memories.length > 0 && <ClickableOpacityDiv onClick={() => setDeleteAllMemories(true)} style={{ marginRight: '0px', marginTop: '0px', marginBottom: '0px', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', cursor: 'pointer', color: AppColors.blue }}>
                                Clear All Memories
                            </ClickableOpacityDiv>}
                        </div>}
                </div>
                {deletingMemories || memories.length === 0 ? <></> : <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', marginTop: '28px', flexDirection: `${isMobile ? 'column' : 'row'}` }}>
                    <Typography variant="h4" style={{ textAlign: 'left', width: '200px', minWidth: '200px' }}>
                    </Typography>
                    <div style={{ display: 'flex', alignItems: 'center', marginLeft: isMobile ? 0 : 'clamp(40px, 140px, 140px)', color: AppColors.grey.darkish, width: '100%' }}>
                        {loading && <Bars
                            height="30"
                            width="30"
                            color={AppColors.pink.dark}
                            ariaLabel="bars-loading"
                            visible={true}
                        />}
                        {!loading && memories.length === 0 &&
                            <Typography variant='h3' style={{ marginTop: '0px', color: AppColors.grey.darkish }}>
                                No Saved Memories
                            </Typography>
                        }
                        {!loading && memories.length > 0 && <div style={{ border: `1px solid ${AppColors.grey.borderDark}`, marginTop: '0px', borderRadius: '8px', width: '100%' }}>
                            {memories.map((mem) =>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <MemoryRow memory={mem} onDeleteClicked={(mem) => setDeleteMemory(mem)} />
                                    <div style={{ marginLeft: '1%', width: '98%' }}>
                                        <div style={{ width: '100%', minHeight: '1.5px', backgroundColor: AppColors.separator }} />
                                    </div>
                                </div>
                            )}
                        </div>}
                    </div>
                </div>}
                {loading && <div style={{ minHeight: '40px', maxHeight: '40px', }} />}

                <div style={{ minHeight: '20px', maxHeight: '20px', }} />
            </StyledContainer>
            <Modal
                sx={{ zIndex: zIndicies.modal }}
                open={!!deleteMemory}>
                <ConfirmationModal title="Delete Memory" subtitle={`Remove memory: \"${deleteMemory?.memory}\"`} onCancel={() => { setDeleteMemory(undefined) }} onConfirm={performDeleteMemory} confirmText="Yes" />
            </Modal>
            <Modal
                sx={{ zIndex: zIndicies.modal }}
                open={deleteAllMemories}>
                <ConfirmationModal title="Clear all Memories" subtitle={`Are you sure you want to delete all your memories? This action can't be reversed.`} onCancel={() => { setDeleteAllMemories(false) }} onConfirm={performDeleteAllMemories} confirmText="Yes" />
            </Modal>
        </ScrollingWrapper>
    )
}

export default MemoryManagementPage;
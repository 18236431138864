import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import CloseIcon from '../../assets/icons/close_icon';
import ClickableDiv from '../../shared/components/ClickableDiv';
import { AppColors } from '../../theme/AppTheme';
import Memory from '../models/Memory';

const Row = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    padding: '4px 12px',
    justifyContent: 'space-between',
    alignItems: 'center',
}));

interface MemoryRowProps {
    memory: Memory;
    onDeleteClicked: (memory: Memory) => void
}

const MemoryRow = (props: MemoryRowProps) => {
    const { memory, onDeleteClicked } = props;

    return (
        <Row>
            <Typography variant='h5' style={{ width: '100%', textAlign: 'left', marginRight: '8px' }}>
                {memory.memory}
            </Typography>
            <ClickableDiv onClick={() => onDeleteClicked(memory)} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CloseIcon color={AppColors.grey.dark} />
            </ClickableDiv>

        </Row>
    )
}

export default MemoryRow
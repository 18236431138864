import styled from '@emotion/styled';
import { Checkbox, Typography } from '@mui/material';
import { TailSpin } from 'react-loader-spinner';
import CheckedIcon from '../../assets/icons/checked_icon';
import UncheckedIcon from '../../assets/icons/unchecked_icon';
import ResourceList from '../../shared/models/ResourceList';
import { AppColors } from '../../theme/AppTheme';

const StyledContainer = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    cursor: 'pointer',
}));

const StyledLeadingContainer = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
}));

const CheckboxContainer = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '20px',
    height: '20px'
}));

interface ResourceListCellProps {
    resourceList: ResourceList;
    onListToggled: (list: ResourceList) => void;
    checked: boolean;
    loading: boolean;
}

const ResourceListCell = (props: ResourceListCellProps) => {
    const { resourceList, onListToggled, checked, loading } = props;

    const onCheck = () => {
        if (loading) return;
        onListToggled(resourceList);
    }

    return (
        <StyledContainer onClick={onCheck}>
            <StyledLeadingContainer>
                <Checkbox
                    disableRipple
                    checked={checked}
                    color='secondary'
                    icon={<CheckboxContainer><UncheckedIcon /></CheckboxContainer>}
                    checkedIcon={<CheckboxContainer><CheckedIcon /></CheckboxContainer>}
                    sx={{
                        width: '20px',
                        height: '20px',
                        marginRight: '18px',
                        color: AppColors.grey.neutral,
                        '&.Mui-checked': {
                            color: AppColors.black,
                        },
                    }} />
                <Typography variant='h3' style={{ fontSize: '16px', fontWeight: 400, color: checked ? AppColors.black : AppColors.grey.dark }}>
                    {resourceList.name}
                </Typography>
            </StyledLeadingContainer>

            {loading && <TailSpin
                visible={true}
                height={18}
                width={18}
                color={AppColors.pink.dark}
                ariaLabel="loading-spinner"
            />}
            {checked && !loading && <Typography variant='h4' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '14px', fontWeight: 300, height: '20px', color: AppColors.grey.dark }}>
                Added
            </Typography>}
        </StyledContainer>
    )
}

export default ResourceListCell

import { formatChatForInterview, formatPreInterviewChat, initialInterviewPrompt, interviewTitle } from '../../chat/utils/InterviewHelper';
import { initialMessageForResumeFixType, titleForResumeFixType } from '../../chat/utils/ResumeHelper';
import InterviewService from '../../services/interviewService';
import InterviewType from '../../shared/enums/InterviewType';
import ResumeFixType from '../../shared/enums/ResumeType';
import Chat, { appendNewStreamingResponse, newInterviewChat, newResumeChat } from '../../shared/models/Chat';
import { sessionFromChat } from '../../shared/models/ChatSession';
import Interview, { addNumQuestionsToInterview, newPreInterview, newStarterInterview, titleForInterviewType } from '../../shared/models/Interview';
import Job from '../../shared/models/Job';
import Message, { MessageType } from '../../shared/models/Message';
import { addChatToHistory, focusText, setActiveChat, setCurrentChatAction, toggleFollowChat } from '../reducers/appReducer';
import { setInterviewBuckets, setSavedInterviews } from '../reducers/interviewReducer';
import { AppDispatch, RootState } from '../store';
import { DeleteChatAction, saveTitle } from './ChatActions';
import { StartResumeOptimizationAction } from './SavedResumeActions';

export const StartInterviewAction = () => {
    return async (dispatch: AppDispatch) => {
        // Ask the user what kind of interview to start
        try {
            const message: Message = {
                content: `What type of mock interview would you like?`,
                dt: (new Date()).toISOString(),
                type: MessageType.received,
            }
            const newInterview = newPreInterview();
            const newChat = newInterviewChat(message, `Select Interview Type`, newInterview);
            dispatch(setActiveChat(newChat));
        } catch (error) {
            console.error('Error starting interview chat:', error);
        }
    };
}

export const StartInterviewForJobAction = (job: Job) => {
    return async (dispatch: AppDispatch) => {
        // Create a controlled chat for a specific job with a set opening questions
        try {
            const newInterview = newStarterInterview(job);
            const message: Message = {
                content: initialInterviewPrompt(newInterview),
                dt: (new Date()).toISOString(),
                type: MessageType.received,
            }
            const newChat = newInterviewChat(message, `Interview: ${job.title} @ ${job.company}`, newInterview);
            dispatch(setActiveChat(newChat));
        } catch (error) {
            console.error('Error starting job interview chat:', error);
        }
    };
}


export const InterviewQuestionsSelectedAction = (numQuestions: number) => {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        try {
            const chat = getState().appState.activeChat;
            const interview = chat?.interview;
            if (!chat || !interview) return;
            // Update interview object
            const updatedInterview = addNumQuestionsToInterview(interview, numQuestions);
            let updatedChat: Chat = { ...chat, interview: updatedInterview };

            // Show loading response
            const streamingChat = appendNewStreamingResponse(updatedChat, true);
            dispatch(setActiveChat(streamingChat));

            // Create the interview
            const state = getState();
            const createdInterview = await InterviewService.createNewInterview(updatedInterview, numQuestions!.toString(), state);
            updatedChat = { ...streamingChat, streaming: false, sessionId: createdInterview.sessionId ?? '-1', interview: createdInterview };
            if (createdInterview.error) {
                const newChat = newInterviewChat({ ...chat.messages[0], content: `` }, titleForInterviewType(createdInterview.type!), createdInterview);
                const freshChat = formatPreInterviewChat(newChat, createdInterview);
                dispatch(setActiveChat(freshChat));
                return;
            }
            // Replace the placeholder chat in chat history
            dispatch(addChatToHistory(sessionFromChat(updatedChat)));

            // Build the custom interview chat
            const newChat = formatChatForInterview(updatedChat, createdInterview, false);

            // Update Chat title
            await saveTitle(newChat, interviewTitle(interview, state), dispatch);
        } catch (error) {
            console.error('Error creating job interview:', error);
        }
    };
}

export const AddInterviewAnswerAction = (answer: string) => {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        try {
            const chat = getState().appState.activeChat;
            const interview = chat?.interview;

            if (!chat || !interview) return;
            const interviewIsOver = ((interview.totalQuestions ?? 0) > 0 && (interview.answers?.length === interview.totalQuestions));
            const interviewHasntStarted = (interview.totalQuestions ?? 0) === 0;
            if (interviewHasntStarted && interview.type === InterviewType.specified) {
                dispatch(UserSpecifiedInterviewRoleAction(answer));
                return;
            }
            if (interviewIsOver || interviewHasntStarted) return;
            // Update interview object
            const updatedInterview = await InterviewService.addInterviewAnswer(interview, answer);

            if (updatedInterview.answers?.length === updatedInterview.totalQuestions) {
                const message = `Here are my answers to the questions: ${updatedInterview.answers!.map((a, i) => `\n**Answer ${i + 1}**: ${a}`).join('\n')}.\n\nPlease provide feedback on my answer to each question along with a rating out of 5 for each, summary analysis, and suggested improvements.Please format your feedback like this:
                    **Question 1**\n\n 
                    Rating: Your rating here.
                    Feedback: Explain why the user got that rating.
                    Suggested Improvements: Your suggested improvements here.
                    Example Response: An example high quality response to the question.
                    \n\n
                    **Question N**\n\n
                    Rating: Your rating here.
                    Feedback: Explain why the user got that rating.
                    Suggested Improvements: Your suggested improvements here.
                    Example Response: An example high quality response to the question.
                    \n\n
                    **Overall Summary**\n\n
                    An overall summary of the interview and any additional feedback you have at the end.
                    \n\n
                    **Suggested Courses**\n\n
                    Use a function call to fetch any udemy courses you suggest the user take to improve their skills based on their answers and list only the links to the courses here.
                    Example:\n\n
                    https://www.udemy.com/course/your-course-here-1/\n
                    https://www.udemy.com/course/your-course-here-2/\n
                    https://www.udemy.com/course/your-course-here-3/`;
                dispatch(setCurrentChatAction('Thank you for your answers, I am now analyzing your responses'));
                const newChat = formatChatForInterview(chat, updatedInterview, true);
                dispatch(setActiveChat(newChat));
                const completedInterview = await InterviewService.completeInterview(updatedInterview, message);
                const newerChat = formatChatForInterview(chat, completedInterview, false);
                dispatch(setActiveChat(newerChat));
            } else {
                const newChat = formatChatForInterview(chat, updatedInterview, true);
                dispatch(setActiveChat(newChat));
            }
            dispatch(toggleFollowChat());
        } catch (error) {
            console.error('Error adding answer to interview:', error);
        }
    };
}

export const SetInterviewTypeAction = (interviewType: InterviewType) => {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        try {
            const chat = getState().appState.activeChat;
            const interview = chat?.interview;

            if (!chat || !interview) return;
            // Update interview object
            const updatedInterview: Interview = { ...interview, type: interviewType };
            const updatedChat: Chat = { ...chat, interview: updatedInterview };

            // Build the pre-interview state
            const newChat = formatPreInterviewChat(updatedChat, updatedInterview);
            dispatch(setActiveChat({ ...newChat, sessionTitle: titleForInterviewType(interviewType) }));
            if (interviewType === InterviewType.specified) {
                dispatch(focusText());
            }
        } catch (error) {
            console.error('Error setting interview type:', error);
        }
    };
}

export const SetResumeFixTypeAction = (resumeFixType: ResumeFixType) => {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        try {
            const chat = getState().appState.activeChat;

            if (!chat) return;
            if (resumeFixType == ResumeFixType.bestPractices || resumeFixType == ResumeFixType.improvements) {
                dispatch(StartResumeOptimizationAction(resumeFixType));
                return;
            }

            // Recreate the chat with the new resume fix type
            const selectedMessage: Message = {
                content: initialMessageForResumeFixType(resumeFixType),
                dt: (new Date()).toISOString(),
                type: MessageType.sent
            }
            const message: Message = {
                content: '',
                dt: (new Date()).toISOString(),
                type: MessageType.received,
            }

            const newChat = newResumeChat([selectedMessage, message], titleForResumeFixType(resumeFixType), resumeFixType);
            dispatch(setActiveChat({ ...newChat, sessionTitle: titleForResumeFixType(resumeFixType) }));

            if (resumeFixType === ResumeFixType.specificRole) {
                dispatch(focusText());
            }
        } catch (error) {
            console.error('Error setting resume fix type:', error);
        }
    };
}

export const SavedJobSelectedAction = (savedJob: Job) => {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        try {
            const chat = getState().appState.activeChat;
            const interview = chat?.interview;

            if (!chat || !interview) return;
            // Update interview object
            const updatedInterview: Interview = { ...interview, jobTitle: savedJob.title, jobCompany: savedJob.company, jobSummary: savedJob.summary };
            const updatedChat: Chat = { ...chat, interview: updatedInterview };

            // Build new interview with the saved job
            const newChat = formatChatForInterview(updatedChat, updatedInterview, false, true);
            dispatch(setActiveChat({ ...newChat, sessionTitle: `Interview: ${savedJob.title} @ ${savedJob.company}` }));
        } catch (error) {
            console.error('Error setting saved job for interview:', error);
        }
    };
}

export const UserSpecifiedInterviewRoleAction = (role: string) => {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        try {
            const chat = getState().appState.activeChat;
            const interview = chat?.interview;

            if (!chat || !interview) return;
            // Update interview object
            const updatedInterview: Interview = { ...interview, userSpecifiedRole: role };
            const updatedChat: Chat = { ...chat, interview: updatedInterview };

            // Build new interview with the specified role
            const newChat = formatChatForInterview(updatedChat, updatedInterview, false, true);
            dispatch(setActiveChat({ ...newChat, sessionTitle: `Interview: ${role}` }));
        } catch (error) {
            console.error('Error setting user specified role for interview:', error);
        }
    };
}

export const FetchInterviewsAction = () => {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const interviews = await InterviewService.fetchSavedInterviews();
        if (interviews) {
            const sortedInterviews = interviews.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());
            const today = new Date();
            let bucketKeys: string[] = ['Today', 'This Week'];
            const sortedInterviewHistory = sortedInterviews.reduce((buckets: { [key: string]: Interview[] }, interview: Interview) => {
                const createdDate = new Date(interview.updatedAt ?? interview.createdAt);
                const year = createdDate.getFullYear();
                const month = createdDate.toLocaleString('default', { month: 'long' });

                let bucketKey = '';
                if (createdDate.toDateString() === today.toDateString()) {
                    bucketKey = 'Today';
                } else if (createdDate >= new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay())) {
                    bucketKey = 'This Week';
                } else {
                    bucketKey = `${month} ${year}`;
                }

                if (!buckets[bucketKey]) {
                    if (!bucketKeys.includes(bucketKey)) {
                        bucketKeys.push(bucketKey);
                    }
                    buckets[bucketKey] = [];
                }
                buckets[bucketKey].push(interview);

                return buckets;
            }, {});

            for (const bucketKey in sortedInterviewHistory) {
                sortedInterviewHistory[bucketKey].sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());
            }

            dispatch(setInterviewBuckets(bucketKeys));
            dispatch(setSavedInterviews(sortedInterviewHistory));
        }
    }
};

export const DeleteInterviewAction = (interview: Interview) => {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        try {
            const state = getState();

            const chatForInterview = state.appState.chatHistoryArray.find(chat => chat.sessionId === interview.sessionId);
            if (!chatForInterview) return;
            dispatch(DeleteChatAction(chatForInterview));
        } catch (error) {
            console.error('Error deleting interview:', error);
        }
    };
}
import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import ChatCard from "../../chat/components/ChatCard";
import NavigationConstants from "../../navigation/NavigationConstants";
import { ClearAllFocusedAction } from "../../redux/actions/GlobalActions";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setDrawerIsOpen } from "../../redux/reducers/appReducer";
import { setSelectedResume } from "../../redux/reducers/savedResumeReducer";
import { default as SavedCoverLetter, default as SavedResume } from "./model/SavedCoverLetter";

const ScrollingList = styled('div')((props: { isMobile: boolean }) => ({
    display: 'flex',
    flexDirection: 'column',
    overflowY: props.isMobile ? undefined : 'auto',
    width: props.isMobile ? '100%' : NavigationConstants.sidebarWidth,
    paddingBottom: '50px',
}));

interface SavedCoverLettersSearchViewProps {
    searchText: string;
}

const SavedCoverLettersSearchView = (props: SavedCoverLettersSearchViewProps) => {
    const { searchText } = props;
    const [filteredCoverLetters, setFilteredCoverLetters] = useState<SavedCoverLetter[]>([]);
    const savedCoverLetters = useAppSelector((s) => s.savedResumesState.coverLettersArray ?? []);
    const resumeToggle = useAppSelector((s) => s.savedResumesState.savedResumeToggle);
    const isMobile = useAppSelector((s) => s.appState.isMobile);
    const selectedCoverLetter = useAppSelector((s) => s.savedResumesState.selectedResume);

    const dispatch = useAppDispatch();

    const cardSelected = (resume: SavedResume) => {
        dispatch(setSelectedResume(resume));
        if (isMobile) {
            dispatch(ClearAllFocusedAction());
            dispatch(setDrawerIsOpen(false));
        }
    }

    useEffect(() => {
        const filtered = savedCoverLetters.filter((resume) => resume.title.toLowerCase().includes(searchText.toLowerCase()) || resume.content.toLowerCase().includes(searchText.toLowerCase()));
        setFilteredCoverLetters(filtered);
    }, [searchText, resumeToggle]);

    return (
        <ScrollingList isMobile={isMobile}>
            {filteredCoverLetters.map((coverLetter, index) => <ChatCard key={index} onClick={() => cardSelected(coverLetter)} resume={coverLetter} selected={selectedCoverLetter?.id === coverLetter.id} />)}
        </ScrollingList>
    );
}

export default SavedCoverLettersSearchView
import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { ThreeDots } from 'react-loader-spinner';
import NewChatPlusIcon from '../../assets/icons/new_chat_plus_icon';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { addResourceList, addResourceToList, removeResourceFromList, setListModalOpen } from '../../redux/reducers/resourceReducer';
import ResourcesService from '../../services/resourcesService';
import ClickableOpacityDiv from '../../shared/components/ClickableOpacityDiv';
import PPTextInput from '../../shared/components/PPTextInput';
import PrimaryButton from '../../shared/components/PrimaryButton';
import Resource from '../../shared/models/Resource';
import ResourceList from '../../shared/models/ResourceList';
import { AppColors } from '../../theme/AppTheme';
import ResourceListCell from './ResourceListCell';
import ResourceModalClearButton from './ResourceModalDoneButton';

const StyledContainer = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    backgroundColor: 'white',
    width: '100%',
    height: '100%',
    overflowY: 'auto',
}));

const StyledList = styled('div')((props: { isMobile: boolean }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: 'auto',
    height: '100%',
    overflowY: 'auto',
    paddingRight: '20px',
    paddingLeft: '20px',
    gap: props.isMobile ? '22px' : '20px',
    paddingBottom: '20px',
}));

const TitleContainer = styled('div')((props: { isMobile: boolean }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: props.isMobile ? '0 20px' : 0,
    width: '100%',
}));

const TitleRow = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
}));

const ButtonRow = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '15px 20px',
    paddingTop: '10px',
    marginBottom: 'env(safe-area-inset-bottom)',
}));

interface SavedResourceListSelectViewProps {
    onClose: () => void;
    savedResource: Resource;
    onResourceStatusChange: (saved: boolean) => void;
}

const SavedResourceListSelectView = (props: SavedResourceListSelectViewProps) => {
    const { savedResource, onResourceStatusChange, onClose } = props;
    const savedResourceLists = useAppSelector(state => state.resourceState.resourceList);
    const resourceToggle = useAppSelector(state => state.resourceState.resourceToggle);
    const isMobile = useAppSelector(state => state.appState.isMobile);
    const [newListName, setNewListName] = useState('');
    const [listError, setListError] = useState('');
    const [creatingList, setCreatingList] = useState(false);
    const [inputFocused, setInputFocused] = useState(false);

    const dispatch = useAppDispatch();

    // Holds a map of {resourceListId: boolean} to keep track of which lists the resource is saved to
    const [resourceListMap, setResourceListMap] = useState<Map<number, boolean>>(new Map());
    const [loadingMap, setLoadingMap] = useState<Map<number, boolean>>(new Map());

    useEffect(() => {
        dispatch(setListModalOpen(true));
        return () => {
            dispatch(setListModalOpen(false));
        }
    }, []);

    useEffect(() => {
        const map = new Map<number, boolean>();
        savedResourceLists.forEach((resourceList) => {
            for (const resource of resourceList.resources) {
                if (resource.url === savedResource.url) {
                    map.set(resourceList.id, true);
                    break;
                }
            }
            if (map.has(resourceList.id) === false) map.set(resourceList.id, false);
        });
        setResourceListMap(map);
        const isInAnyLists = Array.from(map.values()).some(value => value === true);
        onResourceStatusChange(isInAnyLists);
    }, [savedResource, resourceToggle]);

    const onListToggled = async (list: ResourceList) => {
        const selected = resourceListMap.get(list.id) ?? false;
        if (selected) {
            setLoadingMap((prevMap) => new Map(prevMap.set(list.id, true)));
            const success = await ResourcesService.removeResourceFromList(list.id, savedResource.url);
            if (success) {
                setResourceListMap((prevMap) => new Map(prevMap.set(list.id, false)));
                dispatch(removeResourceFromList({ listId: list.id, resource: savedResource }));
            }
            setLoadingMap((prevMap) => new Map(prevMap.set(list.id, false)));
        } else {
            setLoadingMap((prevMap) => new Map(prevMap.set(list.id, true)));
            const success = await ResourcesService.addResourceToList(list.id, savedResource.url);
            if (success) {
                setResourceListMap((prevMap) => new Map(prevMap.set(list.id, true)));
                dispatch(addResourceToList({ listId: list.id, resource: savedResource }));
            }
            setLoadingMap((prevMap) => new Map(prevMap.set(list.id, false)));
        }
    }

    const onClear = async () => {
        resourceListMap.forEach(async (value, key) => {
            if (value) {
                const list = savedResourceLists.find(resourceList => resourceList.id === key);
                if (list) {
                    await onListToggled(list);
                }
            }
        });
    }

    const onCreateNewList = async () => {
        if (creatingList) return;
        setListError('')
        if (newListName === '') {
            return
        }
        setCreatingList(true);
        const newList = await ResourcesService.createList(newListName);
        if (newList) {
            dispatch(addResourceList(newList));
            const success = await ResourcesService.addResourceToList(newList.id, savedResource.url);
            if (success) {
                dispatch(addResourceToList({ listId: newList.id, resource: savedResource }));
            }
            setNewListName('');
        } else {
            setListError('Failed to create list');
        }
        setCreatingList(false);
    }

    return (
        <StyledContainer>
            <ResourceModalClearButton name={'Clear'} onClick={onClear} />
            <TitleContainer isMobile={isMobile}>
                <TitleRow>
                    <Typography variant="body2" style={{ padding: isMobile ? 0 : '0 20px', fontSize: '18px', marginBottom: '20px', marginTop: '2px', width: '100%', textAlign: 'left' }}>
                        Add to Lists
                    </Typography>
                </TitleRow>
            </TitleContainer>
            <StyledList isMobile={isMobile}>
                <span style={{ width: '100%', paddingBottom: '2px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around' }}>
                    <PPTextInput
                        bottomPadding='0px'
                        leadingIcon={creatingList ? <div style={{
                            padding: '10px',
                            paddingRight: '0px',
                        }}>
                            <ThreeDots
                                height="15"
                                width="15"
                                color={AppColors.pink.dark}
                                ariaLabel="dots-loading"
                                visible={true}
                            /> </div> : <ClickableOpacityDiv
                                onClick={onCreateNewList}
                                style={{
                                    padding: '10px',
                                    paddingRight: '0px',
                                }}
                            >
                            <NewChatPlusIcon color={inputFocused ? AppColors.black : AppColors.grey.darkish} />
                        </ClickableOpacityDiv>}
                        borderColor={inputFocused ? AppColors.black : AppColors.grey.darkish}
                        error={listError}
                        height='50px'
                        placeholder='Create New List'
                        id={'list-name'}
                        value={newListName}
                        onValueChanged={(newValue) => setNewListName(newValue)}
                        onEnterPressed={onCreateNewList}
                        onFocused={() => setInputFocused(true)}
                        onBlurred={() => setInputFocused(false)}
                    />
                </span>
                {savedResourceLists.map((resourceList) => <ResourceListCell key={resourceList.id} resourceList={resourceList} onListToggled={onListToggled} loading={loadingMap.get(resourceList.id) ?? false} checked={resourceListMap.get(resourceList.id) ?? false} />)}
            </StyledList>
            <ButtonRow>
                <PrimaryButton
                    title='Done'
                    onClick={onClose}
                    style={{ borderRadius: '8px', width: '100%' }}
                    height={isMobile ? '40px' : '35px'}
                    backgroundColor={AppColors.black}
                />
            </ButtonRow>
        </StyledContainer>
    )
}

export default SavedResourceListSelectView

const EmailIcon = () => {
    return (
        <svg width="18px" height="20px" viewBox="0 0 28 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <title>noun-mail-6372206-999DA0</title>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="PP---Desktop---Landing---1024-Copy-5" transform="translate(-515, -510)" fill="#999DA0">
                    <g id="Group-14" transform="translate(397, 73)">
                        <g id="noun-mail-6372206-999DA0" transform="translate(118, 437)">
                            <path d="M28,3 L28,16.9998306 C28,17.7959434 27.6840753,18.5589431 27.1210223,19.1208503 C26.5591168,19.6839007 25.7961195,20 25,20 L3.0001603,20 C2.20404991,20 1.44105255,19.6839092 0.87914707,19.1208503 C0.316098299,18.5589431 0,17.7959434 0,16.9998306 L0,3 C0,2.20405657 0.31608983,1.44105691 0.87914707,0.878980352 C1.44105255,0.316099255 2.20404991,0 3.0001603,0 L25,0 C25.7961195,0 26.5591168,0.316090786 27.1210223,0.878980352 C27.6840711,1.44101457 28,2.20405657 28,3 Z M25.5002405,2.134104 C25.3490341,2.04709942 25.1770114,2.0001228 25.0000091,2.0001228 L3.0001603,2.0001228 C2.82300975,2.0001228 2.65115221,2.04709942 2.50011947,2.134104 C2.50111189,2.13509646 2.50309677,2.13509646 2.50408922,2.13608888 L13.49618,8.54826706 C13.8073113,8.73021574 14.1932053,8.73021574 14.50418,8.54826706 L25.4962707,2.13608888 C25.4972631,2.13509646 25.499248,2.13509646 25.5002405,2.134104 L25.5002405,2.134104 Z M1.99995161,4.15802846 L1.99995161,17.0001694 C1.99995161,17.2653159 2.10498601,17.5201982 2.29288771,17.7072747 C2.47996371,17.895177 2.73487486,18.0002117 2.99999093,18.0002117 L24.9998397,18.0002117 C25.2648203,18.0002117 25.5198669,17.895177 25.7069429,17.7072747 C25.8948446,17.5201982 25.999879,17.2652862 25.999879,17.0001694 L25.999879,4.15802846 L15.5121059,10.2759146 C14.5780386,10.8209265 13.4220461,10.8209265 12.4879788,10.2759146 L1.99995161,4.15802846 Z" id="Shape"></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default EmailIcon;

import styled from "@emotion/styled";
import { Modal, Typography } from "@mui/material";
import { CSSProperties, Fragment, useState } from "react";
import DownloadIcon from "../assets/icons/download_icon";
import DownloadIconSmall from "../assets/icons/download_icon_small";
import EditIcon from "../assets/icons/edit_icon";
import SmallXIcon from "../assets/icons/small_x_icon";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { setOnboarding, setUser } from "../redux/reducers/userReducer";
import UserService from "../services/userService";
import ClickableOpacityDiv from "../shared/components/ClickableOpacityDiv";
import ConfirmationModal from "../shared/components/ConfirmationModal";
import PrimaryButton from "../shared/components/PrimaryButton";
import { formatFileSize } from "../shared/utils/StringUtils";
import { AppColors } from "../theme/AppTheme";
import ProfileSectionHeader from "./components/ProfileSectionHeader";
import EditTextResumeModal from "./modals/EditTextResumeModal";

const ScrollingWrapper = styled('div')(() => ({
    overflowY: 'auto',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
}));

const StyledContainer = styled('div')((props: { style?: CSSProperties, isMobile: boolean }) => ({
    padding: props.isMobile ? '24px' : '50px',
    maxWidth: '1200px',
    width: props.isMobile ? 'calc(100% - 48px)' : '100%',
    ...props.style,
}));

const ResumePage = () => {
    const user = useAppSelector(store => store.userState.currentUser);
    const isMobile = useAppSelector(store => store.appState.isMobile);
    const [open, setOpen] = useState(false);
    const [openResumeRemove, setOpenResumeRemove] = useState(false);
    const resume = user?.resume;
    const dispatch = useAppDispatch();


    const onReplaceFilePressed = () => {
        dispatch(setOnboarding(true));
    }

    const onRemoveResume = () => {
        setOpenResumeRemove(true);
    }

    const onRemoveResumeConfirmed = async () => {
        const updatedUser = await UserService.deleteResume();
        dispatch(setUser(updatedUser));
    }

    const onEditTextResumePressed = () => {
        setOpen(true);
    }


    if (!resume) {
        return (
            <ScrollingWrapper>
                <StyledContainer isMobile={isMobile} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                    <ProfileSectionHeader title='Resume' />
                    <Typography variant='h4' style={{ marginTop: '40px' }}>
                        Add your resume
                    </Typography>
                    <Typography variant='body1' style={{ textAlign: 'left', marginTop: '26px', marginBottom: '36px', color: AppColors.grey.darkish }}>
                        Adding a resume will help Path Pilot give you more personalized recommendations.
                    </Typography>
                    <PrimaryButton smallText paddingHorizontal={'19px'} height={'48px'} title='Add Resume' disabled={false} onClick={onReplaceFilePressed} />
                </StyledContainer>
            </ScrollingWrapper>

        );
    }

    const downloadResumePressed = () => {
        window.open(resume.resumeUrl, "_blank");
    }

    return (
        <ScrollingWrapper>
            <StyledContainer isMobile={isMobile}>
                <ProfileSectionHeader title='File Resume' icon={<DownloadIcon />} action='Replace File' onActionPressed={onReplaceFilePressed} />
                <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', paddingTop: '40px', paddingBottom: '74px' }}>
                    {!isMobile && <Typography variant="h4" style={{ textAlign: 'left', width: '200px', minWidth: '200px' }}>
                        File Uploaded:
                    </Typography>}
                    <div>
                        <Typography variant='body1' style={{ display: 'flex', flexDirection: 'column', color: AppColors.blue, textAlign: 'left' }}>
                            <ClickableOpacityDiv onClick={downloadResumePressed}>{`${resume.fileName} [${(formatFileSize(resume.fileSize))}]`}</ClickableOpacityDiv>
                            <span style={{ color: AppColors.grey.darkish }}> Uploaded on {new Date(resume.createdAt).toLocaleDateString()}
                            </span>
                            <Typography variant='h6' style={{ marginTop: '26px', display: 'flex', alignItems: 'center', gap: '44px', color: AppColors.grey.dark }}>
                                <ClickableOpacityDiv onClick={onRemoveResume}><SmallXIcon color={AppColors.grey.dark} /> <span style={{ marginLeft: '4px' }}></span>Remove</ClickableOpacityDiv>
                                <ClickableOpacityDiv onClick={onReplaceFilePressed}><DownloadIconSmall /> <span style={{ marginLeft: '4px' }}></span>Replace</ClickableOpacityDiv>
                            </Typography>
                        </Typography>
                    </div>
                </div>
                <ProfileSectionHeader title='Text Resume' icon={<EditIcon />} action='Edit' onActionPressed={onEditTextResumePressed} />
                <Typography variant="subtitle2" style={{ paddingTop: '40px', textAlign: 'left', color: AppColors.grey.darkish }}>
                    {resume.textResume?.split('\n').map((line, index) => (
                        <Fragment key={index}>
                            {line}
                            <br />
                        </Fragment>
                    ))}
                </Typography>
                <div style={{ minHeight: '20px', maxHeight: '20px', }} />
            </StyledContainer>
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="edit-profile"
                aria-describedby="edit-profile-info"
            >
                <EditTextResumeModal
                    onClose={() => setOpen(false)}
                />
            </Modal>
            <Modal
                open={openResumeRemove}>
                <ConfirmationModal title="Remove resume?" subtitle="Are you sure you want to remove your resume? This will delete both the file and the text version of your resume." onCancel={() => { setOpenResumeRemove(false) }} onConfirm={() => { setOpenResumeRemove(false); onRemoveResumeConfirmed(); }} confirmText="REMOVE RESUME" />
            </Modal>
        </ScrollingWrapper>
    )
}

export default ResumePage;
import JobInfoRow from "../components/JobInfoRow";

interface JobSummaryResponse {
    "Fit Summary": {
        "Role Fit": string;
    };
    "Overall Fit Assessment": string;
    "Detailed Fit Assessment": {
        emoji: string;
        category: string;
        explanation: string;
    }[];
    "Job Summary": string;
    "Company Overview": string;
}

export const JobSummaryWidget = (js: JobSummaryResponse) => {
    return (
        <>
            <JobInfoRow title="Fit Summary" subtitle={js["Fit Summary"]["Role Fit"]} />
            <JobInfoRow title="Overall Fit Assessment" subtitle={js["Overall Fit Assessment"]} />
            <JobInfoRow
                title="Detailed Fit Assessment"
                subtitle={
                    <ul style={{ display: 'flex', flexDirection: 'column', paddingLeft: '20px' }}>
                        {js["Detailed Fit Assessment"].map((d, index) => (
                            <li key={index}>
                                {d.emoji} <strong>{d.category}</strong>: {d.explanation}
                            </li>
                        ))}
                    </ul>
                }
            />
            <JobInfoRow title="Job Summary" subtitle={js["Job Summary"]} />
            <JobInfoRow title="Company Overview" subtitle={js["Company Overview"]} />
        </>
    );
}

export default JobSummaryResponse;
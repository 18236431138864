import styled from '@emotion/styled';
import { Box, FilledInput, FormControl, InputAdornment, Typography, useTheme } from '@mui/material';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AddIcon from '../../assets/icons/add_icon';
import MinusIcon from '../../assets/icons/minus_icon';
import PlusIcon from '../../assets/icons/plus_icon';
import SearchIcon from '../../assets/icons/search_icon';
import ChatCard from '../../chat/components/ChatCard';
import NavigationConstants from '../../navigation/NavigationConstants';
import { StartInterviewAction } from '../../redux/actions/InterviewActions';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { clearActiveChat, setDrawerIsOpen } from '../../redux/reducers/appReducer';
import { clearSelectedInterview, setSelectedInterview } from '../../redux/reducers/interviewReducer';
import { clearSelectedSavedResource } from '../../redux/reducers/resourceReducer';
import { clearSelectedJob } from '../../redux/reducers/savedJobsReducer';
import ClickableOpacityDiv from '../../shared/components/ClickableOpacityDiv';
import SearchClearButton from '../../shared/components/SearchClearButton';
import { StyledBackButton } from '../../shared/components/SideBarButtons';
import Interview from '../../shared/models/Interview';
import { AppColors, sharpTransition } from '../../theme/AppTheme';
import ChatHistorySearchView from '../chatHistory/ChatHistorySearchView';

const Sidebar = styled(Box)((props: { isMobile: boolean }) => ({
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    overflowX: 'clip',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    borderRight: `2px solid ${AppColors.grey.light}`,
    height: '100%',
    width: props.isMobile ? '100%' : NavigationConstants.sidebarWidth,
    backgroundColor: AppColors.white,
}));

const Header = styled(Box)((props: { isMobile: boolean }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: '24px',
    paddingRight: '24px',
    marginTop: props.isMobile ? '72px' : '46px',
    width: props.isMobile ? 'calc(100% - 40px)' : '100%',
}));

const SearchInput = styled(FormControl)(() => ({
    width: '100%',
    marginTop: '46px',
    marginBottom: '17px',
}));

const StyledInput = styled(FilledInput)(() => ({
    marginLeft: '24px',
    marginRight: '24px',
    borderRadius: '8px',
    height: '48px',
    backgroundColor: AppColors.grey.lightest,
    '&:hover': {
        backgroundColor: AppColors.grey.light
    },
}));


const InterviewSidebarView = () => {
    const { t } = useTranslation();
    const [titleOpacity, setTitleOpacity] = useState(1);
    const [searchText, setSearchText] = useState('');

    const isMobile = useAppSelector(state => state.appState.isMobile);
    const dispatch = useAppDispatch();
    const onAddClicked = () => {
        dispatch(clearActiveChat());
        if (isMobile) {
            dispatch(setDrawerIsOpen(false));
        }
        dispatch(StartInterviewAction());
    }

    const sidebarRef = useRef<HTMLDivElement | null>(null);

    const handleScroll = () => {
        if (sidebarRef.current) {
            const scrollPosition = sidebarRef.current.scrollTop;
            if (scrollPosition > 25 && titleOpacity === 1) {
                setTitleOpacity(0);
            } else if (scrollPosition <= 25 && titleOpacity === 0) {
                setTitleOpacity(1);
            }
        }
    }

    return (
        <Sidebar isMobile={isMobile} ref={sidebarRef} onScroll={handleScroll}>
            {isMobile && <StyledBackButton />}
            {isMobile &&
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', zIndex: 9, position: 'absolute', left: 0, right: '24px', top: 0, height: '60px', backgroundColor: AppColors.white }}>
                    <Typography variant='h4' style={{ marginLeft: '60px', marginTop: '8px', fontSize: '18px', opacity: 1 - titleOpacity, transition: `opacity 0.2s ${sharpTransition}` }}>
                        Saved Interviews
                    </Typography>
                    <ClickableOpacityDiv onClick={onAddClicked} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '8px' }}>
                        <AddIcon />
                    </ClickableOpacityDiv>
                </div>}
            <Header isMobile={isMobile}>
                <Typography variant='h4' style={{ fontSize: isMobile ? '18px' : '14px', opacity: titleOpacity, transition: `opacity 0.2s ${sharpTransition}` }}>
                    Saved Interviews
                </Typography>
                {!isMobile && <ClickableOpacityDiv onClick={onAddClicked} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <AddIcon />
                </ClickableOpacityDiv>}
            </Header>
            <SearchTextInput searchTerm={searchText} onSearchTermUpdated={(newValue) => setSearchText(newValue)} />
            {!searchText && <ChatList isMobile={isMobile} />}
            {searchText && <ChatHistorySearchView searchText={searchText} />}
        </Sidebar>
    )
}

const ScrollingInterviewList = styled(Box)((props: { isMobile: boolean }) => ({
    display: 'flex',
    flexDirection: 'column',
    overflowY: props.isMobile ? undefined : 'auto',
    width: props.isMobile ? '100%' : NavigationConstants.sidebarWidth,
    transition: `height 0.2s ${sharpTransition}`,
    paddingBottom: '50px',
}));

const startingObject = (chatHistory: { [key: string]: Interview[] }) => {
    let obj: { [key: string]: boolean } = {};
    Object.keys(chatHistory).forEach(element => {
        obj[element] = true;
    })

    return obj;
}

const ChatList = (props: { isMobile: boolean }) => {
    const dispatch = useAppDispatch();
    const interviewHistory = useAppSelector((s) => s.interviewState.interviews);
    const chatHistoryBuckets = useAppSelector((s) => s.interviewState.interviewBuckets);
    const selectedInterview = useAppSelector(state => state.interviewState.selectedInterview);
    const interviewToggle = useAppSelector((s) => s.interviewState.interviewToggle);
    const [open, setOpen] = useState<{ [key: string]: boolean }>(startingObject(interviewHistory));

    useEffect(() => {
        dispatch(clearSelectedInterview());
    }, []);

    useEffect(() => {
        setOpen({ 'Today': true });
    }, [interviewToggle]);

    const cardSelected = (bucket: string, index: number) => {
        const interview = interviewHistory[bucket][index];
        if (!interview) return;
        dispatch(setSelectedInterview(interview));
        if (props.isMobile) {
            dispatch(clearSelectedJob());
            dispatch(clearSelectedSavedResource());
            dispatch(setDrawerIsOpen(false));
        }
    }


    const toggleSection = (bucket: string) => {
        setOpen({ ...open, [bucket]: !(open[bucket] ?? false) });
    }

    const isOpenBucket = (bucket: string): boolean => {
        return open[bucket] ?? false;
    }


    const interviewBucketCards = (bucket: string) => {
        const isOpen = isOpenBucket(bucket);
        return (
            <>
                <ClickableOpacityDiv onClick={() => toggleSection(bucket)} style={{ maxWidth: 'calc(100% - 48px)', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '36px', marginBottom: isOpen ? '15px' : '0px', marginLeft: '24px', marginRight: '24px' }}>
                    <div>{bucket} ({interviewHistory[bucket].length})</div>
                    {isOpen ? <MinusIcon /> : <PlusIcon />}
                </ClickableOpacityDiv>
                <div style={{
                    overflow: isOpen ? undefined : 'hidden',
                    height: isOpen ? 'auto' : '0px',
                }}>
                    {interviewHistory[bucket].map((interview, index) => <ChatCard key={index} onClick={() => cardSelected(bucket, index)} interview={interview} selected={selectedInterview?.sessionId === interview.sessionId} />)}
                </div>
            </>
        );
    }

    return (
        <ScrollingInterviewList isMobile={props.isMobile}>
            {chatHistoryBuckets.map((bucket) => {
                if (interviewHistory[bucket] === undefined) {
                    return null;
                }
                return interviewBucketCards(bucket);
            })}
        </ScrollingInterviewList>
    );
}

interface SearchTextInputProps {
    searchTerm: string;
    onSearchTermUpdated: (newText: string) => void;
}

const SearchTextInput = (props: SearchTextInputProps) => {
    const { searchTerm, onSearchTermUpdated } = props;
    const theme = useTheme();

    const handleInputChanged = (event: ChangeEvent<HTMLInputElement>) => {
        const newText = event.target.value
        onSearchTermUpdated(newText);
    }

    return (
        <SearchInput variant='filled'>
            <StyledInput
                disableUnderline
                hiddenLabel
                id="outlined-basic"
                type='text'
                startAdornment={
                    <InputAdornment sx={{ width: '18px', height: '18px', margin: 0, padding: 0, marginRight: '12px' }} position="end">
                        <SearchIcon />
                    </InputAdornment>
                }
                endAdornment={searchTerm.length > 0 &&
                    <SearchClearButton onClick={() => onSearchTermUpdated('')} />
                }
                placeholder='Search'
                onChange={handleInputChanged}
                value={searchTerm}
                sx={{
                    ...theme.typography.h5,
                    '&.Mui-focused': {
                        backgroundColor: AppColors.grey.light
                    },
                    '& input::placeholder': {
                        ...theme.typography.h4,
                        color: AppColors.grey.dark,
                        opacity: 1.0,
                    },
                    input: {
                        ...theme.typography.h4,
                    },
                }}
            />
        </SearchInput>
    );
}

export default InterviewSidebarView
import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Bars } from "react-loader-spinner";
import { Strings } from "../../i18n";
import { useAppSelector } from "../../redux/hooks";
import CloseButton from "../../shared/components/CloseButton";
import FullScreenModalDiv from "../../shared/components/FullScreenModalDiv";
import OutlinedButton from "../../shared/components/OutlinedButton";
import PPMultiLineTextInput from "../../shared/components/PPMultiLIneTextInput";
import PPTextInput from "../../shared/components/PPTextInput";
import PrimaryButton from "../../shared/components/PrimaryButton";
import zIndicies from "../../shared/utils/zIndexConstants";
import { AppColors } from "../../theme/AppTheme";
import { EditableObject } from "../models/EditableValue";


const StyledContainer = styled('div')((props: { isMobile: boolean }) => ({
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: props.isMobile ? '100%' : '70%',
    height: props.isMobile ? '100%' : undefined,
    maxWidth: props.isMobile ? '100%' : '650px',
    maxHeight: props.isMobile ? '100%' : '85vh',
    backgroundColor: AppColors.white,
}));

const StyledScrollingContainer = styled('div')((props: { isMobile: boolean }) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: props.isMobile ? 'calc(100% - 48px)' : '100%',
    overflowY: 'auto',
    backgroundColor: AppColors.white,
    padding: props.isMobile ? '24px' : '54px 100px 64px',
    paddingBottom: '64px',
    height: props.isMobile ? 'calc(100% - 48px)' : undefined,
}));

const StyledButtons = styled('div')((props: { isMobile: boolean }) => ({
    padding: '20px',
    borderTop: `1px solid ${AppColors.grey.border}`,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '22px',
    width: props.isMobile ? 'calc(100% - 40px)' : '100%',
}));

interface EditProfileModalProps {
    onClose: () => void;
    onSubmit: (newData: EditableObject) => void;
    editableData: EditableObject;
    loading: boolean;
    title: string;
    subtitle: string;
}

const EditProfileModal = (props: EditProfileModalProps) => {
    const { title, subtitle, onSubmit, onClose, editableData, loading } = props;
    const { t } = useTranslation();
    const [error, setError] = useState('');
    const [data, setData] = useState(editableData);
    const isMobile = useAppSelector(store => store.appState.isMobile);
    const scrollContainerRef = useRef<HTMLDivElement>(null);


    const onSubmitPressed = async () => {
        // TODO: Validate data
        setError('');
        onSubmit(data);
    }

    const updateData = (dataField: EditableObject) => {
        const newData = { ...data, ...dataField };
        setData(newData);
    }

    return (
        <FullScreenModalDiv>
            <StyledContainer isMobile={isMobile}>
                <StyledScrollingContainer isMobile={isMobile} ref={scrollContainerRef}>
                    <CloseButton onClick={onClose} />
                    <Typography variant='h2' style={{ marginBottom: '21px' }}>
                        {title}
                    </Typography>
                    <Typography variant='h5' style={{
                        textAlign: 'center',
                        marginBottom: '40px',
                        color: AppColors.grey.darkish
                    }}>
                        {subtitle}
                    </Typography>
                    {Object.entries(data).map(([k, v]) =>
                        v?.largeInput
                            ? <>
                                <Typography variant='h4' style={{ width: '100%', textAlign: 'left', paddingBottom: '16px' }}>
                                    {k}:
                                </Typography>
                                <div style={{ width: '100%', minHeight: '120px' }}>
                                    <PPMultiLineTextInput
                                        id={k ?? ''}
                                        value={v?.value ?? ''}
                                        onChange={(newValue) => updateData({ [k]: { ...v, value: newValue } })}
                                        style={{
                                            width: isMobile ? 'calc(100% - 24px)' : '100%',
                                            borderRadius: '12px',
                                            border: `1px solid ${AppColors.grey.border}`,
                                            paddingLeft: '12px',
                                            paddingRight: '12px',
                                        }}
                                        minRows={4}
                                        maxRows={8}
                                    />
                                </div>
                            </>
                            : <PPTextInput
                                id={k ?? ''}
                                title={k}
                                value={v?.value ?? ''}
                                onValueChanged={(newValue) => updateData({ [k]: { ...v, value: newValue } })}
                            />
                    )}
                </StyledScrollingContainer>
                <StyledButtons isMobile={isMobile}>
                    <PrimaryButton height={'50px'} style={{ maxWidth: '294px', }} title={t(Strings.save).toUpperCase()} disabled={false} onClick={onSubmitPressed} />
                    <OutlinedButton height={'50px'} style={{ maxWidth: '294px', }} title={t(Strings.cancel).toUpperCase()} disabled={false} onClick={onClose} />
                </StyledButtons>
                {loading && <div style={{
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: zIndicies.modal,
                    backgroundColor: AppColors.loading,
                }}>
                    <>
                        <span style={{ minHeight: '20px', maxHeight: '20px' }} />
                        <Bars
                            height="30"
                            width="30"
                            color={AppColors.pink.dark}
                            ariaLabel="bars-loading"
                            visible={true}
                        />
                    </>
                </div>}
            </StyledContainer>

        </FullScreenModalDiv>
    )
}

export default EditProfileModal;
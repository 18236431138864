import ResourcesService from "../../services/resourcesService";
import Resource from "../../shared/models/Resource";
import { setResourceList, setSavedResourceBuckets, setSavedResources } from "../reducers/resourceReducer";
import { AppDispatch, RootState } from "../store";

export const SetupSavedResourceBuckets = (resources: Resource[]) => {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const descendingSavedResources = resources.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
        const today = new Date();
        let bucketKeys: string[] = ['Today', 'This Week'];
        const sortedChatHistory = descendingSavedResources.reduce((buckets: { [key: string]: Resource[] }, resource: Resource) => {
            const createdDate = new Date(resource.createdAt);
            const year = createdDate.getFullYear();
            const month = createdDate.toLocaleString('default', { month: 'long' });

            let bucketKey = '';
            if (createdDate.toDateString() === today.toDateString()) {
                bucketKey = 'Today';
            } else if (createdDate >= new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay())) {
                bucketKey = 'This Week';
            } else {
                bucketKey = `${month} ${year}`;
            }

            if (!buckets[bucketKey]) {
                if (!bucketKeys.includes(bucketKey)) {
                    bucketKeys.push(bucketKey);
                }
                buckets[bucketKey] = [];
            }
            buckets[bucketKey].push(resource);

            return buckets;
        }, {});

        for (const bucketKey in sortedChatHistory) {
            sortedChatHistory[bucketKey].sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
        }

        dispatch(setSavedResourceBuckets(bucketKeys));
        dispatch(setSavedResources(sortedChatHistory));
    };
};
export const FetchResourceListsAction = () => {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const resourceLists = await ResourcesService.fetchAllLists();
        if (resourceLists) {
            resourceLists.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());
            const favouritesList = resourceLists.find(list => list.name.toLowerCase() === "likes");
            if (favouritesList) {
                resourceLists.splice(resourceLists.indexOf(favouritesList), 1);
                resourceLists.unshift(favouritesList);
                dispatch(setResourceList(resourceLists));
            } else {
                const newList = await ResourcesService.createList("Likes");
                if (newList) {
                    dispatch(setResourceList([newList]));
                }
            }
        } else {
            const newList = await ResourcesService.createList("Likes");
            if (newList) {
                dispatch(setResourceList([newList]));
            }
        }
    }
};
import styled from '@emotion/styled';
import CloseIcon from '../../assets/icons/close_icon';

const StyledButton = styled('div')(() => ({
    position: 'absolute',
    top: '22px',
    right: '22px',
    height: '21px',
    width: '21px',
    '&:hover': {
        cursor: 'pointer',
    }, zIndex: 99999999,
}));

interface CloseButtonProps {
    onClick: () => void;
}
const CloseButton = (props: CloseButtonProps) => {

    return (
        <StyledButton onClick={props.onClick}>
            <CloseIcon />
        </StyledButton>
    )
}

export default CloseButton
import { Typography } from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';
import SavedIcon from '../../assets/icons/save_icon_selected';
import SaveIcon from '../../assets/icons/save_icon_unselected';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { addSavedJob, removeSavedJob } from '../../redux/reducers/savedJobsReducer';
import JobsService from '../../services/jobsService';
import ClickableOpacityDiv from '../../shared/components/ClickableOpacityDiv';
import LinearFitScore from '../../shared/components/LinearFitScore';
import APIJob, { combineJobData } from '../../shared/models/APIJob';
import Job from '../../shared/models/Job';
import { AppColors } from '../../theme/AppTheme';
import { marginForIndex, widthForIndex } from './MessageCell';

interface MobileJobCardCardProps {
    index: number;
    job: Job;
    jobData?: APIJob;
    minHeight?: string;
    viewed?: boolean;
    onBookmarked?: () => void;
    onClick: () => void;
}
const MobileJobCard = (props: MobileJobCardCardProps) => {
    const { job, jobData, index, minHeight, viewed, onClick, onBookmarked } = props;
    const savedJobs = useAppSelector(store => store.savedJobsState.jobsArray ?? []);
    const isSaved = savedJobs.some(j => (j.id === job.jobId) || (j.url === job.url));
    const savedJobsToggle = useAppSelector(store => store.savedJobsState.savedJobsToggle);
    const [bookmarked, setBookmarked] = useState(isSaved);
    const isMobile = useAppSelector((s) => s.appState.isMobile);

    const dispatch = useAppDispatch();

    const onBookmark = async () => {
        const combinedJob: Job = jobData ? combineJobData(job, jobData) : job;
        if (!bookmarked) {
            setBookmarked(true);
            const savedJob = await JobsService.saveJob(combinedJob);
            dispatch(addSavedJob(combinedJob));
            if (!savedJob) {
                setBookmarked(false);
            } else {
                onBookmarked?.();
            }
        } else {
            await JobsService.unsaveJob(combinedJob)
            dispatch(removeSavedJob(combinedJob));
            setBookmarked(false);
        }
    }

    useEffect(() => {
        setBookmarked(savedJobs.some((j) => {
            return (j.jobId === (job.jobId ?? jobData?.id));
        }
        ));
    }, [savedJobsToggle]);

    const JobInfoCell = (props: { children: ReactNode, cellIndex: number }) => {
        const { children, cellIndex } = props;
        return (<div style={{ textAlign: 'left', minWidth: widthForIndex(cellIndex), maxWidth: widthForIndex(cellIndex), marginLeft: marginForIndex(cellIndex) }} key={cellIndex}>
            {children}
        </div>);
    }

    return (
        <ClickableOpacityDiv onClick={onClick} key={index} style={{ minHeight: minHeight, height: '76px', maxHeight: '76px', overflow: 'hidden', display: 'flex', padding: '10px 15px', alignItems: 'center', backgroundColor: AppColors.white, boxShadow: `0 0 2px 1px ${AppColors.card}`, marginBottom: '16px', borderRadius: '12px', width: '100%', position: 'relative' }}>
            <JobInfoCell cellIndex={0}><LinearFitScore percentage={job.fit} /></JobInfoCell>

            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: isMobile ? '35px' : '50px', width: '100%', overflow: 'hidden', }}>
                <Typography variant='body2' style={{ lineHeight: '19px', fontSize: '12px', whiteSpace: 'nowrap', maxWidth: 'calc(100%)', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {job.title}<br />
                </Typography>
                <Typography variant='caption' style={{ lineHeight: '19px', color: AppColors.grey.darkish, whiteSpace: 'nowrap', maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {job.company}
                </Typography>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant='caption' style={{ lineHeight: '19px', fontSize: '10px', whiteSpace: 'nowrap', maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {job.location1}, {job.location2}
                    </Typography>
                </div>

            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                {viewed &&
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginRight: '16px',
                        color: AppColors.grey.darkish,
                    }}>
                        Viewed
                    </div>
                }
                <ClickableOpacityDiv
                    onClick={(e) => { e.stopPropagation(); onBookmark(); }}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    {bookmarked ? <SavedIcon /> : <SaveIcon />}
                </ClickableOpacityDiv>

            </div>
        </ClickableOpacityDiv>
    )
}

export default MobileJobCard
import { Typography } from '@mui/material';
import ClickableOpacityDiv from '../../shared/components/ClickableOpacityDiv';
import { AppColors } from '../../theme/AppTheme';

interface ResourceModalClearButtonProps {
    name: string;
    onClick: () => void;
}
const ResourceModalClearButton = (props: ResourceModalClearButtonProps) => {
    const { name, onClick } = props;

    return (
        <ClickableOpacityDiv style={{ position: 'absolute', right: '20px', top: '19px' }} onClick={onClick}>
            <Typography variant='h4' style={{ fontSize: '16px', fontWeight: 400, height: '18px', color: AppColors.black }}>
                {name}
            </Typography>
        </ClickableOpacityDiv>
    )
}

export default ResourceModalClearButton
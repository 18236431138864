
import TopLevelInterviewType from '../../shared/enums/TopLevelInterviewType';
import { newTopLevelInterviewChat } from '../../shared/models/Chat';
import Message, { MessageType } from '../../shared/models/Message';
import { setActiveChat } from '../reducers/appReducer';
import { AppDispatch } from '../store';
import { StartInterviewAction } from './InterviewActions';
import { StartInterviewQuestionsFlowAction } from './InterviewQuestionsActions';


export const StartTopLevelInterviewFlowAction = () => {
    return async (dispatch: AppDispatch) => {
        try {
            const message: Message = {
                content: `How would you like to prep for an interview?`,
                dt: (new Date()).toISOString(),
                type: MessageType.received,
            }
            const newChat = newTopLevelInterviewChat([message], `Interview Prep`);
            dispatch(setActiveChat(newChat));
        } catch (error) {
            console.error('Error starting interview prep chat:', error);
        }
    };
}

export const StartTopLevelInterviewAction = (type: TopLevelInterviewType) => {
    return async (dispatch: AppDispatch) => {
        try {
            if (type === TopLevelInterviewType.mock) {
                dispatch(StartInterviewAction());
            } else {
                dispatch(StartInterviewQuestionsFlowAction());
            }
        } catch (error) {
            console.error('Error starting top level interview chat:', error);
        }
    };
}

import styled from "@emotion/styled";
import { Rating, Typography } from "@mui/material";
import moment from "moment";
import EmptyStar from '../../assets/images/empty_star_grey.png';
import Star from '../../assets/images/star_grey.png';
import { useAppSelector } from "../../redux/hooks";
import UserAvatar from "../../shared/components/UserAvatar";
import { Review } from "../../shared/models/Resource";
import { AppColors } from "../../theme/AppTheme";

const StyledRow = styled('div')((props: { isMobile: boolean }) => ({
    display: 'flex',
    flexDirection: props.isMobile ? 'column' : 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
    overflow: 'hidden',
    marginBottom: '50px',
}));

const StyledColumn = styled('div')((props: { width: string, marginTop?: string }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    overflow: 'hidden',
    width: props.width,
    marginTop: props.marginTop,
}));

interface ReviewCardProps {
    review: Review;
}

const ReviewCard = (props: ReviewCardProps) => {
    const { review } = props;
    const isMobile = useAppSelector(store => store.appState.isMobile);

    return (
        <StyledRow isMobile={isMobile}>
            {/* TODO when have something that gives this */}
            <div style={{ marginRight: '20px', display: 'flex', width: isMobile ? '100%' : "33%", alignItems: 'center' }}>
                {review.image ?
                    <UserAvatar imageUrl={review.image} />
                    : <div style={{ marginTop: '6px', marginRight: '15px', minWidth: '40px', minHeight: '40px', maxHeight: '40px', backgroundColor: AppColors.grey.dark, borderRadius: '20px' }}>
                        <Typography variant='h3' style={{ color: AppColors.white, lineHeight: '40px', textAlign: 'center' }}>
                            {review.userName.split(' ').map((n) => n[0]).join('')}
                        </Typography>
                    </div>
                }
                <StyledColumn width='100%'>
                    <Typography variant='h3' style={{ textAlign: 'left', color: AppColors.grey.dark, lineHeight: '24px', marginBottom: '8px', fontSize: '14px' }}>
                        {review.userName}
                    </Typography>
                    <Rating
                        name="read-only-rating"
                        value={review.rating ?? 0}
                        precision={0.1}
                        icon={<img style={{ marginRight: '4px' }} width={10} height={10} src={Star} alt='star' />}
                        emptyIcon={<img style={{ marginRight: '4px' }} width={10} height={10} src={EmptyStar} alt='empty_star' />}
                        readOnly
                    />
                </StyledColumn>
            </div>
            <StyledColumn width={isMobile ? '100%' : "66%"} marginTop={isMobile ? '23px' : undefined}>
                <Typography variant='h6' style={{ color: AppColors.grey.dark, lineHeight: isMobile ? '26px' : '24px', fontSize: isMobile ? '14px' : '16px', textAlign: "left", marginBottom: isMobile ? '8px' : '20px' }}>
                    {review.content}
                </Typography>
                <Typography variant='subtitle2' style={{ fontWeight: isMobile ? 400 : 500, color: isMobile ? AppColors.grey.darkish : AppColors.grey.dark, lineHeight: '24px', fontSize: '12px', textAlign: "left" }}>
                    {moment(review.created).fromNow()}
                </Typography>
            </StyledColumn>
        </StyledRow>
    )
}

export default ReviewCard
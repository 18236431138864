import { ReactNode } from "react";

enum ResumeFixType {
    improvements = 'improvements',
    bestPractices = 'bestPractices',
    savedJobs = 'savedJobs',
    specificRole = 'specificRole',
}

export const textForResumeType = (type: ResumeFixType): ReactNode => {
    switch (type) {
        case ResumeFixType.improvements:
            return <>Conduct an assessment of my resume and give me advice on how to improve it</>
        case ResumeFixType.bestPractices:
            return <>Optimize my resume based on general resume <b>best practices</b></>
        case ResumeFixType.savedJobs:
            return <>Optimize my resume based on one of my <b>saved jobs</b></>
        case ResumeFixType.specificRole:
            return <>Optimize my resume for a <b>specific role or position</b> that I'm interested in</>
    }
}

export default ResumeFixType;